import React, { FC, useEffect, useState } from "react";
import StarRatings from "react-star-ratings";
import { IStarRating } from "./StarRating.interface";
import { useAppSelector } from "src/redux/hooks";

const StarRating: FC<IStarRating> = ({ rate, starDimensionWidth, className, mobileStarDimension, tabletStarDimension, starPadding }) => {
    const [starDimension, setStarDimension] = useState("32px");
    const uiState = useAppSelector((data) => data.UiStates);

    useEffect(() => {
        const handleResize = () => {
            // const width = window.innerWidth;
            // if (starDimensionWidth) {
            //     setStarDimension(`${starDimensionWidth}px`);
            // } else if (width > 359 && width < 768) {
            //     setStarDimension(mobileStarDimension ? `${mobileStarDimension}px` : "18px");
            // } else if (width > 767.98 && width < 1024) {
            //     setStarDimension(tabletStarDimension ? `${tabletStarDimension}px` : "24px");
            // } else if (width > 1023.98 && width < 1280) {
            //     setStarDimension("17.06px");
            // } else if (width > 1279.98 && width < 1536) {
            //     setStarDimension("24px");
            // } else if (width > 1535.98 && width < 1700) {
            //     setStarDimension("26px");
            // } else if (width >= 1700) {
            //     setStarDimension("32px");
            // }
            if (uiState.isTablet) {
                setStarDimension("32px");
            } else {
                setStarDimension(`22px`);
            }
        };

        // Initial call to set the dimension based on current window size
        handleResize();

        // Add event listener for window resize
        window.addEventListener("resize", handleResize);

        // Clean up the event listener when the component unmounts
        return () => window.removeEventListener("resize", handleResize);
    }, [mobileStarDimension, tabletStarDimension, starDimensionWidth]);

    return (
        <StarRatings
            rating={rate}
            starDimension={starDimension}
            starSpacing={starPadding ? starPadding : "4px"}
            // for color
            starRatedColor="var(--brand-color)"
            starEmptyColor="var(--template-review-color)"
            numberOfStars={5}
            name="rating"
        />
    );
};

export default StarRating;
