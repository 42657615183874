import React, { FC } from "react";
import CustomSwiper from "src/components/CustomSwiper/CustomSwiper";
import { SwiperSlide } from "swiper/react";
import { IproductData, IWProduct } from "./Product.interface";
import ProductInfo from "./ProductInfo";
import { ChevronLeft, ChevronRight } from "@untitled-ui/icons-react/build/cjs";
import { useTranslation } from "react-i18next";

const WProduct: FC<IWProduct> = ({ isLoading, productData, handlePrevious, handleNext, setSwiperRef, handleProduct }) => {
    const { t } = useTranslation();
    return isLoading ? (
        <div className="custom_loading_wrapper">
            <div className="custom_loading"></div>
        </div>
    ) : (
        <div className="flex flex-col items-center">
            {productData.length > 0 ? (
                <>
                    <div className="flex relative mb-5 justify-end w-full">
                        <button
                            disabled={productData.length <= 1}
                            className={`rounded-full flex flex-col justify-center items-center border border-[#060606] h-[26px] w-[26px] min-w-[26px] mr-3.5 bg-transparent ${
                                productData.length <= 1 ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
                            }`}
                            onClick={handlePrevious}
                        >
                            <ChevronLeft className="text-[#060606] w-4 h-4" />
                        </button>
                        <button
                            disabled={productData.length <= 1}
                            className={`rounded-full flex flex-col justify-center items-center border border-[#060606] h-[26px] w-[26px] min-w-[26px] bg-transparent ${
                                productData.length <= 1 ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
                            }`}
                            onClick={handleNext}
                        >
                            <ChevronRight className="text-[#060606] w-4 h-4" />
                        </button>
                    </div>
                    <CustomSwiper setSwiperRef={setSwiperRef} className="mySwiper w-full" loop={productData.length > 1}>
                        {productData.map((productArr: IproductData[], index: React.Key | null | undefined) => (
                            <SwiperSlide key={index}>
                                <ProductInfo key={index} productData={productArr} handleProduct={handleProduct} />
                            </SwiperSlide>
                        ))}
                    </CustomSwiper>
                </>
            ) : (
                <div className="flex flex-col min-h-[320px] items-center justify-center">
                    <span className="text-TextColor text-[16px] leading-[21px] font-medium">{t("No products available.")}</span>
                </div>
            )}
        </div>
    );
};

export default WProduct;
