import React, { FC } from "react";
import { CountryCode } from "libphonenumber-js";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import InputWithLabel from "src/components/InputWithLabel";
import Number from "src/components/Number";

export interface WInquireFormProps {
    title: string;
}

const WInquireForm: FC<WInquireFormProps> = ({ title }) => {
    const { t } = useTranslation();
    const { control, setValue, clearErrors } = useFormContext();
    const handleNumberChange = (country: CountryCode, phone: string, code: string, isValid: boolean) => {
        setValue(`phone`, `+${code}${phone}`);
        setValue(`phone_country_code`, country);
        if (isValid) {
            clearErrors("phone");
        }
    };
    return (
        <div className="flex flex-col w-full">
            {title !== "" && <span className="text-[24px] leading-[36px] font-medium text-center mb-[30px] text-TextColor tracking-[-0.01rem]">{title}</span>}
            <Controller
                name="name"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <div className="w-full flex flex-col">
                        <InputWithLabel
                            label={t("Name")}
                            type="text"
                            borderClass="!h-[49px] !bg-templateCardBg !border-templateCardBorderColor"
                            required
                            placeholder={t("Enter your name")}
                            handleChange={onChange}
                            value={value}
                            name="name"
                            error={!!error}
                        />
                        {error && <p className="text-error !ml-0">{error.message}</p>}
                    </div>
                )}
            />
            <div className="w-full flex flex-col mt-[16px] mb-[16px]">
                <label className="text-sm text-txtAppointmentColor leading-[18.2px] font-normal text-[14px] -tracking-[0.02rem] mb-1.5">
                    {t("Phone number")}
                    <span className="asterisk">*</span>
                </label>
                <Controller
                    name="phone"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                        <div className="flex flex-col">
                            <Number getNumber={onChange} errors={error} parentClassName="md:!h-[49px] !bg-templateCardBg !border-templateCardBorderColor" onNumberChange={handleNumberChange} />
                            {error && <p className="text-error">{error.message}</p>}
                        </div>
                    )}
                />
            </div>
            <Controller
                name="description"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <div className="w-full flex flex-col">
                        <InputWithLabel
                            label={t("Message")}
                            type="text"
                            borderClass="!h-[49px] !bg-templateCardBg !border-templateCardBorderColor"
                            required
                            placeholder={t("Add a message")}
                            handleChange={onChange}
                            value={value}
                            name="description"
                            error={!!error}
                        />
                        {error && <p className="text-error !ml-0">{error.message}</p>}
                    </div>
                )}
            />
        </div>
    );
};

export default WInquireForm;
