import React, { useEffect, useRef, useState } from "react";
import Location from "./Location";
import About from "./About";
import Testimonial from "./Testimonial";
import Product from "./Product";
import Course from "./Course";
import Service from "./Service";
import Professional from "./Professional";
import { useAppSelector } from "../../../redux/hooks";
import { useDispatch } from "react-redux";
import { selectTab, setIsScrollable } from "../../../redux/reducers/Landing.slice";
import Footer from "./Footer";
import Gallery from "./Gallery";
import { ISelectedTab } from "../../../Interface";
import Hero from "./Hero/Hero";
import BarLoader from "src/components/BarLoader";
import { useLocation } from "react-router-dom";
import { getSlug } from "src/utils/global-functions";
import NotConfigured from "src/app/NotConfigured";
import { useTranslation } from "react-i18next";
type SectionRefs = {
    [key in ISelectedTab]: React.RefObject<HTMLDivElement>;
};

const Template1 = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const nameMapping: { [key: string]: string } = {
        hero: t("Hero"),
        location: t("Location"),
        about: t("About us"),
        team: t("Team"),
        service: t("Services"),
        gallery: t("Gallery"),
        academy: t("Academy"),
        product: t("Product"),
        testimonial: t("Testimonials"),
    };
    const settingLoading = useAppSelector((data) => data.Common.loading);
    const settings: any = useAppSelector((data) => data.Common.settings)?.find((setting: any) => setting.type === (getSlug().isPreview ? "template_preview" : "template"))?.value;
    const [isLoading, setIsLoading] = useState(true);
    const locationSetting = settings?.location;
    const aboutSetting = settings?.about;
    const academySetting = settings?.academy;
    const gallerySetting = settings?.gallery;
    const testimonialsSetting = settings?.testimonial;
    const productsSetting = settings?.product;
    const servicesSetting = settings?.service;
    const teamSetting = settings?.team;
    const footerSetting = settings?.footer;
    const homeSettings = settings?.hero;
    const location = useLocation();
    const activeSection = useAppSelector((state) => {
        const initSelectedTab: any = state.LandingStates.initSelectedTab;
        return Object.keys(initSelectedTab).find((key: any) => initSelectedTab[key]) as ISelectedTab | undefined;
    });
    const isScrollable = useAppSelector((data) => data.LandingStates.isScrollable);

    // Refs for all sections
    const sectionRefs: SectionRefs = {
        hero: useRef<HTMLDivElement>(null),
        about: useRef<HTMLDivElement>(null),
        location: useRef<HTMLDivElement>(null),
        team: useRef<HTMLDivElement>(null),
        service: useRef<HTMLDivElement>(null),
        academy: useRef<HTMLDivElement>(null),
        gallery: useRef<HTMLDivElement>(null),
        testimonial: useRef<HTMLDivElement>(null),
        product: useRef<HTMLDivElement>(null),
        language: useRef<HTMLDivElement>(null),
        footer: useRef<HTMLDivElement>(null),
    };
    // Utility function to debounce rapid function calls
    const debounce = (func: (...args: any[]) => void, wait: number): ((...args: any[]) => void) => {
        let timeout: number | undefined; // Use undefined as the initial state

        return function (...args: any[]): void {
            const later = () => {
                clearTimeout(timeout);
                func(...args);
            };
            clearTimeout(timeout);
            timeout = window.setTimeout(later, wait); // Use window.setTimeout for clarity in a browser environment
        };
    };
    useEffect(() => {
        setIsLoading(settingLoading);
    }, [settingLoading]);

    useEffect(() => {
        if (location.state) {
            dispatch(selectTab(location.state.selectedTab));
            dispatch(setIsScrollable(true));
        }
    }, [location]);
    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY + window.innerHeight / 4; // Adjust the divisor based on your needs
            let closestSection = null;
            let smallestDistance = Infinity;

            Object.entries(sectionRefs).forEach(([key, ref]) => {
                if (ref.current) {
                    const { offsetTop } = ref.current;
                    // Calculate distance from the section's top to the middle of the viewport
                    const distance = Math.abs(scrollPosition - offsetTop);
                    if (distance < smallestDistance) {
                        smallestDistance = distance;
                        closestSection = key;
                    }
                }
            });

            if (closestSection && closestSection !== activeSection) {
                dispatch(setIsScrollable(false));
                dispatch(selectTab(closestSection));
            }
        };

        // Debounce the scroll event handler to improve performance
        const debouncedHandleScroll = debounce(handleScroll, 100);

        window.addEventListener("scroll", debouncedHandleScroll);
        return () => window.removeEventListener("scroll", debouncedHandleScroll);
    }, [dispatch, activeSection, sectionRefs]);
    useEffect(() => {
        if (isScrollable && activeSection && sectionRefs[activeSection]?.current) {
            sectionRefs[activeSection]!.current!.scrollIntoView({ behavior: "smooth" });
        }
    }, [activeSection]);
    const [activeSettings, setActiveSettings] = useState<
        {
            name: string;
            tabName: ISelectedTab;
        }[]
    >([]);

    useEffect(() => {
        const extractKeysWithStatusTrue = (obj: any) => {
            const result: ISelectedTab[] = [];
            for (const key in obj) {
                if (obj[key].status === true) {
                    result.push(key as ISelectedTab);
                }
            }
            return result;
        };

        const filterData = extractKeysWithStatusTrue(settings);

        const navLinks: { name: string; tabName: ISelectedTab }[] = Object.keys(nameMapping)
            .filter((key): key is ISelectedTab => filterData.includes(key as ISelectedTab))
            .map((tab) => ({
                name: nameMapping[tab],
                tabName: tab,
            }));

        setActiveSettings(navLinks);
    }, [settings]);

    return (
        <>
            {isLoading || settingLoading ? (
                <BarLoader />
            ) : settings?.length !== 0 && activeSettings.length !== 0 ? (
                <div className="w-full mx-auto flex flex-col flex-1 h-full overflow-y-auto bg-templateBg header_height">
                    {homeSettings?.status && (
                        <div ref={sectionRefs.hero} className="px-5 md:px-[34px]">
                            <Hero data={homeSettings} />
                        </div>
                    )}
                    {locationSetting?.status && (
                        <div ref={sectionRefs.location} className="px-5 md:px-[34px]">
                            <Location data={locationSetting?.locations} homeSettings={homeSettings} HomeStatus={!!homeSettings?.status} />
                        </div>
                    )}
                    {aboutSetting && aboutSetting?.status && (
                        <div ref={sectionRefs.about} className="px-5 md:px-[34px]">
                            <About data={aboutSetting} />
                        </div>
                    )}
                    {teamSetting?.status && (
                        <div ref={sectionRefs.team} className="px-5 md:px-[34px]">
                            <Professional data={teamSetting} locationSetting={locationSetting} />
                        </div>
                    )}
                    {servicesSetting && servicesSetting?.status && (
                        <div ref={sectionRefs.service} className="px-5 md:px-[34px]">
                            <Service data={servicesSetting} />
                        </div>
                    )}
                    {gallerySetting && gallerySetting?.status && (
                        <div ref={sectionRefs.gallery} className="px-5 md:px-[34px]">
                            <Gallery data={gallerySetting} />
                        </div>
                    )}
                    {academySetting?.status && (
                        <div ref={sectionRefs.academy} className="px-5 md:px-[34px]">
                            <Course data={academySetting} />
                        </div>
                    )}
                    {productsSetting?.status && (
                        <div ref={sectionRefs.product} className="px-5 md:px-[34px]">
                            <Product data={productsSetting} />
                        </div>
                    )}
                    {testimonialsSetting?.status && (
                        <div ref={sectionRefs.testimonial} className="px-5 md:px-[34px] flex flex-col flex-1">
                            <Testimonial data={testimonialsSetting} />
                        </div>
                    )}
                    <Footer data={footerSetting} activeSettings={activeSettings} />
                </div>
            ) : (
                <NotConfigured />
            )}
        </>
    );
};
export default Template1;
