import React, { useState } from "react";
import { ISpecialistService } from "../../../../Interface";
import { useTranslation } from "react-i18next";
import { getBookingInfo, setTempStore } from "../../Booking.slice";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import Sidebar from "src/components/Sidebar";
import ServiceInfo from "src/app/BookAppointment/SelectServices/ServiceInfo";
import moment from "moment";
import { X } from "@untitled-ui/icons-react/build/cjs";
interface IProps {
    serviceData: ISpecialistService;
    removeable?: boolean;
    isSelected?: boolean;
    handleRemoveService?: () => void;
}

const ServiceCardComponent = ({ serviceData, removeable = false, handleRemoveService, isSelected = false }: IProps) => {
    const { t } = useTranslation();
    const bookingInfo = useAppSelector(getBookingInfo);
    const dispatch = useAppDispatch();
    const uiState = useAppSelector((data) => data.UiStates);
    const [isShowServiceInfoPopup, setIsShowServiceInfoPopup] = useState(false);
    const handleInfo = () => {
        setIsShowServiceInfoPopup(true);
    };

    // const handleServiceSelect = async (e: any) => {
    //     e.stopPropagation();
    //     if (e.target.id === "alert_circle_svg") {
    //         return;
    //     }

    //     if (bookingInfo.tempStore?.selectedServices?.some((sObj) => sObj.id === serviceData.id || (sObj.parent_id && sObj.parent_id === serviceData.parent_id))) {
    //         const filteredServices = bookingInfo.tempStore?.selectedServices?.filter((serviceObj) => serviceObj?.parent_id !== serviceData?.parent_id && serviceObj.id !== serviceData.id);
    //         await dispatch(setTempStore({ selectedServices: filteredServices }));
    //     } else {
    //         console.log("bookingInfo.tempStore", bookingInfo.tempStore);

    //         await dispatch(
    //             setTempStore({
    //                 selectedServices: [...bookingInfo.tempStore?.selectedServices, { ...serviceData, quantity: 1 }],
    //             }),
    //         );
    //     }
    // };

    const handleServiceSelect = async (e: any) => {
        e.stopPropagation();
        if (e.target.id === "alert_circle_svg") {
            return;
        }

        // if (bookingInfo.tempStore?.selectedServices?.some((sObj) => sObj.id === serviceData.id)) {
        //     const filteredServices = bookingInfo.tempStore?.selectedServices?.filter((serviceObj) => serviceObj.id !== serviceData.id);
        //     // await dispatch(setSelectedServices(filteredServices));
        //     await dispatch(setTempStore({ selectedServices: filteredServices }));
        // } else {
        //     console.log("bookingInfo.tempStore", bookingInfo.tempStore);
        //     if (serviceData?.parent_id && bookingInfo.tempStore?.selectedServices?.some((sObj) => sObj?.parent_id === serviceData?.parent_id)) {
        //         const filteredServices = bookingInfo.tempStore?.selectedServices?.filter((serviceObj) => serviceObj?.parent_id !== serviceData?.parent_id);
        //         await dispatch(setTempStore({ selectedServices: [...filteredServices, { ...serviceData, quantity: 1 }] }));
        //     } else {
        //         await dispatch(
        //             setTempStore({
        //                 selectedServices: [...bookingInfo.tempStore?.selectedServices, { ...serviceData, quantity: 1 }],
        //             }),
        //         );
        //     }
        // }
        if (removeable) {
            handleRemoveService && handleRemoveService();
        } else {
            await dispatch(
                setTempStore({
                    selectedServices: [...bookingInfo.tempStore?.selectedServices, { ...serviceData, quantity: 1 }],
                }),
            );
        }
    };

    const getDurationPriceRange = (type: string) => {
        if (serviceData?.staff_service?.length) {
            let matchingService: ISpecialistService | undefined = undefined;
            if (bookingInfo.selectedSpecialist) {
                matchingService = bookingInfo.selectedSpecialist.services.find((specialistServiceData: any) => specialistServiceData.id === serviceData.id);
            }
            switch (type) {
                case "duration":
                    if (matchingService) {
                        const particularServiceDuration = moment.duration(matchingService.shop_staff_service.duration).asMinutes();
                        return <div className="text-textGrey lg:font-medium font-normal text-xs  lg:text-sm -tracking-[0.02rem]  mt-1 xl:mt-0">{`${particularServiceDuration} minutes`}</div>;
                    } else {
                        const durations = serviceData.staff_service.map((service: any) => moment.duration(service.duration).asMinutes());
                        const minDuration = Math.min(...durations);
                        const maxDuration = Math.max(...durations);
                        return (
                            <div className="text-textGrey lg:font-medium font-normal text-xs  lg:text-sm -tracking-[0.02rem]  mt-1 xl:mt-0">
                                {minDuration === maxDuration ? `${durations[0]} minutes` : `${minDuration} minutes - ${maxDuration} minutes`}
                            </div>
                        );
                    }
                case "price":
                    if (matchingService) {
                        const particularServicePrice = matchingService.shop_staff_service.price;
                        return (
                            <div className={`w-full flex flex-col`}>
                                <p className=" w-full text-secondaryTxt font-normal text-xs leading-[18px] text-nowrap -tracking-[0.02rem] justify-end flex">
                                    {serviceData.price?.starting_price ? t("Starting price") : t(" Price")}
                                </p>
                                <div className=" justify-end flex text-xs font-medium lg:text-sm lg:font-semibold text-txtAppointmentColor rounded-secondary -tracking-[0.03rem] min-w-max  mt-1">
                                    {`$${particularServicePrice}`}
                                </div>
                            </div>
                        );
                    } else {
                        const prices = serviceData.staff_service.map((service: any) => service.price);
                        const minPrice = Math.min(...prices);
                        const maxPrice = Math.max(...prices);
                        return (
                            <div className={`w-full flex flex-col`}>
                                <p className=" w-full text-secondaryTxt font-normal text-xs leading-[18px] text-nowrap -tracking-[0.02rem] justify-end flex">
                                    {serviceData.price?.starting_price ? t("Starting price") : t(" Price")}
                                </p>
                                <div className=" justify-end flex text-xs font-medium lg:text-sm lg:font-semibold text-txtAppointmentColor rounded-secondary -tracking-[0.03rem] min-w-max  mt-1">
                                    {minPrice === maxPrice ? `$${minPrice.toFixed(2)}` : `$${minPrice.toFixed(2)} - $${maxPrice.toFixed(2)}`}
                                </div>
                            </div>
                        );
                    }
            }
            return null;
        }
    };

    return (
        <div className="w-full flex relative">
            <div
                className={`inline-flex items-center  w-full lg:min-h-[78px] rounded-secondary p-3  lg:p-4 pr-6 text-gray-500 hover:shadow-[0_2px_16px_0_rgba(0,0,0,0.10)] border cursor-pointer group relative ${
                    isSelected ? "bg-primaryHover border-primaryBorderHover" : "border-borderColorPrimary"
                } `}
                onClick={removeable ? undefined : handleServiceSelect}
            >
                <div className="flex flex-row w-full h-full">
                    <div className="w-full flex-1 flex flex-col">
                        <div className="flex xl:gap-[10px] flex-col xl:flex-row">
                            <h2 className="text-txtcolor font-semibold lg:font-bold text-xs lg:text-[14px] leading-[19.6px]  -tracking-[0.02rem]">{serviceData.name}</h2>

                            {/* {serviceData?.price && serviceData?.price?.duration && (
                                <div className="text-textGrey font-medium text-sm -tracking-[0.02rem]">{convertToHumanReadable(serviceData?.price?.duration)}</div>
                            )} */}
                            {getDurationPriceRange("duration")}
                        </div>

                        {/* {serviceData?.price && serviceData?.price?.duration && (
                            <div className="text-textGrey mt-1 font-medium text-sm -tracking-[0.02rem]">{convertToHumanReadable(serviceData?.price?.duration)}</div>
                        )} */}
                        <p className="text-sm mt-1 font-medium -tracking-[0.02rem] hidden lg:block ">{serviceData.description}</p>
                    </div>
                    {/* <div className="w-full flex">hlw</div> */}
                    {/* <div className={`w-full flex items-center ${serviceData.price?.starting_price ? "justify-between" : "justify-end"}`}>
                        {serviceData.price?.starting_price && <p className="text-secondaryTxt font-medium text-xs leading-[150%] -tracking-[0.12px] mt-1">{t("Starting price")}</p>}
                        <div className="bg-inputbg px-2 text-base font-bold -tracking-[0.16px] rounded-md">{serviceData.price && `$${serviceData.price?.price}`}</div>
                    </div> */}
                    <div className="flex flex-row gap-10 ">
                        {/* {!uiState.isMobile && (
                            <div className="w-full flex flex-col">
                                <h3 className="text-secondaryTxt font-normal text-xs leading-[150%] mb-1 -tracking-[0.02rem]">Specialist</h3>
                                <div className="flex items-center ">{getStaff(serviceData)}</div>
                            </div>
                        )} */}
                        <div className="flex flex-row gap-10"></div>
                        {getDurationPriceRange("price")}
                        {/* {serviceData?.price && priceDurationRange && (
                            <div className={`w-full flex flex-col `}>
                                <p className=" w-full text-secondaryTxt font-normal text-xs leading-[150%] text-nowrap -tracking-[0.02rem]">
                                    {serviceData.price?.starting_price ? t("Starting price") : t(" Price")}
                                </p>
                                <div className=" justify-end flex text-base font-bold pt-1 rounded-secondary -tracking-[0.03rem]">
                                    {" "}
                                    {`$${priceDurationRange.minPrice} - $${priceDurationRange.maxPrice}`}
                                </div>
                            </div>
                        )} */}
                    </div>
                </div>
                {isSelected && (
                    <button
                        onClick={handleServiceSelect}
                        className="absolute -right-[6px] -top-[6px] z-10 flex justify-center items-center w-6 h-6 bg-dropdownBackgroundColor border-borderColorPrimary text-txtcolor rounded-full border"
                    >
                        <X width="18" />
                    </button>
                )}
            </div>
            <Sidebar
                isOpen={isShowServiceInfoPopup}
                position={uiState.isMobile ? "bottom" : "right"}
                handleClose={() => setIsShowServiceInfoPopup(false)}
                size={""}
                customClass={` ${uiState.isMobile ? "w-full rounded-t-[24px] h-[80%] " : "w-[660px]"}`}
            >
                <ServiceInfo data={serviceData} />
            </Sidebar>
        </div>
    );
};

export default ServiceCardComponent;
