import React, { FC } from "react";
import { CountryCode } from "libphonenumber-js";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomButton from "src/components/CustomButton";
import Number from "src/components/Number";
import OTPField from "src/components/OTPField/OTPField";
import { IUpdatePhone } from "../Profile.interface";
import { CheckCircle } from "@untitled-ui/icons-react/build/cjs";

const WUpdatePhone: FC<IUpdatePhone> = ({ verifyStep, isLoading, handleModalClose, phoneDetails, isOtpLoading, handlerSendCode }) => {
    const { t } = useTranslation();
    const { control, watch, setValue, clearErrors } = useFormContext();
    const otpWatch = watch("otp");
    const handleNumberChange = (country: CountryCode, phone: string, code: string, isValid: boolean) => {
        setValue(`phone`, `+${code}${phone}`);
        setValue(`phone_country_code`, country);
        if (isValid) {
            clearErrors("phone");
        }
    };
    return (
        <div className={`model-inner-wrapper font-primary ${verifyStep.success && "!py-[16px]"}`}>
            {verifyStep.otp && (
                <div className="flex flex-col w-full">
                    <h2 className="login-model-title">{t("Verify your phone number")}</h2>
                    <div className="flex flex-col">
                        <span className="login-model-subtitle"> {t("We’ve sent a 4-digit code to your phone number")}</span>
                        <span className="login-model-subtitle-dec">{phoneDetails.formatedPhone}</span>
                    </div>
                    <div className="mx-auto mt-[30px]">
                        <Controller
                            name="otp"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                <div className="flex flex-col">
                                    <OTPField otp={value} setOtp={onChange} errors={error} />
                                    {error && <p className="text-error text-center">{error.message}</p>}
                                </div>
                            )}
                        />
                        <div className="flex items-center justify-center gap-1 mt-[24px]">
                            <p className="text-textGrey text-sm font-normal -tracking-[0.01rem] leading-[19.6px]">{t("Didn’t receive the code?")}?</p>
                            <CustomButton
                                type="button"
                                isLoading={isOtpLoading}
                                disabled={isOtpLoading}
                                onClick={handlerSendCode(phoneDetails)}
                                className="text-primary !border-0 !p-0 !h-auto !flex-row leading-[19.6px] !font-semibold !bg-transparent text-sm text-center flex ring-0"
                                shadow={false}
                            >
                                {t("Resend code")}
                            </CustomButton>
                        </div>
                    </div>
                </div>
            )}
            {verifyStep.phone && (
                <div className="flex flex-col w-full">
                    <h2 className="login-model-title">{t("Phone Number Updated")}</h2>
                    <div className="flex flex-col">
                        <span className="login-model-subtitle"> {t("Update the phone number linked to your account")}</span>
                    </div>
                    <div className="w-full flex flex-col mt-[30px]">
                        <label className="text-sm text-txtAppointmentColor leading-[18.2px] font-normal text-[14px] -tracking-[0.02rem] mb-1.5">
                            {t("Phone number")}
                            <span className="asterisk">*</span>
                        </label>
                        <Controller
                            name="phone"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                <div className="flex flex-col">
                                    <Number getNumber={onChange} errors={error} onNumberChange={handleNumberChange} />
                                    {error && <p className="text-error">{error.message}</p>}
                                </div>
                            )}
                        />
                    </div>
                </div>
            )}
            {verifyStep.success && (
                <div className="flex flex-col w-full justify-center ">
                    <div className="flex flex-col w-full justify-center text-center max-w-[280px] mx-auto">
                        <span className="bg-[#DCFAE6] mb-4 text-[14px] mx-auto leading-[20px] h-[48px] min-w-[48px] w-[48px] rounded-full flex items-center justify-center">
                            <CheckCircle className="text-[#079455] w-5 h-5" />
                        </span>
                        <span className="text-[#101828] font-semibold text-[18px] leading-[28px] mb-1">{t("Phone Number Updated")}</span>
                        <span className="text-[#475467] font-normal text-[14px] leading-[20px]">{t("Your phone number has been successfully updated")}</span>
                    </div>
                    <CustomButton primary onClick={() => handleModalClose()} className={`w-full mt-5 !h-[48px]`}>
                        <span>{t("Continue")}</span>
                    </CustomButton>
                </div>
            )}
            {!verifyStep.success && (
                <div className="mt-[52px] w-full flex flex-row gap-4">
                    <CustomButton
                        primary
                        isLoading={isLoading}
                        disabled={isLoading || (verifyStep.otp && (isLoading || !otpWatch || otpWatch.length !== 4))}
                        className={`w-full !h-[48px]`}
                        type="submit"
                    >
                        <span>{verifyStep.phone ? t("Update") : t("Continue")}</span>
                    </CustomButton>
                </div>
            )}
        </div>
    );
};

export default WUpdatePhone;
