import React, { useEffect, useLayoutEffect, useState } from "react";
import { getCountries, getCountryCallingCode } from "react-phone-number-input/input";
import en from "react-phone-number-input/locale/en.json";
import { initSelectedNumber, INumberList, ISelectedNumber } from "./Number.interface";
import { Check, ChevronDown, XClose } from "@untitled-ui/icons-react/build/cjs";
import { useTranslation } from "react-i18next";
import { usePhone } from "src/hooks/usePhone";
import { FaCheck } from "react-icons/fa6";
import { CountryCode, formatIncompletePhoneNumber, parseIncompletePhoneNumber } from "libphonenumber-js";
import IconClose from "src/assets/svgs/icon-close.svg";

const Number = ({
    index,
    getNumber,
    onNumberChange,
    parentClassName = "min-h-[38px]",
    disabled = false,
    buttonIconRight,
    handlerIconClick,
    errors,
    name,
    defaultValues,
    isFocus = false,
    isButtonClicked,
    id,
    onFocus,
    onBlur,
}: any) => {
    const { setFormatPhone, isPhoneValid, phoneInfo } = usePhone();
    const { t } = useTranslation();
    const [Switch, setSwitch] = useState(false);
    const [selectedNumber, setSelectedNumber] = useState<ISelectedNumber>(initSelectedNumber);
    const [numberList, setNumberList] = useState<INumberList[]>([]);
    const [isInit, setIsInit] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    const [filterList, setFilterList] = useState<INumberList[]>([]);

    useEffect(() => {
        const handler = () => setSwitch(false);
        window.addEventListener("click", handler);
        return () => {
            window.removeEventListener("click", handler);
        };
    }, []);

    useEffect(() => {
        if (isInit) {
            setIsInit(false);
            return;
        }
        const filteredList = numberList.filter((country) => country.name.toLowerCase().includes(searchTerm));
        if (searchTerm === "") {
            setFilterList(numberList);
        } else {
            setFilterList(filteredList);
        }
    }, [searchTerm]);

    useEffect(() => {
        let existSelectedNumber: ISelectedNumber = { ...initSelectedNumber };
        if (defaultValues?.phone && defaultValues?.phone_country_code) {
            existSelectedNumber = { ...selectedNumber };
            const getphoneinfo = phoneInfo(defaultValues.phone, defaultValues.phone_country_code);
            if (getphoneinfo) {
                if (getphoneinfo.countryCallingCode) {
                    existSelectedNumber.code = getphoneinfo.countryCallingCode;
                }
                if (getphoneinfo.nationalNumber) {
                    existSelectedNumber.number = getphoneinfo.nationalNumber;
                }
            }
            // existSelectedNumber.image = require(`src/assets/flags/${defaultValues.phone_country_code}.svg`);
            existSelectedNumber.name = en[defaultValues.phone_country_code as CountryCode];
            existSelectedNumber.country = defaultValues.phone_country_code;
        }
        setSelectedNumber((old) => ({ ...old, ...existSelectedNumber }));
    }, [defaultValues]);

    const handlerInputClik = (e: any) => {
        e.stopPropagation();
        if (!disabled) {
            setSwitch(!Switch);
        }
    };

    const handleCountryCode = (data: ISelectedNumber) => () => {
        setSelectedNumber((old) => ({ ...old, ...data }));
        const isValid = isPhoneValid(selectedNumber.number, data.country);
        onNumberChange(data.country, selectedNumber.number, data.code, isValid);
    };

    const handleInputNumberChange = (e: any) => {
        let number = parseIncompletePhoneNumber(e.target.value);
        if (number === selectedNumber.number) {
            const newValueFormatted = formatIncompletePhoneNumber(number, selectedNumber.country);
            if (newValueFormatted.indexOf(e.target.value) === 0) {
                // Trim the last digit (or plus sign).
                number = number.slice(0, -1);
            }
        }
        setSelectedNumber((old) => ({ ...old, number }));
        const isValid = isPhoneValid(number, selectedNumber.country);
        onNumberChange(selectedNumber.country, number, selectedNumber.code, isValid);
    };

    useLayoutEffect(() => {
        const fetchData = async () => {
            let list = getCountries().map((country) => ({
                // image: require(`src/assets/flags/${country}.svg`),
                code: getCountryCallingCode(country),
                name: en[country],
                country: country,
            }));
            const priorityCountries = ["Canada", "United States"];

            const customSortValue = (country: string) => {
                const priorityIndex = priorityCountries.indexOf(country);
                return priorityIndex !== -1 ? priorityIndex : priorityCountries.length + 1;
            };

            const shortedList = list.sort((a, b) => customSortValue(a.name) - customSortValue(b.name) || a.name.localeCompare(b.name));
            setNumberList(shortedList);
            setFilterList(shortedList);
        };

        fetchData();
    }, []);

    const handleSearchTermClick = (e: { stopPropagation(): unknown; preventDefault: () => void }) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const term = e.target.value.toLowerCase();
        setSearchTerm(term);
    };

    const clearSearchTerm = (event: { stopPropagation(): unknown; preventDefault: () => void }) => {
        event.preventDefault();
        event.stopPropagation();
        setSearchTerm("");
    };

    return (
        <div className="relative">
            <div
                className={`fl-input-field w-full rounded-secondary flex items-center !h-[54px] md:!h-[44px] fl-phone-field form_input_control px-3.5 ${parentClassName} ${Switch && ""} ${
                    (typeof errors === "object" && Object.keys(errors).length > 0) ||
                    errors?.phonenumber ||
                    errors?.phone ||
                    (!isPhoneValid(selectedNumber.number, selectedNumber.country) && isButtonClicked)
                        ? "is-invalid"
                        : "border"
                }`}
            >
                <span className={`flex items-center ${disabled ? "!cursor-not-allowed" : ""}`} onClick={handlerInputClik}>
                    <span className="min-w-5 placeholder:text-textGrey placeholder:text-[14px] bg-transparent text-[14px] font-normal text-txtAppointmentColor placeholder:font-normal">
                        {selectedNumber.country}
                    </span>
                    <ChevronDown width={20} className="ml-[2px] text-placeholderTxt" />
                </span>
                <span className="ml-2 mr-1 placeholder:text-textGrey placeholder:text-[14px] bg-transparent text-[14px] font-normal text-txtAppointmentColor placeholder:font-normal">
                    +{selectedNumber.code}
                </span>
                <input
                    className={`placeholder:text-textGrey placeholder:text-[14px] bg-transparent text-[14px] font-normal text-txtAppointmentColor placeholder:font-normal focus:ring-0 focus:ring-offset-0 focus-visible:outline-none w-full border-none focus:border-headerBorderColor shadow-[0px_1px_2px_0px_#1018280D] ${
                        disabled ? "cursor-not-allowed" : ""
                    }`}
                    type="text"
                    name={name}
                    id={id}
                    disabled={disabled}
                    value={setFormatPhone(selectedNumber.code, selectedNumber.number, selectedNumber.country)}
                    onChange={handleInputNumberChange}
                    placeholder={setFormatPhone(selectedNumber.code, "8708708866", selectedNumber.country)}
                    autoFocus={isFocus}
                    maxLength={20}
                    onFocus={onFocus}
                    onBlur={onBlur}
                />
                {buttonIconRight && (
                    <span onClick={handlerIconClick ?? undefined} className="text-secondaryTxt cursor-pointer absolute top-1/2 -translate-y-1/2 right-4">
                        {buttonIconRight}
                    </span>
                )}
            </div>
            {/* {errors && errors.phone ? <p className="text-error">{errors && errors.phone ? errors.phone.message : 'Invalid Phone number'}</p> : ''} */}
            {Switch && (
                <div id="dropdownDelay" className="z-[999] absolute top-15 rounded-lg w-full focus:outline-none bg-dropdownBackgroundColor  px-4 pt-5 pb-2 shadow-[0px_5.33px_16px_0px_#0000001A]">
                    <div className="relative h-[44px] border border-borderCounter shadow-[0px_1px_2px_0px_#1018280D] rounded-lg">
                        <input
                            type="text"
                            placeholder={t("Search for a country")}
                            value={searchTerm}
                            onChange={handleSearchTermChange}
                            onClick={handleSearchTermClick}
                            autoFocus
                            className="placeholder:text-textGrey font-normal text-txtAppointmentColor text-[14px] -tracking-[0.01rem] placeholder:-tracking-[0.01rem] border-none w-full h-full focus:ring-0 ring-0 focus:outline-none !focus:border-0"
                        />
                        <button className="absolute right-3 top-[14px]" onClick={clearSearchTerm}>
                            <XClose className="w-[18px] h-[18px] text-textCounterColor" />
                        </button>
                    </div>
                    <ul className="country-code-dropdown-list" aria-labelledby="dropdownDelayButton">
                        {filterList.map((country: any, indexNumberCode: number) => (
                            <li
                                onClick={handleCountryCode(country)}
                                className={`country-code-dropdown-li ${selectedNumber.name === country.name && "active"}`}
                                key={indexNumberCode}
                                value={country.code}
                            >
                                <div className="flex items-center">
                                    <span className="w-6 h-auto max-h-6 font-normal">{country.country}</span>
                                    <span className="w-10 text-sm font-normal mx-3">+{country.code}</span>
                                    <span className="text-sm font-normal">{country.name}</span>
                                </div>
                                {selectedNumber.country === country.country && <Check className="h-[17px] w-[17px] text-primary" />}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default Number;
