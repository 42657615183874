import React, { useCallback, useEffect, useState } from "react";
import SelectBox from "../../../../components/SelectBox";
import Info from "./Info";
import { useAppSelector } from "src/redux/hooks";
import { Swiper as SwiperType } from "swiper/types";
import { SwiperSlide } from "swiper/react";
import { ChevronLeft, ChevronRight } from "@untitled-ui/icons-react/build/cjs";
import CustomSwiper from "src/components/CustomSwiper/CustomSwiper";
import { useTranslation } from "react-i18next";
import ProfessionalList from "./ProfessionalList";
import { IProfessional } from "./Professional.interface";
import { useLocalizedSetting } from "src/hooks/useLocalizedSetting";

const Professional: React.FC<IProfessional> = ({ data, locationSetting, sectionIndex }) => {
    const { t } = useTranslation();
    const { getLocalizedSetting } = useLocalizedSetting();
    const uiState = useAppSelector((uiStateData) => uiStateData.UiStates);
    const [locationOptions, setLocationOptions] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState<any>(null);
    const [staffList, setStaffList] = useState([]);
    const [staffData, setstaffData] = useState<any[]>([]);
    const [swiperRef, setSwiperRef] = useState<SwiperType>();

    useEffect(() => {
        if (staffList.length) {
            const mainData = staffInPairs(staffList);
            setstaffData(mainData || []);
        } else {
            setstaffData([]);
        }
    }, [staffList]);

    useEffect(() => {
        const locationOptionsData: any = locationSetting.locations.map((location: any) => ({
            value: location.title,
            label: location.title,
            id: location.title,
        }));
        const selectAllOption = {
            value: null,
            label: "ALL LOCATIONS",
            id: 0,
        };
        const updatedLocationOptionsData: any = [selectAllOption, ...locationOptionsData];

        setLocationOptions(updatedLocationOptionsData);
    }, []);

    useEffect(() => {
        if (selectedLocation) {
            const filteredStaff = data?.staff.filter((staffInfo: any) => staffInfo.location.some((location: any) => location === selectedLocation));
            setStaffList(filteredStaff);
        } else {
            setStaffList(data?.staff);
        }
    }, [selectedLocation]);

    const handleLocation = (e: any) => {
        setSelectedLocation(e.value);
    };

    const staffInPairs = (array: any) => {
        const result = [];
        if (array && array.length) {
            for (let i = 0; i < array.length; i += 4) {
                result.push(array.slice(i, i + 4));
            }
            return result;
        }
    };

    const handlePrevious = useCallback(() => {
        swiperRef?.slidePrev();
    }, [swiperRef]);
    const handleNext = useCallback(() => {
        swiperRef?.slideNext();
    }, [swiperRef]);

    const title = getLocalizedSetting(data, "title");

    return (
        <>
            <div className="w-full flex flex-col lg:flex-row lg:justify-between gap-3 md:gap-2 items-center professionals_section">
                <div className="w-full flex items-center flex-1">
                    <div className="slice-label">
                        <span className="num">{sectionIndex}</span>
                        {data?.is_title && <span className="slice-text">{title}</span>}
                    </div>
                </div>
                <SelectBox
                    placeholder={"All Locations"}
                    name="location"
                    allowClear={false}
                    options={locationOptions}
                    inputClass="w-full lg:w-[190px]"
                    searchable={true}
                    onChange={handleLocation}
                    value={locationOptions.filter((item: any) => item.value === selectedLocation)}
                    disabled={false}
                    // inputClass={`${errors.gender ? "is-invalid border-solid" : ""}`}
                />
            </div>
            {uiState.isTablet ? (
                staffData.length > 0 ? (
                    <div className={`flex flex-col `}>
                        <div className="flex relative my-[15px] justify-end">
                            <button
                                disabled={staffData.length <= 1}
                                className={`rounded-full flex flex-col justify-center items-center border border-TextColor h-[24px] w-[24px] min-w-[24px] mr-3.5 bg-transparent ${
                                    staffData.length <= 1 ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
                                }`}
                                onClick={handlePrevious}
                            >
                                <ChevronLeft className="text-TextColor w-4 h-4" />
                            </button>
                            <button
                                disabled={staffData.length <= 1}
                                className={`rounded-full flex flex-col justify-center items-center border border-TextColor h-[24px] w-[24px] min-w-[24px] bg-transparent ${
                                    staffData.length <= 1 ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
                                }`}
                                onClick={handleNext}
                            >
                                <ChevronRight className="text-TextColor w-4 h-4" />
                            </button>
                        </div>
                        <CustomSwiper className="mySwiper w-full" setSwiperRef={setSwiperRef} loop={staffData.length > 1}>
                            {staffData.map((staff: any, index: React.Key | null | undefined) => (
                                <SwiperSlide key={index}>
                                    <ProfessionalList key={index} prop={staff} />
                                </SwiperSlide>
                            ))}
                        </CustomSwiper>
                    </div>
                ) : (
                    <div className="flex flex-col min-h-[380px] items-center justify-center">
                        <span className="text-TextColor text-[16px] leading-[21px] font-medium">{t("No specialists available.")}</span>
                    </div>
                )
            ) : staffList.length > 0 ? (
                <div className="flex flex-col items-center">
                    <CustomSwiper setSwiperRef={setSwiperRef} className="mySwiper w-full mt-[32px]">
                        {staffList.map((staff: any, index: React.Key | null | undefined) => (
                            <SwiperSlide key={index}>
                                <Info key={index} data={staff} />
                            </SwiperSlide>
                        ))}
                    </CustomSwiper>
                    {staffList && staffList.length > 1 && (
                        <div className="flex relative mt-4">
                            <button
                                className="rounded-full flex flex-col justify-center items-center border border-TextColor cursor-pointer h-[24px] w-[24px] min-w-[24px] mr-3.5 bg-transparent"
                                onClick={handlePrevious}
                            >
                                <ChevronLeft className="text-TextColor w-4 h-4" />
                            </button>
                            <button
                                className="rounded-full flex flex-col justify-center items-center border border-TextColor cursor-pointer h-[24px] w-[24px] min-w-[24px] bg-transparent"
                                onClick={handleNext}
                            >
                                <ChevronRight className="text-TextColor w-4 h-4" />
                            </button>
                        </div>
                    )}
                </div>
            ) : (
                <div className="flex flex-col min-h-[320px] items-center justify-center">
                    <span className="text-TextColor text-[16px] leading-[21px] font-medium">{t("No specialists available.")}</span>
                </div>
            )}
        </>
    );
};

export default Professional;
