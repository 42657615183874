import React, { useEffect, useState } from "react";
import SpecialistDetail from "../../../components/SpecialistDetail";
import InputWithLabel from "../../../components/InputWithLabel";
import { IoClose, IoSearchOutline } from "react-icons/io5";
import { axiosGet, axiosPost } from "../../../utils/requestClient";
import { API } from "../../../constants/api";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { toast } from "react-toastify";
import { ISpecialistRoleCategory, ISpecialist, ISpecialistService } from "../../../Interface";
import SpecialistCardComponent from "./SpecialistCardComponent";
import { getBookingInfo, setStep, setTempStore } from "../Booking.slice";
import { setIsShowSpecialistProfileModal, setShowSpecialistProfileModalData } from "../../../redux/reducers/Ui.slice";
import { useTranslation } from "react-i18next";
import { EBookingTabState } from "../bookAppointment.interface";
import ManageSubmit from "../AppointmentPreview/ManageSubmit";
import { BiSearch } from "react-icons/bi";
import { FilterLines, XClose, Check, SearchLg } from "@untitled-ui/icons-react/build/cjs";
import { useNavigate } from "react-router-dom";
import PopupModal from "src/components/PopupModal";
import { Dropdown } from "flowbite-react";
import Sidebar from "src/components/Sidebar";
import { PATH } from "src/constants/path";
import { animated, useSprings } from "react-spring";
import Stepper from "../Stepper";
import { currentShop } from "src/redux/reducers/common/Common.slice";
import { isGetApp } from "src/redux/reducers/Landing.slice";

const SelectSpecialists = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const shop = useAppSelector(currentShop);
    const bookingState = useAppSelector(getBookingInfo);
    const uiStates = useAppSelector((data) => data.UiStates);
    const [specialists, setSpecialists] = useState<ISpecialist[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [staffRoleLists, setStaffRoleLists] = useState<ISpecialistRoleCategory[]>([]);
    const [filteredSpecialists, setFilteredSpecialists] = useState<ISpecialist[]>([]);
    const isGetAppVisible = useAppSelector(isGetApp);

    // loader states
    const [isShopLocationCategoryLoading, setIsShopLocationCategoryLoading] = useState(false);
    const [isShowSearchBar, setIsShowSearchBar] = useState(false);

    // filter states
    const [categoryFilterValue, setCategoryFilterValue] = useState<any | null>(null);
    const [searchFilterValue, setSearchFilterValue] = useState<string>("");

    const specialistAnimation = useSprings(
        filteredSpecialists.length,
        filteredSpecialists.map(
            (_, index) => ({
                from: { opacity: 0, transform: "translateY(20px)" },
                to: { opacity: 1, transform: "translateY(0)" },
                config: { tension: 220, friction: 20 },
                delay: index * 100,
            }),
            [filteredSpecialists],
        ),
    );

    useEffect(() => {
        dispatch(setTempStore({ SelectionCount: bookingState.selectedSpecialist ? 1 : 0 }));
    }, [bookingState.selectedSpecialist]);

    const getSpecialists = async () => {
        setIsLoading(true);
        const payloadServiceIds = bookingState.selectedServices.length ? bookingState.selectedServices?.map((service: ISpecialistService) => service.id) : undefined;
        const payloadData = {
            service_id: payloadServiceIds,
            booking_date: bookingState.selectedBookingDate,
            booking_time: bookingState.selectedBookingTime ? `${bookingState.selectedBookingTime}:00` : undefined,
        };
        const payloadObj = {
            shop_id: shop.id,
            location_id: bookingState.selectedShopLocation?.id,
        };
        axiosPost(API.STAFF.LIST, payloadData, payloadObj)
            .then(async (response) => {
                setSpecialists(response.data.data);
                return;
            })
            .catch((error) => {
                toast.error(error?.message ?? "Something went wrong, Please try again");
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const loadCategories = async () => {
        setIsShopLocationCategoryLoading(true);
        const urlParamObj = {
            shop_id: bookingState.selectedShopLocation?.shop_id,
            location_id: bookingState.selectedShopLocation?.id,
        };
        axiosGet(API.STAFF_ROLE.LIST, {}, urlParamObj)
            .then((response) => {
                const data = response.data.data;
                setStaffRoleLists(data);
            })
            .finally(() => setIsShopLocationCategoryLoading(false));
    };

    // redux step set
    useEffect(() => {
        handleStep(EBookingTabState.SELECT_SPECIALIST);
        dispatch(setTempStore({ selectedSpecialist: bookingState.selectedSpecialist }));
        getSpecialists();
        loadCategories();
    }, []);

    const handleStep = async (step: EBookingTabState) => {
        await dispatch(setStep(step));
    };

    useEffect(() => {
        const filteredRecords = specialists.filter((specialistRecord: ISpecialist) => {
            let searchMatched = true;
            let categoryMatched = true;

            // search value matching
            if (searchFilterValue !== "") {
                const specialistFullName = `${specialistRecord.first_name} ${specialistRecord.last_name}`.toLocaleLowerCase();
                searchMatched = specialistFullName.includes(searchFilterValue.toLocaleLowerCase());
            }

            // category filtering
            if (categoryFilterValue) {
                categoryMatched = specialistRecord.staff_role.id === categoryFilterValue.value;
            }
            return searchMatched && categoryMatched;
        });

        setFilteredSpecialists(filteredRecords);
    }, [categoryFilterValue, searchFilterValue, specialists]);

    const handleBack = () => {
        navigate(`${PATH.APPOINTEMENT}`);
        handleStep(EBookingTabState.TAB_MENU);
        dispatch(setTempStore({ selectedSpecialist: undefined }));
    };
    const handleSearchBar = () => {
        setIsShowSearchBar(!isShowSearchBar);
    };

    const handleSpecialistSelect = () => {
        dispatch(setTempStore({ selectedSpecialist: uiStates.showSpecialistProfileModalData }));
        dispatch(setIsShowSpecialistProfileModal(false));
    };

    const handleClose = () => {
        dispatch(setIsShowSpecialistProfileModal(false));
        dispatch(setShowSpecialistProfileModalData(undefined));
    };

    return (
        <>
            <div className="flex shrink-0 flex-col px-4 lg:px-2">
                <div className="flex flex-row w-full justify-between mb-4 items-center relative">
                    <div className="title text-txtcolor font-bold text-2xl flex gap-3 items-center w-full ">
                        {/* <div
                            className=" flex justify-center items-center text-txtcolor h-8 w-8 cursor-pointer border border-borderColorSecondary rounded-primary shadow-InputAndButton hover:shadow-[0_2px_16px_0_rgba(0,0,0,0.10)]"
                            onClick={handleBack}
                        >
                            <ArrowLeft className="text-txtAppointmentColor w-[18px]" />
                        </div> */}
                        <div className="title text-txtcolor md:mt-0 w-full">
                            <h3 className="location-title-text"> {t("Select a specialist")}</h3>
                            <p className="location-sub-text">{uiStates.isMobile ? t("Choose your preferred specialist") : t("Please choose your preferred specialist.")}</p>
                        </div>
                        {(isShowSearchBar && uiStates?.isMobile) || !uiStates?.isMobile ? (
                            <div className="w-[calc(100%-90px)] transition delay-150 duration-300 ease-in-out  bg-cardBackground md:mt-0 absolute left-[0px]  sm:hidden">
                                <InputWithLabel
                                    buttonIcon={<IoSearchOutline color="#8C9CBB" size={18} className="mr-1 " />}
                                    handlerIconClick={() => setSearchFilterValue("")}
                                    type="text"
                                    inputClass="pl-[41px] !pt-[0px] !pr-[40px]"
                                    placeholder={t("Search")}
                                    // borderClass="hidden lg:block"
                                    handleChange={(e) => setSearchFilterValue(e.target.value)}
                                    value={searchFilterValue}
                                    name="firstname"
                                    buttonIconRight={searchFilterValue ? <XClose className="text-secondaryTxt w-5 mr-3" /> : <span></span>}
                                />
                            </div>
                        ) : null}
                    </div>

                    {uiStates.isMobile && (
                        <div className="min-w-[36px] h-[36px] flex border border-borderColorPrimary  items-center justify-center rounded-lg  sm:hidden" onClick={handleSearchBar}>
                            {/* <BiSearch className="w-[20px] h-[20px]  text-textGrey" /> */}
                            {/* <IoClose className="w-[20px] h-[20px]  text-textGrey" /> */}
                            {isShowSearchBar ? <IoClose className="w-[20px] h-[20px] text-textGrey" /> : <BiSearch className="w-[20px] h-[20px]  text-textGrey" />}
                        </div>
                    )}

                    <div className="sm:w-[320px] w-full md:mt-0 hidden sm:block search-box">
                        <InputWithLabel
                            buttonIcon={<SearchLg className="text-textGrey w-[18px] h-[18px]" />}
                            handlerIconClick={() => setSearchFilterValue("")}
                            type="text"
                            inputClass="pl-[38px] !pr-[40px] "
                            borderClass="!h-[40px]"
                            placeholder={t("Search")}
                            // borderClass="hidden lg:block"
                            handleChange={(e) => setSearchFilterValue(e.target.value)}
                            value={searchFilterValue}
                            name="firstname"
                            buttonIconRight={searchFilterValue ? <XClose className="text-textGrey w-[18px] absolute top-1/2 -translate-y-1/2 right-3" /> : <span></span>}
                        />
                    </div>
                    {!isShopLocationCategoryLoading && (
                        <div className="ml-2 relative">
                            <Dropdown
                                className="bg-dropdownBackgroundColor border-borderColorPrimary border-0"
                                theme={{
                                    arrowIcon: "ml-2 h-4 w-4",
                                    content:
                                        "py-1 focus:outline-none bg-dropdownBackgroundColor !right-0 fixed z-50 rounded-secondary shadow-InputAndButton px-2.5 py-2 space-y-1 border border-borderColorPrimary shadow-InputAndButton",
                                }}
                                label=""
                                placement="bottom-start"
                                dismissOnClick={true}
                                inline
                                renderTrigger={() => (
                                    <button
                                        type="button"
                                        className="min-w-[36px] h-[36px] sm:min-w-[40px] sm:h-[40px] border border-borderColorSecondary lg:border-borderColorSecondary rounded-md flex items-center justify-center lg:mt-0 relative"
                                    >
                                        {/* <FilterFunnel02 className="w-[20px] h-[20px] lg:hidden text-textGrey" /> */}
                                        <FilterLines color="#8C9CBB" className="" />
                                        {/* <span className="hidden lg:block text-textGrey text-base font-normal">Filter</span> */}
                                    </button>
                                )}
                            >
                                {staffRoleLists.map((categoryList) => (
                                    <Dropdown.Item
                                        theme={{
                                            container: "right-0",
                                            icon: "text-textGrey mr-3 h-4 w-4 bg-black ",
                                            base: `${
                                                categoryFilterValue?.value === categoryList.id && " bg-mainBackgroundColor rounded-[8px] text-txtcolor focus:outline-none"
                                            }  flex w-[250px] md:w-[210px]  right-0 -tracking-[0.02rem] cursor-pointer items-center justify-start px-2.5  py-2 text-sm font-normal text-txtcolor  active:text-txtcolor active:bg-mainBackgroundColor active:rounded-[8px] hover:rounded-[8px] focus:rounded-[8px] hover:bg-mainBackgroundColor focus:bg-mainBackgroundColor focus:outline-none focus:ring-0`,
                                        }}
                                        key={categoryList.id}
                                        onClick={() => {
                                            categoryFilterValue?.value === categoryList.id
                                                ? setCategoryFilterValue(null)
                                                : setCategoryFilterValue({ label: categoryList.name, value: categoryList.id });
                                        }}
                                    >
                                        <div className="flex items-center justify-between gap-2 w-full">
                                            {categoryList.name}
                                            {categoryFilterValue?.value === categoryList.id && <Check className="text-txtcolor h-4 w-4 mr-1.5" />}
                                        </div>
                                    </Dropdown.Item>
                                ))}
                            </Dropdown>
                            {categoryFilterValue && <div className="w-[13px] h-[13px] bg-primary rounded-full absolute -top-1 -right-1 border-[3px]  border-contentBackground "></div>}
                        </div>
                    )}
                </div>
                <div className="mb-3">
                    <Stepper />
                </div>
                {/* {!isShopLocationCategoryLoading && (
                    <div className="fl-tab-btn-view flex flex-wrap w-full mt-5 lg:mt-6 mb-4 lg:mb-5">
                        <button
                            onClick={() => setCategoryFilterValue(null)}
                            type="button"
                            className={`fl-tab-link pt-1.5 border-headerBorderColor text-textGrey -tracking-[0.03rem] hover:shadow-[0_2px_16px_0_rgba(0,0,0,0.10)] ${
                                categoryFilterValue === null ? "active" : ""
                            }`}
                        >
                            All
                        </button>

                        {staffRoleLists.map((staffRoleList) => (
                            <button
                                key={staffRoleList.id}
                                type="button"
                                className={`fl-tab-link pt-1.5 border-headerBorderColor text-textGrey -tracking-[0.03rem] hover:shadow-[0_2px_16px_0_rgba(0,0,0,0.10)]  ${
                                    categoryFilterValue === staffRoleList.id ? "active" : ""
                                }`}
                                onClick={() => setCategoryFilterValue(staffRoleList.id)}
                            >
                                {staffRoleList.name}
                            </button>
                        ))}
                    </div>
                )} */}
            </div>
            {/*
                <div className="flex flex-wrap">
                    <div className="mt-3 w-full md:w-1/2 md:pl-5">
                        <SelectBox
                            disabled={isShopLocationSpecialistLoading || isShopLocationCategoryLoading}
                            placeholder={t("Select a category")}
                            name="role"
                            allowClear={true}
                            options={staffRoleLists.map((staffRoleList) => ({
                                label: staffRoleList.name,
                                value: staffRoleList.id,
                            }))}
                            onChange={(option) => setCategoryFilterValue(option)}
                            value={categoryFilterValue}
                        />
                    </div>
                </div> */}

            {isLoading ? (
                <div className="custom_loading_wrapper">
                    <div className="custom_loading"></div>
                </div>
            ) : filteredSpecialists.length <= 0 && !isLoading ? (
                <div className="flex-1 justify-center flex items-center h-full">
                    <h1 className="text-textGrey font-normal text-base -tracking-[0.03rem]">{t("No specialists available.")}</h1>
                </div>
            ) : (
                <div
                    className={`overflow-y-scroll grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-4 3xl:grid-cols-5 scrollbar-hide md:pb-0 -tracking-[0.01rem] max-lg:px-2 ${
                        filteredSpecialists.length <= 0 ? "pb-0 w-full" : isGetAppVisible ? "mt-[20px] lg:mt-[24px] pb-[60px]" : "mt-[20px] lg:mt-[24px] pb-[60px]"
                    }`}
                >
                    {specialistAnimation.map((style, index) => (
                        <animated.div key={filteredSpecialists[index].id} style={style}>
                            <SpecialistCardComponent specialistData={filteredSpecialists[index]} />
                        </animated.div>
                    ))}
                </div>
            )}
            {/* {filteredSpecialists.length <= 0 && !isLoading && (
                <div className=" flex-1 justify-center flex items-center ">
                    <h1 className="text-txtcolor font-semibold  text-base -tracking-[0.03rem]">{t("No specialists available.")}</h1>
                </div>
            )} */}

            {/* Show specialist detail component here */}
            {/* <AnimatePresence initial={false} onExitComplete={() => dispatch(setShowSpecialistProfileModalData(undefined))}>
                    {uiStates.isShowSpecialistProfileModal && (
                        <div className="fixed flex items-end lg:absolute lg:hidden bottom-0 top-0 right-0 left-0 bg-[rgba(26,_29,_37,_0.3)] h-full z-30 w-full md:py-4">
                            <SpecialistDetail />
                        </div>
                    )}
                </AnimatePresence> */}
            {/* <Sidebar
                isOpen={uiStates.isShowSpecialistProfileModal}
                position={uiStates.isMobile ? "bottom" : "right"}
                handleClose={() => {
                    dispatch(setIsShowSpecialistProfileModal(false));
                    dispatch(setShowSpecialistProfileModalData(undefined));
                }}
                size="w-[660px]"
            > */}
            {uiStates.isShowSpecialistProfileModal &&
                (uiStates.isMobile ? (
                    <Sidebar
                        dismissable
                        isOpen={uiStates.isMobile && uiStates.isShowSpecialistProfileModal}
                        position="bottom"
                        handleClose={() => handleClose()}
                        customClass={`w-full rounded-t-[24px] remove-scroll-sidebar overflow-hidden ${"h-[80%]"}`}
                        size={""}
                    >
                        <div className="w-full flex flex-1 flex-col h-full overflow-hidden">
                            <SpecialistDetail />
                        </div>
                    </Sidebar>
                ) : (
                    <PopupModal
                        onClose={handleClose}
                        size="max-w-[95%] min-w-[340px] mx-auto lg:w-[660px] font-primary outline-custom"
                        className="!pt-5 !pb-0"
                        isCrossSign={false}
                        primaryButton={t("Continue")}
                        secondaryButton={t("Cancel")}
                        acceptAction={handleSpecialistSelect}
                        declineAction={handleClose}
                        dismissible
                        position="center"
                    >
                        <SpecialistDetail />
                    </PopupModal>
                ))}
            {/* </Sidebar> */}
            <div className={`lg:absolute mt-auto bottom-0 w-full lg:bg-transparent `}>{uiStates.isMobile && <ManageSubmit />}</div>

            {/* <div className="flex fixed md:absolute justify-center bottom-5 lg:bottom-0 w-full left-0 right-0 z-30 gap-5 px-[30px] 2xl:px-10 ">
                {selectedSpecialistData && !bookingState.isModifyingAppointment && (
                    <>
                        <ButtonCmp onClick={handleSelectSpecialist} className="btn_primary fl-btn w-full lg:w-1/2" loading={isSubmitSpecialistLoading}>
                            {t("Continue")}
                        </ButtonCmp>
                    </>
                )}
            </div> */}
        </>
    );
};

export default SelectSpecialists;
