import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { usePhone } from "src/hooks/usePhone";
import { ChevronDown, ChevronUp } from "@untitled-ui/icons-react/build/cjs";
import { format, parse } from "date-fns";
import { Swiper as SwiperType } from "swiper/types";
import { ChevronLeft, ChevronRight } from "@untitled-ui/icons-react/build/cjs";
import { onError } from "src/utils/global-functions";
import { useTranslation } from "react-i18next";
interface Props {
    data: any;
    homeSettings: any;
    HomeStatus: boolean;
}

const init = {
    // When window width is <= 768px
    640: {
        slidesPerView: 1,
        spaceBetween: 16,
    },
    1024: {
        slidesPerView: 1.5,
        spaceBetween: 30,
    },
    1280: {
        slidesPerView: 1.5,
        spaceBetween: 34,
    },
};
const Location: React.FC<Props> = ({ data, homeSettings, HomeStatus }) => {
    const { t } = useTranslation();
    const [currentSliderInfo, setCurrentSliderInfo] = useState<any>(null);
    const { getCustomFormatPhone } = usePhone();
    const [swiperRef, setSwiperRef] = useState<SwiperType>();
    const [isCollapsed, setIsCollapsed] = useState<{ [key: string]: { isShow: boolean } }>({});
    const [swiperKey, setSwiperKey] = useState(0);
    const [breakPoint, setBreakPoint] = useState(init);

    const handleSlideChange = (event: any) => {
        setCurrentSliderInfo(data[event?.realIndex]);
    };
    useEffect(() => {
        if (data.length > 0) {
            setCurrentSliderInfo(data[0]);
        }
    }, []);
    useLayoutEffect(() => {
        if (data.length === 1) {
            setBreakPoint((prev: any) => ({
                ...prev,
                1024: {
                    slidesPerView: data.length,
                    spaceBetween: 20,
                },
                1280: {
                    slidesPerView: data.length,
                    spaceBetween: 20,
                },
            }));
        } else {
            setBreakPoint(init);
        }
        setSwiperKey((prevKey) => prevKey + 1);
    }, [data]);

    useEffect(() => {
        if (data.length) {
            const initialState = data.reduce((acc: { [x: string]: { isShow: boolean } }, item: { title: string }) => {
                acc[item.title] = { isShow: false };
                return acc;
            }, {});
            setIsCollapsed(initialState);
        }
    }, [data]);

    const handleHours = (locationTitle: string) => () => {
        setIsCollapsed((prev) => ({
            ...prev,
            [locationTitle]: { isShow: !prev[locationTitle]?.isShow },
        }));
    };

    const formatTimeRange = (hours: any) => {
        const from = format(parse(hours.from, "HH:mm:ss", new Date()), "h:mmaaa");
        const to = format(parse(hours.to, "HH:mm:ss", new Date()), "h:mmaaa");
        return `${from.toLowerCase()} - ${to.toLowerCase()}`;
    };

    const handlePrevious = useCallback(() => {
        swiperRef?.slidePrev();
    }, [swiperRef]);
    const handleNext = useCallback(() => {
        swiperRef?.slideNext();
    }, [swiperRef]);

    const checkStatus = (array: any[]) => array.some((element) => element.status === true);
    // const { opacity, transform } = useSpring({
    //     from: { opacity: 0, transform: 'translateX(-50px)' },
    //     to: { opacity: 1, transform: 'translateX(0)' },
    //     reset: true,
    //   });
    return (
        <>
            {currentSliderInfo ? (
                <div className={`w-full mx-auto pt-[20px]  text-TextColor relative ${HomeStatus ? "md:pt-[54px]" : "mt-[60px] md:pt-[36px]"} `}>
                    <Swiper
                        key={swiperKey}
                        slidesPerView={1}
                        spaceBetween={34}
                        pagination={{
                            clickable: true,
                            dynamicBullets: true,
                        }}
                        loop={data.length > 1}
                        navigation={true}
                        onSwiper={setSwiperRef}
                        className="mySwiper w-full"
                        onSlideChange={handleSlideChange}
                        breakpoints={breakPoint}
                    >
                        {/* Slides with content */}
                        {data.map((location: any, index: React.Key | null | undefined) => (
                            <SwiperSlide key={index} className="w-full">
                                <div className="relative w-full">
                                    <div className="profile-images">
                                        {location?.is_image && (
                                            <img
                                                src={`${location.image_url}`}
                                                alt=""
                                                onError={onError("Location")}
                                                className="w-full aspect-[16/9] max-lg:min-h-[500px] max-h-[640px] object-cover cursor-pointer"
                                            />
                                        )}
                                        {(checkStatus(location?.hours) || location.is_title || location.is_contact) && (
                                            <div className="w-[-webkit-fill-available] absolute backdrop-blur-[20px] bottom-[24px] left-[24px] right-[24px] bg-[#FFFFFF1A] p-[20px]">
                                                <div className="flex flex-col md:flex-row items-center w-full">
                                                    {(location.is_title || location.is_contact) && (
                                                        <div className="flex flex-col flex-1">
                                                            {location.is_title && (
                                                                <span className="text-[16px] leading-[21px] md:text-[18px] md:leading-[27px] uppercase font-medium flex-1 text-white mb-0.5">
                                                                    {location.title}
                                                                </span>
                                                            )}
                                                            {location.is_contact && (
                                                                <div className="flex flex-row gap-3">
                                                                    <span className="text-[16px] leading-[24px] font-normal uppercase tracking-[-0.01rem] text-white">
                                                                        {getCustomFormatPhone(location.contact, location?.country_code)}
                                                                    </span>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                    {checkStatus(location?.hours) && (
                                                        <div onClick={handleHours(location.title)} className="flex flex-row items-center gap-2 justify-end max-md:pt-2">
                                                            <span className="text-[14px] leading-[18.2px] font-medium tracking-[-0.01rem] text-white">{t("View opening hours")}</span>
                                                            {isCollapsed?.[location.title].isShow ? <ChevronDown className="text-white w-5 h-5" /> : <ChevronUp className="text-white w-5 h-5" />}
                                                        </div>
                                                    )}
                                                </div>
                                                {isCollapsed?.[location.title].isShow && checkStatus(location?.hours) && (
                                                    <div className="grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-5 gap-3 mt-3 border-t pt-3 border-[#FFFFFF33]">
                                                        {location?.hours
                                                            ?.filter((dataInfo: any) => dataInfo.status === true)
                                                            .map((dataInfo: any, dayIndex: React.Key | null | undefined) => (
                                                                <div key={`dayIndex${dayIndex}`} className=" px-1.5 sms:px-3 py-1 sms:py-2 flex flex-col border border-[#FFFFFF33]">
                                                                    <span className="text-[12px] leading-[18px] font-light tracking-[-0.01rem] text-white mb-0.5">{dataInfo.day}</span>
                                                                    <span className="text-[12px] sms:text-[14px] uppercase leading-[18px] font-medium tracking-[-0.01rem] text-white">
                                                                        {formatTimeRange(dataInfo)}
                                                                    </span>
                                                                </div>
                                                            ))}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    {data.length > 1 && (
                        <div className="flex justify-center mt-5 md:mt-0 md:justify-end md:absolute md:top-[-50px] md:right-0 z-50">
                            <button
                                className="rounded-full flex flex-col justify-center items-center border border-TextColor cursor-pointer h-[24px] w-[24px] min-w-[24px] smd:w-[40px] smd:h-[40px] smd:min-w-[40px] md:h-[52px] md:w-[52px] md:min-w-[52px] mr-3.5 bg-transparent"
                                onClick={handlePrevious}
                            >
                                <ChevronLeft className="text-TextColor w-[16px] h-[16px] smd:w-[20px] smd:h-[20px] md:w-[22px] md:h-[22px]" />
                            </button>
                            <button
                                className="rounded-full flex flex-col justify-center items-center border border-TextColor cursor-pointer h-[24px] w-[24px] min-w-[24px] smd:w-[40px] smd:h-[40px] smd:min-w-[40px] md:h-[52px] md:w-[52px] md:min-w-[52px] bg-transparent"
                                onClick={handleNext}
                            >
                                <ChevronRight className="text-TextColor w-[16px] h-[16px] smd:w-[20px] smd:h-[20px] md:w-[22px] md:h-[22px]" />
                            </button>
                        </div>
                    )}
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

export default Location;
