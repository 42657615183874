import React, { FC, useState, useEffect } from "react";
import { DefaultImageJPG } from "src/theme/Images";
import { Minus, Plus, XClose } from "@untitled-ui/icons-react/build/cjs";
import { formatAmount, onError } from "src/utils/global-functions";
import { IDetails, IImages, IVariant } from "../../Template2/Product/Product.interface";
import { IProductInfo } from "./Product.interface";
import { useTranslation } from "react-i18next";
import { BsPlusSlashMinus } from "react-icons/bs";

interface IProps {
    productData: IProductInfo;
    handleProduct: (selectedProductData: IProductInfo, variant: IVariant) => () => void;
}

const ProductInfo: FC<IProps> = ({ productData, handleProduct }) => {
    const { t } = useTranslation();
    const [productDetails, setProductDetails] = useState<{
        variant: IVariant;
        price: number;
        imageUrl: string;
        isOtherDetailsShow: boolean;
        description: string;
        details: IDetails[];
    }>({
        price: 0,
        imageUrl: "",
        isOtherDetailsShow: false,
        description: "",
        details: [],
        variant: { price: 0, size: "0", id: 0 },
    });
    const [hoveredProduct, setHoveredProduct] = useState<number | null>(null);

    const [isCollapsed, setIsCollapsed] = useState<any>({ id: 1, isShow: false });

    useEffect(() => {
        const price = productData.variants && productData.variants.length > 0 ? productData.variants[0].price : 0;
        const variant = productData.variants && productData.variants.length > 0 ? productData.variants[0] : { price: 0, size: "0", id: 0 };
        const imageUrl = productData.images && productData.images.length > 0 ? productData.images.find((image: IImages) => image.is_main)?.image_url || DefaultImageJPG : DefaultImageJPG;
        const description = productData.description;
        const details = productData.details || [];

        setProductDetails({
            price,
            imageUrl,
            isOtherDetailsShow: false,
            description,
            details,
            variant,
        });
    }, [productData]);

    const handlePrice = (variantDetail: any) => {
        setProductDetails((prevDetails) => ({
            ...prevDetails,
            variant: variantDetail,
            price: variantDetail.price,
        }));
    };

    const handleDetails = (isDetailShow: boolean) => () => {
        setProductDetails((prevDetails) => ({
            ...prevDetails,
            isOtherDetailsShow: isDetailShow,
        }));
    };

    return (
        <div className="relative flex flex-col items-center justify-center">
            <div className="flex flex-col w-full">
                <div className="relative" onMouseEnter={() => setHoveredProduct(productData.id)} onMouseLeave={() => setHoveredProduct(null)}>
                    <img src={productDetails.imageUrl} alt="" onError={onError("Product")} className="w-full min-h-[343px] max-h-[672px] aspect-[1/1] object-cover" />
                    {hoveredProduct === productData.id && (
                        <div className="absolute inset-0 flex items-center justify-center bg-[#000000B2]" onMouseLeave={() => setHoveredProduct(null)}>
                            <button
                                onClick={handleDetails(true)}
                                className="bg-transparent border w-full max-w-[254px] border-white h-[46px] uppercase text-white px-4 flex items-center justify-center text-[14px] leading-[18.2px] tracking-[-0.001em] font-medium "
                            >
                                {t("View Product Details")}
                            </button>
                        </div>
                    )}
                    <button
                        onClick={handleProduct(productData, productDetails.variant)}
                        className="absolute bottom-3 right-3 text-[20px] leading-[24.2px] font-normal text-white h-[40px] w-[40px] bg-[#000000] flex justify-center items-center"
                    >
                        <Plus className="text-white w-[20px] h-[20px]" />
                    </button>
                </div>
                <div className="flex flex-row pt-4">
                    <div className="flex-1">
                        <div className="flex flex-row w-full items-center justify-between mb-3">
                            <h2 className="text-[20px] leading-[30px] font-medium truncate max-w-max tracking-[-0.01em] text-TextColor uppercase">{productData.name}</h2>
                            <span className="text-[20px] leading-[30px] font-normal truncate max-w-max tracking-[-0.01em] text-TextColor uppercase">{formatAmount(productDetails.price)}</span>
                        </div>
                        <div className="flex gap-2">
                            {productData.variants.map((variant, index) => (
                                <button
                                    key={index}
                                    onClick={() => handlePrice(variant)}
                                    className={`border border-borderColorSecondary px-1.5 hover:border-primary hover:text-primary text-secondaryTxt py-[5px] rounded-[6px] text-[12px] leading-[18px] font-medium ${
                                        variant.id === productDetails.variant.id ? "border-primary !text-primary" : ""
                                    }`}
                                >
                                    {`${variant.size} ${productData.unit_of_measure}`}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            {productDetails.isOtherDetailsShow && (
                <div className="bg-primary w-full overflow-hidden flex flex-col h-full absolute top-0 left-0 z-50">
                    <div className="relative px-6 pt-[24px] pb-5 w-full flex-col h-full overflow-y-auto flex-grow">
                        <h2 className="mb-[10px] text-[20px] leading-[30px] font-medium tracking-[-0.01em] text-white">{t("Product Description")}</h2>
                        <p className={`text-[14px] leading-[19.6px] font-normal text-white tracking-[-0.001em] ${productDetails.details ? "border-b pb-[20px]" : ""} `}>{productDetails.description}</p>
                        {productDetails.details.map((detail, index) => (
                            <div key={index} className="py-4 border-b border-white ">
                                <div className="flex justify-between items-center">
                                    <p className="text-left text-[14px] leading-[18.2px] text-white font-semibold tracking-[-0.001em]">{detail.name}</p>
                                    <button
                                        onClick={() => setIsCollapsed({ isShow: isCollapsed.id === index ? !isCollapsed.isShow : true, id: index })}
                                        className="text-right text-TextColor text-[14px] font-normal"
                                    >
                                        {isCollapsed.isShow && isCollapsed.id === index ? <Minus className="text-white w-[18px] h-[18px]" /> : <Plus className="text-white w-[18px] h-[18px]" />}
                                    </button>
                                </div>
                                {isCollapsed.isShow && isCollapsed.id === index && <p className="pt-2 text-[14px] leading-[19.6px] font-normal text-white tracking-[-0.001em]">{detail.description}</p>}
                            </div>
                        ))}
                    </div>
                    <button onClick={() => setProductDetails((prevDetails) => ({ ...prevDetails, isOtherDetailsShow: false }))} className="absolute top-[16px] right-[16px] text-white rounded">
                        <XClose className="text-white w-[18px] h-[18px]" />
                    </button>
                </div>
            )}
        </div>
    );
};

export default ProductInfo;
