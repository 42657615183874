import React, { FC, useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { Stripe, StripeElements, TokenResult } from "@stripe/stripe-js";
import { useTranslation } from "react-i18next";
import { axiosPost } from "src/utils/requestClient";
import { API } from "src/constants/api";
import { errorCode } from "src/constants/common";
import { getBookingInfo, setIsCardLoading, setSubmitTriggered } from "../Booking.slice";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import WCardTabComponent from "./WCardTabComponent";
import MCardTabComponent from "./MCardTabComponent";

interface IFormData {
    cardnumber: string;
    date: string;
    cvv: string;
    holdername: string;
}
interface ICardTabComponent {
    loadStripeCardList: () => Promise<void>;
}

const CardTabComponent: FC<ICardTabComponent> = ({ loadStripeCardList }) => {
    const { t } = useTranslation();
    const uiState = useAppSelector((uiData) => uiData.UiStates);
    const dispatch = useAppDispatch();
    const bookingInfo = useAppSelector(getBookingInfo);
    const stripe: Stripe | null = useStripe();
    const elements: StripeElements | null = useElements();
    const [isCreateCardLoading, setIsCreateCardLoading] = useState(false);

    useEffect(() => {
        if (bookingInfo.submitTriggered && !bookingInfo.isCardAdd) {
            handleSubmit(onSubmit)();
            dispatch(setSubmitTriggered(false));
        }
    }, [bookingInfo.submitTriggered, bookingInfo.isCardAdd]);

    const [cardError, setCardError] = useState<{
        cardNumber?: string;
        cardExpiry?: string;
        cardCvc?: string;
    }>({});

    const methods = useForm<IFormData>({
        defaultValues: {
            cardnumber: "",
            date: "",
            cvv: "",
            holdername: "",
        },
    });

    const {
        handleSubmit,
        setError,
        formState: { isValid },
    } = methods;

    const onSubmit = async (data: IFormData) => {
        dispatch(setIsCardLoading(true));
        setIsCreateCardLoading(true);
        const cardElement = elements?.getElement(CardNumberElement);

        if (stripe && cardElement) {
            const { error, token }: TokenResult = await stripe.createToken(cardElement);
            if (error) {
                const errCode = error.code as string;
                const parts = errCode.split("_");

                if (parts[1] === "number") {
                    await setCardError((prev) => ({
                        ...prev,
                        cardNumber: error?.message,
                    }));
                }
                if (parts[1] === "expiry") {
                    await setCardError((prev) => ({
                        ...prev,
                        cardExpiry: error?.message,
                    }));
                }
                if (parts[1] === "cvc") {
                    await setCardError((prev) => ({
                        ...prev,
                        cardCvc: error?.message,
                    }));
                }
            }

            if (!data.holdername || data.holdername === "") {
                setError("holdername", {
                    type: "manual",
                    message: t("This field is required"),
                });
            }

            if (isValid && !error) {
                handleCreateCard(token?.id);
            } else {
                dispatch(setIsCardLoading(false));
                setIsCreateCardLoading(false);
            }
        }
    };

    const handleCreateCard = async (tokenId: string) => {
        try {
            setIsCreateCardLoading(true);
            dispatch(setIsCardLoading(true));
            const response = await axiosPost(API.STRIPE.CARD_ADD, {
                token: tokenId,
            });
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                // handleClose(true);
                loadStripeCardList();
                return;
            }

            throw response.data;
        } catch (err: any) {
            toast.error(err?.message);
        } finally {
            dispatch(setIsCardLoading(false));
            setIsCreateCardLoading(false);
        }
    };

    return (
        // <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col h-full">
        <FormProvider {...methods}>
            {uiState.isTablet ? <WCardTabComponent setCardError={setCardError} cardError={cardError} /> : <MCardTabComponent setCardError={setCardError} cardError={cardError} />}
        </FormProvider>
        // <div className="flex gap-3 justify-end bg-contentBackground pb-0 w-full">
        //     <CustomButton primary isLoading={isCreateCardLoading} disabled={isCreateCardLoading} className={`!max-w-max px-4`} type="submit">
        //         Add card
        //     </CustomButton>
        // </div>
        // </form>
    );
};

export default CardTabComponent;
