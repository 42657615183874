import React, { useState } from "react";
import { useLocalizedSetting } from "src/hooks/useLocalizedSetting";
import { locationDefaultImg } from "src/theme/Images";
interface Props {
    data: any;
}
const About: React.FC<Props> = ({ data }) => {
    const [imageError, setImageError] = useState(false);
    const { getLocalizedSetting } = useLocalizedSetting();
    const handleImageError = () => {
        setImageError(true);
    };
    const imageUrl = imageError ? locationDefaultImg : data?.image_url;

    const title = getLocalizedSetting(data, "title");
    const subtitle = getLocalizedSetting(data, "subtitle");
    const description = getLocalizedSetting(data, "description");

    return (
        <div className="mx-auto w-full text-txtcolor pt-9 md:spacing-top">
            {/* {data?.is_title && <h1 className="custom-heading uppercase  text-txtcolor dark:text-white mb-[1.8vw]">{data?.title}</h1>} */}
            {(data?.is_subtitle || data?.is_description || data?.is_title) && (
                <div className="flex flex-col md:flex-row w-full text-TextColor mb-5 md:mb-9 xl:mb-[54px]">
                    <div className="w-full lg:w-1/2">
                        {data?.is_title && <span className="custom-heading uppercase">{title}</span>}
                        {data?.is_subtitle && <p className="text-[24px] leading-[36px] md:text-[28px] md:leading-[36px] lg:text-[32px] lg:leading-[38.4px] font-normal">{subtitle}</p>}
                    </div>

                    {data?.is_description && (
                        <div className="w-full md:max-w-[460px] lg:max-w-[594px] 3xl:max-w-[792px] ml-auto">
                            <p className="text-[16px] leading-[24px] font-light tracking-[-0.01rem]">{description}</p>
                        </div>
                    )}
                </div>
            )}
            {data?.is_image ? (
                <div className="w-full aspect-[2.4/1] object-cover bg-cover bg-center bg-no-repeat" style={{ backgroundImage: `url(${imageUrl})` }}>
                    {" "}
                    <img src={data?.image_url} alt="" onError={handleImageError} style={{ display: "none" }} />
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default About;
