import { IDetails, IImages, IVariant } from "../../Template2/Product/Product.interface";
import { Swiper as SwiperType } from "swiper/types";

export interface IProductInfo {
    id: number;
    name: string;
    variants: IVariant[];
    images: IImages[];
    details: IDetails[];
    description: string;
    unit_of_measure: string;
    [key: string]: any;
}

export interface IWProduct {
    isLoading: boolean;
    productData: IProductInfo[];
    handlePrevious: () => void;
    handleNext: () => void;
    setSwiperRef: React.Dispatch<React.SetStateAction<SwiperType | undefined>>;
    selectedLocation: number | null;
    handleProduct: (selectedProductData: IProductInfo, variant: IVariant) => () => void;
}

export interface IMProduct {
    isLoading: boolean;
    productList: IProductInfo[];
    handlePrevious: () => void;
    handleNext: () => void;
    setSwiperRef: React.Dispatch<React.SetStateAction<SwiperType | undefined>>;
    handleProduct: (selectedProductData: IProductInfo, variant: IVariant) => () => void;
}

export interface IMProductInfo {
    productData: IProductInfo;
    handleProduct: (selectedProductData: IProductInfo, variant: IVariant) => () => void;
}

export const init = {
    // When window width is <= 768px
    640: {
        slidesPerView: 2,
        spaceBetween: 15,
    },
    1024: {
        slidesPerView: 3,
        spaceBetween: 20,
    },
    1280: {
        slidesPerView: 4,
        spaceBetween: 20,
    },
};

export interface IIndividualProductDetail {
    productData: IProductInfo[];
}
