import React, { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomButton from "src/components/CustomButton";
import OTPField from "src/components/OTPField/OTPField";
import { IVerifyOtp } from "../Password.interface";

const MVerifyOtp: FC<IVerifyOtp> = ({ authData, loading, handlerOtpAgainSend }: any) => {
    const { t } = useTranslation();
    const { control, watch } = useFormContext();
    const otpWatch = watch("otp");
    return (
        <div className="mobile-model-inner-wrapper font-primary">
            <div className="mobile-model-inner flex flex-col">
                <span className="text-[14px] leading-[18.2px] text-txtAppointmentColor tracking-[-0.01rem] font-semibold">
                    {authData.isEmail ? authData.email : authData.phone.fullFormatedPhoneNumber}
                </span>
                <div className="w-full mt-[12px]">
                    <Controller
                        name="otp"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                            <div className="flex flex-col">
                                <OTPField otp={value} setOtp={onChange} errors={error} />
                                {error && <p className="text-error">{error.message}</p>}
                            </div>
                        )}
                    />
                    <div className="mt-[6px] flex flex-col items-center">
                        <div className="flex items-center w-full justify-between gap-1">
                            <p className="text-[#475467] text-[12px] font-semibold -tracking-[0.02rem] leading-[20px] flex">
                                {authData.isEmail ? t("Enter the code sent to your email") : t("Enter the code sent to your phone")}
                            </p>
                            <CustomButton
                                type="button"
                                disabled={loading}
                                onClick={handlerOtpAgainSend}
                                className="text-primary !border-0 !p-0 !h-auto leading-[20px] !font-semibold !bg-transparent !text-[12px] text-center flex flex-row ring-0"
                                shadow={false}
                            >
                                {t("Resend code")}
                            </CustomButton>
                        </div>
                    </div>
                    <div className="mt-[20px] w-full mb-4 flex flex-col">
                        <CustomButton type="submit" isLoading={loading} disabled={otpWatch && otpWatch.length !== 4 ? true : false} primary className="w-full !h-[48px]">
                            <span>{t("Continue")}</span>
                        </CustomButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MVerifyOtp;
