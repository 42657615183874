import React, { useState } from "react";
import { DefaultAuthDataInit, initAction, IAuthData, IInitAction } from "./Auth.interface";
import VerifyPassword from "./Password/VerifyPassword";
import PhoneEmail from "./Login/PhoneEmail";
import VerifyOtp from "./Login/VerifyOtp";
import Login from "./Login/Login";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { setIsShowLoginModal } from "src/redux/reducers/Login.slice";
import ForgotPassword from "./Password/ForgotPassword";
import ForgotPasswordVerifyOtp from "./Password/VerifyOtp";
import NewPassword from "./Password/NewPassword";
import Sidebar from "src/components/Sidebar";
import PopupModal from "src/components/PopupModal";
import { useBookingConditionsStatus } from "src/hooks/useBookingConditionsStatus";
import { useShopNavigation } from "src/hooks/useShopNavigation";
import { getBookingInfo } from "../BookAppointment/Booking.slice";

const Auth = () => {
    const [action, setAction] = useState(initAction);
    const [authData, setAuthData] = useState<IAuthData>(DefaultAuthDataInit);
    const dispatch = useAppDispatch();
    const uiState = useAppSelector((data) => data.UiStates);
    const AuthUserState = useAppSelector((data) => data.AuthUser);
    const booking = useAppSelector(getBookingInfo);
    const { hasSelectedRequiredFields } = useBookingConditionsStatus();
    const { syncTempStoreOnClose } = useShopNavigation();

    const handleClose = () => {
        if (hasSelectedRequiredFields) {
            syncTempStoreOnClose(booking);
        }
        dispatch(setIsShowLoginModal(false));
    };
    const handleAuthAction = (type: keyof IInitAction) => {
        setAction(() => {
            const resetActions = Object.keys(initAction).reduce((acc, key) => {
                acc[key as keyof IInitAction] = false;
                return acc;
            }, {} as IInitAction);
            return { ...resetActions, [type]: true };
        });
    };
    const handleVerifyOtpRedirect = () => {
        if (authData.email && authData.phone) {
        } else {
            handleAuthAction("phoneEmailVerification");
        }
    };
    const resetAuthAction = () => {
        setAction(initAction);
        setAuthData(DefaultAuthDataInit);
    };

    return (
        <>
            {uiState.isTablet ? (
                <PopupModal onClose={handleClose} size="max-w-[95%] min-w-[360px] mt-[76px] mx-auto md:w-[600px] font-primary outline-custom" className="!pt-0 !pb-0" dismissible>
                    <div className="w-full">
                        {action.otp ? (
                            <VerifyOtp
                                authData={authData}
                                setAuthData={setAuthData}
                                handleVerifyOtpRedirect={handleVerifyOtpRedirect}
                                handleAuthAction={handleAuthAction}
                                resetAuthAction={resetAuthAction}
                            />
                        ) : action.phoneEmailVerification ? (
                            <PhoneEmail authData={authData} setAuthData={setAuthData} handleAuthAction={handleAuthAction} />
                        ) : action.passwordVerification ? (
                            <VerifyPassword authData={authData} resetAuthAction={resetAuthAction} onCloseModal={handleClose} handleAuthAction={handleAuthAction} />
                        ) : action.forgotPassword ? (
                            <ForgotPassword authData={authData} handleAuthAction={handleAuthAction} setAuthData={setAuthData} />
                        ) : action.forgotPasswordOtp ? (
                            <ForgotPasswordVerifyOtp authData={authData} setAuthData={setAuthData} resetAuthAction={resetAuthAction} onCloseModal={handleClose} handleAuthAction={handleAuthAction} />
                        ) : action.newPassword ? (
                            <NewPassword authData={authData} handleAuthAction={handleAuthAction} />
                        ) : (
                            <Login authData={authData} setAuthData={setAuthData} handleAuthAction={handleAuthAction} handleClose={handleClose} />
                        )}
                    </div>
                </PopupModal>
            ) : (
                <Sidebar
                    dismissable
                    isOpen={uiState.isMobile && AuthUserState.isShowLoginModal}
                    position="bottom"
                    handleClose={() => handleClose()}
                    customClass={`w-full rounded-t-[16px] h-auto mobile-login-modal`}
                    size={""}
                >
                    {action.otp ? (
                        <VerifyOtp
                            authData={authData}
                            setAuthData={setAuthData}
                            handleVerifyOtpRedirect={handleVerifyOtpRedirect}
                            handleAuthAction={handleAuthAction}
                            resetAuthAction={resetAuthAction}
                        />
                    ) : action.phoneEmailVerification ? (
                        <PhoneEmail authData={authData} setAuthData={setAuthData} handleAuthAction={handleAuthAction} />
                    ) : action.passwordVerification ? (
                        <VerifyPassword authData={authData} resetAuthAction={resetAuthAction} onCloseModal={handleClose} handleAuthAction={handleAuthAction} />
                    ) : action.forgotPassword ? (
                        <ForgotPassword authData={authData} handleAuthAction={handleAuthAction} setAuthData={setAuthData} />
                    ) : action.forgotPasswordOtp ? (
                        <ForgotPasswordVerifyOtp authData={authData} setAuthData={setAuthData} resetAuthAction={resetAuthAction} onCloseModal={handleClose} handleAuthAction={handleAuthAction} />
                    ) : action.newPassword ? (
                        <NewPassword authData={authData} handleAuthAction={handleAuthAction} />
                    ) : (
                        <Login authData={authData} setAuthData={setAuthData} handleAuthAction={handleAuthAction} handleClose={handleClose} />
                    )}
                </Sidebar>
            )}
        </>
    );
};

export default Auth;
