import React, { useEffect } from "react";
import { PlaceholderImgPNG } from "src/theme/Images";
import { ISpecialist } from "../../../../Interface";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { setIsShowSpecialistProfileModal, setShowSpecialistProfileModalData } from "../../../../redux/reducers/Ui.slice";
import { getBookingInfo, setTempStore } from "../../Booking.slice";
import { InfoCircle } from "@untitled-ui/icons-react/build/cjs";
import { onError } from "src/utils/global-functions";

interface IProps {
    specialistData: ISpecialist;
}

const SpecialistCardComponent = ({ specialistData }: IProps) => {
    const dispatch = useAppDispatch();
    const uiStates = useAppSelector((data) => data.UiStates);
    const bookingInfo = useAppSelector(getBookingInfo);

    useEffect(
        () => () => {
            dispatch(setIsShowSpecialistProfileModal(false));
            dispatch(setShowSpecialistProfileModalData(undefined));
        },
        [],
    );
    const handleClick = () => {
        const isSelected = bookingInfo?.tempStore?.selectedSpecialist?.id === specialistData.id;
        dispatch(setTempStore({ selectedSpecialist: isSelected ? undefined : specialistData }));

        if (uiStates.isShowSpecialistProfileModal) {
            dispatch(setShowSpecialistProfileModalData(specialistData));
            dispatch(setIsShowSpecialistProfileModal(true));
        }
    };

    return (
        <div className="w-full px-2 pb-4" onClick={handleClick} key={specialistData.id}>
            <div
                className={`cursor-pointer border border-borderColorPrimary p-3 rounded-secondary h-full hover:shadow-[0_2px_16px_0_rgba(0,0,0,0.10)]  ${
                    bookingInfo?.tempStore?.selectedSpecialist?.id === specialistData.id ? "shadow-[0_2px_16px_0_rgba(0,0,0,0.10)] bg-primaryHover" : ""
                }  h-auto border-borderColorPrimary hover:bg-primaryHover`}
            >
                <div className="">
                    <img className="rounded-secondary aspect-square w-full  object-cover" src={specialistData.profile_image_url} alt="" onError={onError("Professional")} />
                </div>
                <div className="flex items-end gap-1 pb-1 pt-4">
                    <div className="flex flex-col flex-1 min-w-0">
                        <h2 className="mb-1 text-sm font-semibold text-txtAppointmentColor -tracking-[0.02rem] leading-[19.6px] truncate-custom">
                            {specialistData.first_name} {specialistData.last_name}
                        </h2>
                        <p className="text-textGrey text-xs font-normal -tracking-[0.01rem] leading-[18px] truncate-custom">{specialistData?.staff_role?.name}</p>
                    </div>
                    <div
                        className="cursor-pointer flex w-auto"
                        onClick={(e) => {
                            e.stopPropagation();
                            dispatch(setShowSpecialistProfileModalData(specialistData));
                            dispatch(setIsShowSpecialistProfileModal(true));
                        }}
                    >
                        <InfoCircle color="var(--brand-color)" width={16} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SpecialistCardComponent;
