import React, { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomButton from "src/components/CustomButton";
import MInputPasswordWithLabel from "src/components/InputPasswordWithLabel/MInputPasswordWithLabel";
import { IUpdatePassword } from "../Profile.interface";
import { CheckCircle } from "@untitled-ui/icons-react/build/cjs";

const MUpdatePassword: FC<IUpdatePassword> = ({ handleModalClose, passwordChange, handleValidation, isLoading, validationsPassed }) => {
    const { t } = useTranslation();
    const { control } = useFormContext();
    return passwordChange ? (
        <div className="mobile-model-inner-wrapper font-primary">
            <div className="mobile-model-inner flex flex-col">
                <div className="flex flex-row w-full p-4 border gap-3.5 !border-successMessagesBorder !bg-successMessagesBg rounded-xl">
                    <CheckCircle className="text-[#079455] w-5 h-5" />
                    <span className="text-[#101828] font-semibold text-[14px] leading-[20px]">{t("Your password has been changed successfully!")}</span>
                </div>
                <div className="mt-[20px] w-full mb-4 flex flex-col">
                    <CustomButton primary onClick={() => handleModalClose()} className={`w-full !h-[48px]`}>
                        <span>{t("Continue")}</span>
                    </CustomButton>
                </div>
            </div>
        </div>
    ) : (
        <div className="mobile-model-inner-wrapper font-primary">
            <div className="mobile-model-inner flex flex-col">
                <h5 className="login-model-title-mobile">{t("Change Password")}</h5>
                <span className="font-normal text-[12px] lg:text-sm leading-[18.2px] text-textGrey -tracking-[0.01rem]">{t("You can change password")}</span>
                <div className="flex flex-wrap mt-[12px]">
                    <Controller
                        name="old_password"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                            <div className="flex flex-col w-full mb-4">
                                <MInputPasswordWithLabel required name="old_password" label={t("Current password")} value={value} onChange={onChange} error={!!error} />
                                {error && <p className="text-error">{error.message}</p>}
                            </div>
                        )}
                    />
                    <Controller
                        name="password"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                            <div className="flex flex-col w-full mb-4">
                                <MInputPasswordWithLabel
                                    name="password"
                                    required
                                    label={t("New password")}
                                    value={value}
                                    onChange={(e: { target: { value: string } }) => {
                                        onChange(e);
                                        handleValidation(e.target.value);
                                    }}
                                    error={!!error}
                                />
                                {error && <p className="text-error">{error.message}</p>}
                            </div>
                        )}
                    />
                    <Controller
                        name="password_confirmation"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                            <div className="flex flex-col w-full">
                                <MInputPasswordWithLabel name="password_confirmation" label={t("Confirm password")} required value={value} onChange={onChange} error={!!error} />
                                {error && <p className="text-error">{error.message}</p>}
                            </div>
                        )}
                    />
                </div>
                <div className="mt-[20px] w-full mb-4 flex flex-col">
                    <CustomButton className={`w-full !h-[48px]`} primary isLoading={isLoading} disabled={isLoading || validationsPassed === true ? false : true} type="submit">
                        {t("Password Updated")}
                    </CustomButton>
                </div>
            </div>
        </div>
    );
};

export default MUpdatePassword;
