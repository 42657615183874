import React, { FC } from "react";
import { InstaSVG } from "src/theme/Images";
import { IMDProfessionalSwiper } from "./Professional.interface";
import { formatInstagramLink } from "src/utils/global-functions";
import { useTranslation } from "react-i18next";

const IndividualStaffDetail: FC<IMDProfessionalSwiper> = ({ staffList }) => {
    const { t } = useTranslation();
    const staffData = staffList[0];
    return (
        <>
            <div className="flex  flex-row gap-6 w-full">
                {" "}
                <div className="profile-images max-lg:w-[40%] lg:w-[50%] lg:max-w-[668px] lg:min-w-[512px] 2xl:min-w-[668px]">
                    <img src={staffData.image_url} alt="" className="w-full max-h-[438px] lg:min-h-[672px] lg:max-h-[672px] aspect-[1/1] profile-img object-cover cursor-pointer " />
                </div>
                <div className="flex flex-col w-[60%] 2xl:w-full">
                    <h3 className="text-[24px] leading-[36px] lg:text-[32px] lg:leading-[48px] uppercase text-TextColor font-medium">{staffData?.name}</h3>
                    <div className="flex justify-between mt-[12px] ">
                        <p className="text-[18px] leading-[22px] lg:text-[20px] lg:leading-[24.2px] text-TextColor font-medium">{staffData?.role}</p>
                        {staffData?.is_instgram_button && (
                            <a
                                href={`${formatInstagramLink(staffData?.instgram_button)}`}
                                target="_blank"
                                className="text-xs flex text-ProductBorderColor font-normal text-[12px] leading-[18px] items-center gap-2 border-[#D2D2D2] border py-[5px] pl-2 pr-2.5 mr-[1px]"
                            >
                                <img src={InstaSVG} alt="" className="" />@{staffData?.instgram_button ? staffData?.instgram_button : ""}
                            </a>
                        )}
                    </div>
                    <hr className=" border-TextColor my-4 lg:mt-5 lg:mb-8"></hr>
                    <div className="text-base lg:text-[20px] lg:leading-[30px] text-TextColor font-normal">{staffData?.en_description}</div>
                    {staffData?.is_gallery && (
                        <div className="flex flex-col mt-5 gap-5 lg:mt-8 lg:gap-8">
                            <p className="text-[18px] leading-[22px] lg:text-[20px] lg:leading-[24.2px] text-TextColor font-medium">{t("Gallery")}</p>
                            <div className="grid grid-cols-3 gap-5">
                                {staffData.gallery_url.length &&
                                    staffData.gallery_url.map((staffGalleryUrl: string, index: number) => (
                                        <img src={staffGalleryUrl} key={index} alt="" className="w-full object-cover aspect-[15/10]" />
                                    ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default IndividualStaffDetail;
