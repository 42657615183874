import React from "react";
import { PlaceholderImgPNG } from "src/theme/Images";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { setBookingInitialState, setSelectedShopLocation } from "../../../BookAppointment/Booking.slice";
import { IShopLocation } from "../../../../Interface";
import { ROUTES } from "../../../../constants/routes";
import { ArrowNarrowRight } from "@untitled-ui/icons-react/build/cjs";
import { PATH } from "src/constants/path";
import { onError } from "src/utils/global-functions";
interface IProps {
    location: IShopLocation;
    selectedShopData: IShopLocation | undefined;
    setSelectedShopData: React.Dispatch<React.SetStateAction<IShopLocation | undefined>>;
}

const BarberDetailCard = ({ location, selectedShopData, setSelectedShopData }: IProps) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const bookingState = useAppSelector((data) => data.Booking);
    return (
        <div
            onMouseEnter={() => setSelectedShopData(location)}
            onMouseLeave={() => setSelectedShopData(undefined)}
            onClick={() => {
                if (location.id !== bookingState.selectedShopLocation?.id || bookingState.isModifyingAppointment) {
                    dispatch(setBookingInitialState());
                }
                dispatch(setSelectedShopLocation(location));
                navigate(PATH.APPOINTEMENT);
            }}
        >
            <label
                htmlFor={`${location.id}`}
                className={`bg-cardColor group flex shadow-card relative rounded-secondary  cursor-pointer outline-none ring-0 p-3 min-h-[95px] hover:shadow-[0_2px_16px_0_rgba(0,0,0,0.10)] ${
                    selectedShopData?.id === location.id ? "border-borderColorPrimary border" : "border-borderColorPrimary border"
                } `}
            >
                <div className="lg:w-[100px] max-w-[100px] lg:h-[71px] object-cover min-w-[100px] min-h-[70px] h-[70px] mr-[12px] lg:mr-5">
                    <img src={location.profile_image_url} alt="" className="w-full h-full object-cover rounded-secondary" onError={onError("Location")} />
                </div>
                <div className="p-0 flex-1 w-full flex flex-col justify-center gap-[3px] lg:gap-[8px]">
                    <span className="text-[16px] font-normal text-txtcolor !leading-[22.4px] -tracking-[0.02rem]">{location.name}</span>
                    <span className="text-[14px] text-textGrey font-normal !leading-[18.2px] -tracking-[0.02em]">{`${location.street}, ${location.city}, ${location.country} ${location.postal_code}`}</span>
                </div>
                <div className="hidden lg:flex items-center text-primaryAppointment opacity-0 group-hover:opacity-100">
                    <ArrowNarrowRight className="w-[22px]" />
                </div>
            </label>
        </div>
    );
};

export default BarberDetailCard;
