import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useTranslation } from "react-i18next";
import SidebarPaymentElement from "./PaymentMethod.Element";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || "");

interface IProps {
    closeAddNewCardTab: () => void;
}

const AddNewCardTabComponent = ({ closeAddNewCardTab }: IProps) => {
    const { t } = useTranslation();
    return (
        <>
            <div className="flex flex-col font-primary relative  h-[calc(100%)] w-full">
                <div className="flex flex-col mt-1 mb-4 lg:mt-[20px] px-4 lg:px-5">
                    <div className="text-[16px] lg:text-[20px] mb-1 leading-[22.4px] lg:leading-[28.4px] font-bold lg:font-semibold text-txtAppointmentColor -tracking-[0.01rem]">
                        {t("Add a card on file")}
                    </div>
                    <div className="font-normal text-[12px] lg:text-sm leading-[18.2px] text-textGrey -tracking-[0.01rem]">{t("Save a card on file for seamless checkouts.")}</div>
                </div>
                <Elements stripe={stripePromise}>
                    <SidebarPaymentElement handleClose={closeAddNewCardTab} successBtnText={t("Add card")} />
                </Elements>
            </div>
        </>
    );
};
export default AddNewCardTabComponent;
