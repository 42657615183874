import React, { memo, FC, forwardRef, useEffect } from "react";
import { IProps } from "./PopupModal.interface";
import { Modal } from "flowbite-react";
import CustomButton from "../CustomButton";
import { logout } from "src/utils/global-functions";
import GetLogo from "../GetLogo";

const PopupModal: FC<IProps> = forwardRef<HTMLDivElement, IProps>(
    (
        {
            children,
            size = "md",
            dismissible = false,
            position = "top",
            onClose,
            title = "",
            className = "",
            primaryButton = "",
            secondaryButton = "",
            acceptAction = () => {},
            declineAction = () => {},
            isCrossSign = true,
            isLogout = false,
            isLogo = false,
        },
        ref,
    ) => {
        useEffect(() => {
            if (isLogout) {
                const handleClickOutside = (event: any) => {
                    const element = ref && typeof ref !== "function" && ref.current;
                    if (element && !element.contains(event.target)) {
                        if (isLogout) {
                            logout();
                        }
                        onClose();
                    }
                };
                document.addEventListener("mousedown", handleClickOutside);
                return () => {
                    document.removeEventListener("mousedown", handleClickOutside);
                };
            }
        }, [isLogout]);

        return (
            <Modal
                ref={ref}
                show
                popup
                dismissible={dismissible}
                size={size}
                position={position}
                className="modal_backdrop"
                theme={{ content: { inner: `bg-containerBgColor rounded-[12px] shadow relative focus:outline-none focus-visible:outline-none focus-within:outline-none`, base: `${size}` } }}
                onClose={onClose}
            >
                {title ? <Modal.Header className="border-b border-borderColorPrimary px-5 py-4 ">{title}</Modal.Header> : isCrossSign ? <Modal.Header className="model-header-btn" /> : ""}
                <Modal.Body className="p-0 overflow-visible">
                    {isLogo && (
                        <div className="pt-[50px] flex justify-center items-center text-center">
                            <GetLogo />
                        </div>
                    )}
                    {children}
                </Modal.Body>
                {primaryButton || secondaryButton ? (
                    <Modal.Footer className="border-t border-borderColorPrimary px-5 py-4">
                        <div className="flex gap-4 w-full">
                            <CustomButton className="w-1/2" secondary onClick={declineAction}>
                                {secondaryButton}
                            </CustomButton>
                            <CustomButton className="w-1/2" primary onClick={acceptAction}>
                                {primaryButton}
                            </CustomButton>
                        </div>
                    </Modal.Footer>
                ) : null}
            </Modal>
        );
    },
);

export default memo(PopupModal);
