import React, { useEffect, useState } from "react";
import SelectBox from "../../../../components/SelectBox";
import { useTranslation } from "react-i18next";
import MDProfessionalSwiper from "./MDProfessionalSwiper";
import { useLocalizedSetting } from "src/hooks/useLocalizedSetting";
interface Props {
    data: any;
    locationSetting: any;
}
const Professional: React.FC<Props> = ({ data, locationSetting }) => {
    const { getLocalizedSetting } = useLocalizedSetting();
    const { t } = useTranslation();
    const [locationOptions, setLocationOptions] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState<any>(null);
    const [staffList, setStaffList] = useState([]);

    useEffect(() => {
        if (selectedLocation) {
            const filteredStaff = data?.staff.filter((staffInfo: any) => staffInfo.location.some((location: any) => location === selectedLocation));
            setStaffList(filteredStaff);
        } else {
            setStaffList(data?.staff);
        }
    }, [selectedLocation]);

    useEffect(() => {
        const locationOptionsData: any = locationSetting.locations.map((location: any) => ({
            value: location.title,
            label: location.title,
            id: location.title,
        }));
        const selectAllOption = {
            value: null,
            label: "All Locations",
            id: 0,
        };
        const updatedLocationOptionsData: any = [selectAllOption, ...locationOptionsData];

        setLocationOptions(updatedLocationOptionsData);
        // setSelectedLocation(locationOptionsData[0].value);
    }, []);

    const handleLocation = (e: any) => {
        setSelectedLocation(e.value);
    };

    const title = getLocalizedSetting(data, "title");

    return (
        <div className="w-full spacing-top pt-9 md:text-txtcolor">
            <div className="flex flex-col">
                <div className="w-full flex flex-col md:flex-row justify-between">
                    {data?.is_title && <h2 className="custom-heading uppercase">{title}</h2>}
                    <div className="flex justify-between md:justify-center items-center space-x-2 mt-4 mb-4 md:mb-0 md:mt-0">
                        <div className="flex w-full justify-between">
                            <div className="w-full flex-1">
                                <SelectBox
                                    placeholder={"All Locations"}
                                    name="location"
                                    allowClear={false}
                                    options={locationOptions}
                                    inputClass="w-[180px] md:max-w-[180px] h-[43px]"
                                    searchable={true}
                                    // defaultMenuIsOpen={true}
                                    // menuIsOpen
                                    // defaultMenuIsOpen
                                    onChange={handleLocation}
                                    value={locationOptions.filter((item: any) => item.value === selectedLocation)}
                                    disabled={false}
                                    // inputClass={`${errors.gender ? "is-invalid border-solid" : ""}`}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* Carousel Arrows */}
                {staffList.length > 0 ? (
                    <div className="flex flex-wrap justify-between gap-1 ">
                        <MDProfessionalSwiper staffList={staffList} />
                    </div>
                ) : (
                    <>
                        <div className="flex flex-col min-h-[380px] items-center justify-center">
                            <span className="text-TextColor text-[16px] leading-[21px] font-medium">{t("No available specialists")}</span>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default Professional;
