import React, { useEffect, useState } from "react";
import DateTimePick from "./DateTimePick";
import { format, isToday } from "date-fns";
import ButtonCmp from "../../../components/ButtonCmp";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import BarLoader from "../../../components/BarLoader";
import { useTranslation } from "react-i18next";
import { getBookingInfo, setStep, setTempStore } from "../Booking.slice";
import { EBookingTabState } from "../bookAppointment.interface";
import { useNavigate } from "react-router-dom";
import { PATH } from "src/constants/path";
import Stepper from "../Stepper";
import CustomButton from "src/components/CustomButton";
interface IProps {
    closeCurrentTab: () => void;
    selectedDate: Date;
    setSelectedDate: React.Dispatch<React.SetStateAction<Date>>;
    selectedTimeSlot: string | undefined;
    setSelectedTimeSlot: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const SelectDateAndTime = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const bookingState = useAppSelector(getBookingInfo);
    const uiState = useAppSelector((data) => data.UiStates);
    const [selectedDate, setSelectedDate] = useState<Date>(bookingState.selectedBookingDate ? new Date(bookingState.selectedBookingDate) : new Date());
    const [selectedTimeSlot, setSelectedTimeSlot] = useState<string | undefined>(undefined);

    useEffect(() => {
        dispatch(setTempStore({ SelectionCount: bookingState.selectedBookingDate && bookingState.selectedBookingTime ? 1 : 0 }));
    }, [bookingState.selectedBookingDate, bookingState.selectedBookingTime]);

    useEffect(() => {
        setSelectedTimeSlot(bookingState.selectedBookingTime ?? "");

        return () => {
            setSelectedTimeSlot(undefined);
        };
    }, []);
    const dispatch = useAppDispatch();
    useEffect(() => {
        handleStep(EBookingTabState.SELECT_DATE_TIME);
    }, []);
    const handleStep = async (step: EBookingTabState) => {
        await dispatch(setStep(step));
    };
    const handleBack = () => {
        navigate(`${PATH.APPOINTEMENT}`);
        handleStep(EBookingTabState.TAB_MENU);
    };

    return (
        <div className="w-full h-full overflow-hidden flex flex-col">
            <div className="flex justify-between items-center px-4 lg:px-2 mb-4 shrink-0">
                <div className="title text-txtcolor md:mt-0 w-full">
                    <h3 className="location-title-text">{t("Select a date and time")}</h3>
                    <p className="location-sub-text">{uiState.isMobile ? t("Please book an available time slot.") : t("Please choose a time from the available slots.")}</p>
                </div>

                {!uiState.isMobile && (
                    <div className="flex items-center justify-center gap-4 flex-row">
                        <p className="font-medium text-txtcolor text-sm  xl:text-base whitespace-nowrap leading-[140%] flex -tracking-[0.02rem]">{format(selectedDate, "MMMM, Y")}</p>
                        <CustomButton
                            primary
                            className={`!h-[36px] !min-w-[70px] w-full leading-[18.2px] outline-none focus:outline-none focus-within:outline-none focus-visible:outline-none focus:!ring-0 rounded-secondary text-xs font-bold flex justify-center items-center ${
                                selectedDate && isToday(selectedDate) ? "bg-primary text-white border-primary border" : " border-primary border text-primary bg-containerBgColor"
                            }`}
                            onClick={() => setSelectedDate(new Date())}
                        >
                            <span className="flex -tracking-[0.02rem] max-w-max">{t("Today")}</span>
                        </CustomButton>
                    </div>
                )}
            </div>
            <div className="px-4 lg:px-2 mb-[24px] shrink-0">
                <Stepper />
            </div>
            <DateTimePick
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                selectedTimeSlot={selectedTimeSlot}
                setSelectedTimeSlot={setSelectedTimeSlot}
                closeCurrentTab={() => handleStep(EBookingTabState.TAB_MENU)}
            />
        </div>
    );
};

export default SelectDateAndTime;
