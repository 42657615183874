import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import * as Yup from "yup";
import WUpdatePhone from "./Web/WUpdatePhone";
import { IPhoneDetail, IPhoneVerifyStep, IUpdatePhoneField, IUpdatePhoneFieldKey, IUpdatePhonePayload } from "./Profile.interface";
import { usePhone } from "src/hooks/usePhone";
import { API } from "src/constants/api";
import { axiosPatch, axiosPost } from "src/utils/requestClient";
import { errorCode } from "src/constants/common";
import { me } from "src/redux/reducers/common/Common.slice";
import MUpdatePhone from "./Mobile/MUpdatePhone";
import parsePhoneNumberFromString, { CountryCode } from "libphonenumber-js";

const UpdatePhone = ({ handleModalClose, setIsSuccess }: { handleModalClose: () => void; setIsSuccess?: React.Dispatch<React.SetStateAction<boolean>> }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const uiState = useAppSelector((uiData) => uiData.UiStates);
    const { isPhoneValid } = usePhone();
    const [isLoading, setIsLoading] = useState(false);
    const [isOtpLoading, setIsOtpLoading] = useState(false);
    const [verifyStep, setVerifyStep] = useState<IPhoneVerifyStep>({ phone: true, otp: false, success: false });
    const [phoneDetails, setphoneDetails] = useState<IPhoneDetail>({ formatedPhone: "", phone: "", country_code: "CA" });

    const schema = Yup.object({
        phone: Yup.string()
            .required(t("This field is required"))
            .test({
                message: t("Invalid phone number"),
                name: "invalid",
                test: function (value) {
                    const { phone_country_code: phoneCountryCode } = this.parent;
                    // Ensure 'value' is a string before calling 'isPhoneValid'
                    if (value && typeof value === "string") {
                        const isValid = isPhoneValid(value, phoneCountryCode);
                        return isValid;
                    }
                    return false;
                },
            }),
        phone_country_code: Yup.string().required("This field is required"),
    }).required();

    const methods = useForm<IUpdatePhoneField>({
        resolver: yupResolver(schema),
        defaultValues: {
            phone_country_code: "CA",
            phone: "",
            otp: "",
        },
    });

    const { handleSubmit, setError, resetField } = methods;

    const handleChange = async (data: IUpdatePhoneField) => {
        setIsLoading(true);
        const countryCode = data.phone_country_code as CountryCode;
        const phoneNumber = parsePhoneNumberFromString(data.phone, countryCode);
        if (phoneNumber) {
            setphoneDetails({
                formatedPhone: phoneNumber.formatInternational(),
                country_code: data.phone_country_code as CountryCode,
                phone: data.phone,
            });
        }
        if (verifyStep.otp) {
            const payload = {
                ...data,
                otp: data.otp ?? "",
            };
            await axiosPost(API.USER.PHONE_VERIFY_OTP, payload)
                .then(async (response) => {
                    dispatch(me());
                    if (setIsSuccess) {
                        setIsSuccess(true);
                    }
                    setVerifyStep((prevState) => ({
                        ...prevState,
                        otp: false,
                        success: true,
                    }));
                })
                .catch((error: any) => {
                    const response = error.response.data;
                    if (response.status === errorCode.unprocessable) {
                        if (response.data) {
                            let message = "";
                            Object.keys(response.data).forEach((field) => {
                                message = response.data[field][0];
                                setError(field as IUpdatePhoneFieldKey, {
                                    type: "manual",
                                    message: message,
                                });
                                return;
                            });
                        }
                        return;
                    }
                })
                .finally(() => setIsLoading(false));
        } else {
            const payload = { phone: data.phone, country_code: countryCode };
            const asyncFunction = handlerSendCode(payload);
            asyncFunction();
        }
    };

    const handlerSendCode = (phoneData: IPhoneDetail) => async () => {
        resetField("otp");
        setIsOtpLoading(true);
        const payload: IUpdatePhonePayload = {
            phone_country_code: phoneData.country_code,
            phone: phoneData.phone,
        };

        await axiosPatch(API.USER.PHONE_UPDATE, payload)
            .then(async (response) => {
                setVerifyStep((prevState) => ({
                    ...prevState,
                    otp: true,
                    phone: false,
                }));
            })
            .catch((error: any) => {
                const response = error.response.data;
                if (response.status === errorCode.unprocessable) {
                    if (response.data) {
                        let message = "";
                        Object.keys(response.data).forEach((field) => {
                            message = response.data[field][0];
                            setError(field as IUpdatePhoneFieldKey, {
                                type: "manual",
                                message: message,
                            });
                            return;
                        });
                    }
                    return;
                }
            })
            .finally(() => {
                setIsOtpLoading(false);
                setIsLoading(false);
            });
    };
    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(handleChange)}>
                    {uiState.isTablet ? (
                        <WUpdatePhone
                            verifyStep={verifyStep}
                            isLoading={isLoading}
                            handleModalClose={handleModalClose}
                            phoneDetails={phoneDetails}
                            isOtpLoading={isOtpLoading}
                            handlerSendCode={handlerSendCode}
                        />
                    ) : (
                        <MUpdatePhone
                            verifyStep={verifyStep}
                            isLoading={isLoading}
                            handleModalClose={handleModalClose}
                            phoneDetails={phoneDetails}
                            isOtpLoading={isOtpLoading}
                            handlerSendCode={handlerSendCode}
                        />
                    )}
                </form>
            </FormProvider>
        </>
    );
};

export default UpdatePhone;
