import React, { FC } from "react";
import { Swiper } from "swiper/react";
import { ICustomSwiper } from "./CustomSwiper.interface";

const CustomSwiper: FC<ICustomSwiper> = ({
    children,
    setSwiperRef,
    className = "mySwiper w-full",
    slidesPerView = 1,
    slidesPerGroup = 1,
    spaceBetween = 16,
    breakpoints,
    loop = true,
    onInit,
    key,
}) => (
    <>
        <Swiper
            key={key}
            slidesPerView={slidesPerView}
            slidesPerGroup={slidesPerGroup}
            spaceBetween={spaceBetween}
            pagination={{
                clickable: true,
                dynamicBullets: true,
            }}
            onInit={onInit}
            loop={loop}
            onSwiper={setSwiperRef}
            navigation={false}
            className={className}
            breakpoints={breakpoints}
        >
            {children}
        </Swiper>
    </>
);

export default CustomSwiper;
