import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "src/redux/hooks";
import * as Yup from "yup";
import WUpdatePassword from "./Web/WUpdatePassword";
import MUpdatePassword from "./Mobile/MUpdatePassword";
import { IUpdatePasswordField } from "./Profile.interface";
import { API } from "src/constants/api";
import { axiosPost } from "src/utils/requestClient";
import { errorCode } from "src/constants/common";
import { toast } from "react-toastify";

export type IFieldKey = "password" | "password_confirmation";
const UpdatePassword = ({ handleModalClose, setIsSuccess }: { handleModalClose: () => void; setIsSuccess?: React.Dispatch<React.SetStateAction<boolean>> }) => {
    const { t } = useTranslation();
    const uiState = useAppSelector((uiData) => uiData.UiStates);
    const [passwordChange, setPasswordChange] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const schema = Yup.object({
        old_password: Yup.string().required(t("This field is required")),
        password: Yup.string().required(t("This field is required")),
        password_confirmation: Yup.string()
            .required(t("This field is required"))
            .oneOf([Yup.ref("password")], t("Passwords do not match")),
    }).required();

    const methods = useForm<IUpdatePasswordField>({
        resolver: yupResolver(schema),
        defaultValues: {
            old_password: "",
            password: "",
            password_confirmation: "",
        },
    });
    const { handleSubmit, setError } = methods;

    const [validations, setValidations] = useState({
        minLength: false,
        hasDigit: false,
        hasNonDigit: false,
    });

    const handleValidation = (value: string) => {
        setValidations({
            minLength: value.length >= 8,
            hasDigit: /\d/.test(value),
            hasNonDigit: /\D/.test(value),
        });
    };

    const validationsPassed = validations.minLength && validations.hasDigit && validations.hasNonDigit;

    const handleChange = (data: IUpdatePasswordField) => {
        setIsLoading(true);
        if (!passwordChange) {
            axiosPost(API.USER.CHANGE_PASSWORD, data)
                .then(() => {
                    if (setIsSuccess) {
                        setIsSuccess(true);
                    }
                    setPasswordChange(true);
                })
                .catch((error) => {
                    const response = error.response.data;
                    if (response.status === errorCode.unprocessable) {
                        if (response.data) {
                            Object.keys(response.data).forEach((field) => {
                                setError(field as IFieldKey, {
                                    type: "manual",
                                    message: response.data[field][0],
                                });
                            });
                            return;
                        }
                    }
                    toast.error(response.message);
                })
                .finally(() => setIsLoading(false));
        }
    };
    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(handleChange)}>
                {uiState.isTablet ? (
                    <WUpdatePassword
                        handleValidation={handleValidation}
                        validationsPassed={validationsPassed}
                        isLoading={isLoading}
                        passwordChange={passwordChange}
                        handleModalClose={handleModalClose}
                    />
                ) : (
                    <MUpdatePassword
                        handleValidation={handleValidation}
                        validationsPassed={validationsPassed}
                        isLoading={isLoading}
                        passwordChange={passwordChange}
                        handleModalClose={handleModalClose}
                    />
                )}
            </form>
        </FormProvider>
    );
};

export default UpdatePassword;
