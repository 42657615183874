import React, { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomButton from "src/components/CustomButton";
import OTPField from "src/components/OTPField/OTPField";
import FloatingInputbox from "src/components/FloatingInputbox";
import { IUpdateEmail } from "../Profile.interface";
import { CheckCircle } from "@untitled-ui/icons-react/build/cjs";

const MUpdateEmail: FC<IUpdateEmail> = ({ email, isLoading, handleModalClose, verifyStep, handlerSendCode, isOtpLoading }) => {
    const { t } = useTranslation();
    const { control, watch } = useFormContext();
    const otpWatch = watch("otp");
    return (
        <div className="mobile-model-inner-wrapper font-primary">
            <div className="mobile-model-inner flex flex-col">
                {verifyStep.otp && (
                    <div className="flex flex-col w-full">
                        <span className="text-txtAppointmentColor font-semibold leading-[18.2px] text-[14px] tracking-[-0.01rem]">{email}</span>
                        <div className="w-full mt-[12px]">
                            <Controller
                                name="otp"
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                    <div className="flex flex-col">
                                        <OTPField otp={value} setOtp={onChange} errors={error} />
                                        {error && <p className="text-error text-center">{error.message}</p>}
                                    </div>
                                )}
                            />
                        </div>
                        <div className="mt-[6px] flex flex-col items-center">
                            <div className="flex items-center w-full justify-between gap-1">
                                <p className="text-[#475467] text-[12px] font-semibold -tracking-[0.02rem] leading-[20px] flex">{t("Enter the code sent to your email")}</p>
                                <CustomButton
                                    type="button"
                                    isLoading={isOtpLoading}
                                    disabled={isOtpLoading}
                                    onClick={handlerSendCode(email)}
                                    className="text-primary !border-0 !p-0 !h-auto leading-[20px] !font-semibold !bg-transparent !text-[12px] text-center flex flex-row ring-0"
                                    shadow={false}
                                >
                                    {t("Resend code")}
                                </CustomButton>
                            </div>
                        </div>
                    </div>
                )}
                {verifyStep.email && (
                    <div className="flex flex-col w-full">
                        <span className="text-txtAppointmentColor font-bold leading-[22.4px] text-[16px] tracking-[-0.01rem]">{t("Please update your email address")}</span>
                        <div className="w-full mt-[12px]">
                            <Controller
                                name="email"
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                    <div className="w-full flex flex-col">
                                        <FloatingInputbox name="email" label={t("Email")} value={value} handleChange={onChange} error={!!error} />
                                        {error && <p className="text-error">{error.message}</p>}
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                )}
                {verifyStep.success && (
                    <>
                        <div className="flex flex-row w-full p-4 border gap-3.5 border-successMessagesBorder bg-successMessagesBg rounded-xl">
                            <CheckCircle className="text-[#079455] w-5 h-5" />
                            <span className="text-txtAppointmentColor font-semibold text-[14px] leading-[20px]">{t("Your email has been successfully updated")}</span>
                        </div>
                        <CustomButton primary onClick={() => handleModalClose()} className={`w-full mt-5 !h-[48px] mb-4`}>
                            <span>{t("Continue")}</span>
                        </CustomButton>
                    </>
                )}
                {!verifyStep.success && (
                    <div className="mt-[20px] w-full flex mb-4">
                        <CustomButton
                            primary
                            isLoading={isLoading}
                            disabled={isLoading || (verifyStep.otp && (isLoading || !otpWatch || otpWatch.length !== 4))}
                            className={`w-full !h-[48px]`}
                            type="submit"
                        >
                            <span>{verifyStep.email ? t("Update") : t("Continue")}</span>
                        </CustomButton>
                    </div>
                )}
            </div>
        </div>
    );
};

export default MUpdateEmail;
