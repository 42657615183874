import React, { FC, useState } from "react";
import { useForm, Resolver, FormProvider } from "react-hook-form";
import { IOtp } from "../Auth.interface";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { LOCAL_STORAGE_KEYS, errorCode } from "src/constants/common";
import { setIsShowLoginModal, userLogin, userPhoneEmailLogin } from "src/redux/reducers/Login.slice";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { IInitAction } from "../Auth.interface";
import { axiosPost } from "src/utils/requestClient";
import { API } from "src/constants/api";
import { me } from "src/redux/reducers/common/Common.slice";
import WVerifyOtp from "./Web/WVerifyOtp";
import MVerifyOtp from "./Mobile/MVerifyOtp";
import { IVerifyOtpProps } from "./Login.interface";
import { useTranslation } from "react-i18next";

const VerifyOtp: FC<IVerifyOtpProps> = ({ authData, handleVerifyOtpRedirect, handleAuthAction, setAuthData }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const uiState = useAppSelector((uiData) => uiData.UiStates);
    const [loading, setLoading] = useState(false);

    const handlerLoginOtpAgainSend = async () => {
        reset();
        let cread;
        if (authData.phone && authData.email) {
            cread = {
                email: authData.email,
                phone: `+${authData.phone.code}${authData.phone.number}`,
                phone_country_code: authData.phone.country,
            };
            const result: any = await dispatch(userPhoneEmailLogin(cread));
            if (result.type === userPhoneEmailLogin.fulfilled.toString()) {
            }
        } else {
            if (authData.isEmail) {
                cread = {
                    email: authData.email,
                };
            } else {
                cread = {
                    phone: `+${authData.phone.code}${authData.phone.number}`,
                    phone_country_code: authData.phone.country,
                };
            }
            const result: any = await dispatch(userLogin(cread));
            if (result.type === userLogin.fulfilled.toString()) {
            }
        }
    };
    const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
            e.preventDefault();
            handleSubmit(handleSave)();
        }
    };
    const schema = Yup.object({
        otp: Yup.string().length(4, t("OTP must be exactly 4 digits")).required(t("This field is required")),
    }).required();

    const methods = useForm<IOtp>({
        resolver: yupResolver(schema) as Resolver<IOtp>,
        defaultValues: {
            otp: "",
        },
    });
    const { handleSubmit, setError, reset } = methods;
    const handleClose = () => {
        dispatch(setIsShowLoginModal(false));
    };
    const handleSave = async (data: any) => {
        setLoading(true);
        let payload;
        if (authData.isEmail) {
            payload = {
                email: authData.email,
                otp: data.otp,
            };
        } else {
            payload = {
                phone: `+${authData.phone.code}${authData.phone.number}`,
                phone_country_code: authData.phone.country,
                otp: data.otp,
            };
        }
        if (authData.email && authData.phone) {
            axiosPost(API.USER.PHONE_EMAIL_VERIFY_OTP, payload)
                .then(async (response: any) => {
                    localStorage.setItem(LOCAL_STORAGE_KEYS.authToken, response.data.data.token);
                    handleClose();
                    await dispatch(me());
                })
                .catch((error) => {
                    const response = error.response.data;
                    if (response.status === errorCode.unprocessable) {
                        Object.keys(response.data).forEach((field) => {
                            setError("otp", {
                                type: "manual",
                                message: response.data[field][0],
                            });
                        });
                        // setValue('number', new Array(4).fill(''));
                    }
                })
                .finally(() => setLoading(false));
        } else {
            axiosPost(API.USER.VERIFY_OTP, payload)
                .then(async (result: any) => {
                    const enhancedAuthData = {
                        ...authData,
                        otp: data.otp,
                    };
                    if (result?.data?.data?.is_new_user) {
                        setAuthData(enhancedAuthData);
                        handleVerifyOtpRedirect();
                    } else {
                        if (result?.data?.data?.token && result?.data?.data?.is_password_set === false) {
                            setAuthData(enhancedAuthData);
                            localStorage.setItem(LOCAL_STORAGE_KEYS.authToken, result.data.data.token);
                            handleClose();
                            await dispatch(me());
                        } else if (result?.data?.data?.token) {
                            setAuthData(enhancedAuthData);
                            localStorage.setItem(LOCAL_STORAGE_KEYS.authToken, result.data.data.token);
                            handleClose();
                            await dispatch(me());
                        } else {
                            const nameAuthData = {
                                ...enhancedAuthData,
                                name: result?.data?.data?.first_name,
                            };
                            setAuthData(nameAuthData);
                            handleAuthAction("passwordVerification");
                        }
                    }
                })
                .catch((error) => {
                    const response = error.response.data;
                    if (response.status === errorCode.unprocessable) {
                        Object.keys(response.data).forEach((field) => {
                            setError("otp", {
                                type: "manual",
                                message: response.data[field][0],
                            });
                        });
                        // setValue('number', new Array(4).fill(''));
                        // setactiveOTPIndex(0);
                    }
                })
                .finally(() => setLoading(false));
        }
    };

    const handleAction = (type: keyof IInitAction) => () => {
        handleAuthAction(type);
    };

    const handleChangePhoneEmail = async () => {
        if (authData.isEmail) {
            setAuthData({
                ...authData,
                email: null,
                isEmail: false,
            });
        } else {
            setAuthData({
                ...authData,
                phone: null,
                isEmail: true,
            });
        }
        handleAuthAction("phoneEmailVerification");
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(handleSave)}>
                {uiState.isTablet ? (
                    <WVerifyOtp
                        authData={authData}
                        loading={loading}
                        handleKeyPress={handleKeyPress}
                        handlerLoginOtpAgainSend={handlerLoginOtpAgainSend}
                        handleChangePhoneEmail={handleChangePhoneEmail}
                        handleAction={handleAction}
                    />
                ) : (
                    <MVerifyOtp
                        authData={authData}
                        loading={loading}
                        handleKeyPress={handleKeyPress}
                        handlerLoginOtpAgainSend={handlerLoginOtpAgainSend}
                        handleChangePhoneEmail={handleChangePhoneEmail}
                        handleAction={handleAction}
                    />
                )}
            </form>
        </FormProvider>
    );
};

export default VerifyOtp;
