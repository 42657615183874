import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setNotificationToken } from "../../redux/reducers/Login.slice";
import { errorCode } from "../../constants/common";
import { toast } from "react-toastify";
import { onMessageListener, requestForFirebaseToken } from "../../services/API/firebase";
import { axiosPost } from "../../utils/requestClient";
import { API } from "../../constants/api";
import Template1Header from "../Landing/Template1/Header";
import Template2Header from "../Landing/Template2/Header";
import CommonHeader from "./CommonHeader";
import { currentShop, currentUser } from "src/redux/reducers/common/Common.slice";
import { XClose } from "@untitled-ui/icons-react/build/cjs";
import { FlairLogo } from "src/theme/Images";
import CustomButton from "src/components/CustomButton";
import { isGetApp, setIsGetApp } from "src/redux/reducers/Landing.slice";
import DownloadApp from "../Location/DownloadApp";
import PopupModal from "src/components/PopupModal";
import { useTranslation } from "react-i18next";

export type IActionType = {
    downloadAppInfo: boolean;
};
const initAction: IActionType = {
    downloadAppInfo: false,
};

const Header = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const AuthUserState = useAppSelector((data) => data.AuthUser);
    const user = useAppSelector(currentUser);
    const isGetAppVisible = useAppSelector(isGetApp);
    const template = useAppSelector((data) => data.Common.template);
    const [isVisible, setIsVisible] = useState<boolean>(true);
    const [action, setAction] = useState(initAction);
    const shop = useAppSelector(currentShop);

    const handleHideClick = (): void => {
        setIsVisible(false);
        dispatch(setIsGetApp(false));
    };

    useEffect(() => {
        const getOrUpdateFirebaseNotificationToken = async () => {
            let FCM_TOKEN = await requestForFirebaseToken();
            if (!FCM_TOKEN) {
                FCM_TOKEN = await requestForFirebaseToken();
            }

            if (FCM_TOKEN && FCM_TOKEN !== AuthUserState.notificationToken && user) {
                dispatch(setNotificationToken(FCM_TOKEN));

                try {
                    const response = await axiosPost(API.NOTIFICATION.CREATE_UPDATE, {
                        token: FCM_TOKEN,
                    });
                    if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                        return;
                    }

                    throw response.data;
                } catch (err: any) {
                    toast.error(err?.message);
                }
            }
        };

        getOrUpdateFirebaseNotificationToken();

        if (user) {
            onMessageListener()
                .then((payload: any) => {
                    if (payload?.notification?.title !== "Appointment Requested") {
                        toast.success(payload?.notification?.body);
                    }
                })
                .catch((err) => {});
        }
    }, [user, AuthUserState.notificationToken, dispatch]);

    const renderHeader = () => {
        switch (template) {
            case "template_1":
                return <Template1Header />;
            case "template_2":
                return <Template2Header />;
            default:
                return <CommonHeader />;
        }
    };

    const handleAction =
        (actionType: keyof IActionType | null = null) =>
        () => {
            if (actionType) {
                setAction({
                    ...initAction,
                    [actionType]: true,
                });
            } else {
                setAction(initAction);
            }
        };

    return (
        <>
            {isVisible && (
                <div className="relative top-0 flex items-center py-[14px] px-4 bg-navbarBgColor transition-transform duration-300 border-b-[2px] border-borderColorPrimary lg:hidden shrink-0">
                    <div className="mr-[6px]">
                        <XClose className="w-5 h-5 text-textGrey" onClick={handleHideClick} />
                    </div>
                    <div className="flex-1 flex items-center ">
                        <img src={shop && shop.admin_logo_image_url ? shop.admin_logo_image_url : FlairLogo} alt="" className="rounded-[6px] max-h-[43px]" />
                        <div className="flex flex-col ml-4 flex-1 min-w-0">
                            <span className="text-txtAppointmentColor font-bold text-[14px] leading-[21px] tracking-[-0.01rem] truncate-custom">{t("Get the app")}</span>
                            <span className="leading-[15px] text-[10px] font-normal text-txtAppointmentColor truncate-custom">{t("For the best user experience")}</span>
                        </div>
                    </div>

                    <CustomButton
                        primary
                        className="btn-appointment-primary  hover:text-white !px-2 !py-[2px] !h-[24px]  font-bold !text-[12px] whitespace-nowrap "
                        onClick={handleAction("downloadAppInfo")}
                    >
                        <span className="truncate-custom">{t("USE APP")}</span>
                    </CustomButton>
                    {action.downloadAppInfo && (
                        <PopupModal onClose={handleAction()} size="max-w-[95%] min-w-[340px] mx-auto useAppModel font-primary outline-custom" className="" dismissible position="center">
                            <DownloadApp handleClose={handleAction()} />
                        </PopupModal>
                    )}
                </div>
            )}
            {renderHeader()}
        </>
    );
};

export default Header;
