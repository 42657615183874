import React, { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomButton from "src/components/CustomButton";
import WInputPasswordWithLabel from "src/components/InputPasswordWithLabel/WInputPasswordWithLabel";
import { IUpdatePassword } from "../Profile.interface";

const WUpdatePassword: FC<IUpdatePassword> = ({ handleModalClose, passwordChange, handleValidation, isLoading, validationsPassed }) => {
    const { t } = useTranslation();
    const { control } = useFormContext();
    return passwordChange ? (
        <>
            <p>{t("success")}</p>
            <div className="mt-[52px]">
                <CustomButton className={`w-full !h-[48px]`} primary onClick={() => handleModalClose()}>
                    {t("Continue")}
                </CustomButton>
            </div>
        </>
    ) : (
        <div className="content font-primary model-inner-wrapper">
            <h5 className="login-model-title">{t("Change Password")}</h5>
            <p className="login-model-subtitle flex flex-col">{t("password_description")}</p>
            <div className="flex flex-wrap mt-[42px]">
                <Controller
                    name="old_password"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                        <div className="flex flex-col w-full mb-4">
                            <WInputPasswordWithLabel
                                required
                                name="old_password"
                                label={t("Current password")}
                                placeholder={t("Enter your current password")}
                                value={value}
                                onChange={onChange}
                                inputPasswordClass="!h-[44px]"
                                error={!!error}
                            />
                            {error && <p className="text-error">{error.message}</p>}
                        </div>
                    )}
                />
                <Controller
                    name="password"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                        <div className="flex flex-col w-full mb-4">
                            <WInputPasswordWithLabel
                                name="password"
                                required
                                label={t("New password")}
                                placeholder={t("Enter your new password")}
                                value={value}
                                onChange={(e: { target: { value: string } }) => {
                                    onChange(e);
                                    handleValidation(e.target.value);
                                }}
                                inputPasswordClass="!h-[44px]"
                                error={!!error}
                            />
                            {error && <p className="text-error">{error.message}</p>}
                        </div>
                    )}
                />
                <Controller
                    name="password_confirmation"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                        <div className="flex flex-col w-full">
                            <WInputPasswordWithLabel
                                name="password_confirmation"
                                label={t("Confirm password")}
                                required
                                placeholder={t("Enter your Confirm password")}
                                value={value}
                                onChange={onChange}
                                error={!!error}
                                inputPasswordClass="!h-[44px]"
                            />
                            {error && <p className="text-error">{error.message}</p>}
                        </div>
                    )}
                />
            </div>
            <div className="mt-[52px]">
                <CustomButton className={`w-full !h-[48px]`} primary isLoading={isLoading} disabled={isLoading || validationsPassed === true ? false : true} type="submit">
                    {t("Password Updated")}
                </CustomButton>
            </div>
        </div>
    );
};

export default WUpdatePassword;
