import React, { useState } from "react";
import SpecialistDetail from "../../../components/SpecialistDetail";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import ServicesBasket from "./ServicesBasket";
import { setIsShowSpecialistProfileModal, setShowSpecialistProfileModalData } from "../../../redux/reducers/Ui.slice";
import { EBookingTabState } from "../bookAppointment.interface";
import { useTranslation } from "react-i18next";
import moment from "moment";
import ProductBasket from "./ProductBasket";
import BasketCalculation from "./BasketCalculation";
import { BookingPreviewItem, getBookingInfo, setSelectedPromocode, setTempStore } from "../Booking.slice";
import ManageSubmit from "./ManageSubmit";
import Sidebar from "src/components/Sidebar";
import Promotion from "./Promotion";
import { BiChevronDown } from "react-icons/bi";
import { IoClose } from "react-icons/io5";
import { ArrowRight, Calendar } from "@untitled-ui/icons-react/build/cjs";
import CustomButton from "src/components/CustomButton";
import { onError } from "src/utils/global-functions";
import { isGetApp } from "src/redux/reducers/Landing.slice";

const AppointmentPreview = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const bookingInfo = useAppSelector(getBookingInfo);
    const uiStates = useAppSelector((data) => data.UiStates);
    const [isPromotionPopupOpen, setIsPromotionPopupOpen] = useState(false);
    const isGetAppVisible = useAppSelector(isGetApp);
    const handlePromotion = () => {
        setIsPromotionPopupOpen(!isPromotionPopupOpen);
    };

    const handlePromotionRemove = (e: any) => {
        e.stopPropagation();
        dispatch(setSelectedPromocode(undefined));
    };

    const handleRedirect = () => {
        if (bookingInfo && bookingInfo.selectedShopLocation) {
            const latitude = bookingInfo.selectedShopLocation.latitude;
            const longitude = bookingInfo.selectedShopLocation.longitude;
            const googleMapUrl = `https://www.google.com/maps?q=${latitude},${longitude}&z=15&output=embed`;
            window.open(googleMapUrl, "_blank");
        }
    };

    const handleSpecialistSelect = () => {
        dispatch(setTempStore({ selectedSpecialist: uiStates.showSpecialistProfileModalData }));
        dispatch(setIsShowSpecialistProfileModal(false));
    };

    const handleClose = () => {
        dispatch(setIsShowSpecialistProfileModal(false));
        dispatch(setShowSpecialistProfileModalData(undefined));
    };

    const updateDataArray = (data: any, key: string, value: any) => {
        const index = data.findIndex((item: any) => item.key === key);
        if (index !== -1) {
            return data.map((item: any, i: any) => (i === index ? { key, value } : item));
        } else {
            return [...data, { key, value }];
        }
    };

    const bookingPreviewJsx = () => {
        let data = bookingInfo.bookingPreview && bookingInfo.bookingPreview.length ? [...bookingInfo.bookingPreview] : [];

        if (bookingInfo.tempStore.selectedSpecialist) {
            data = updateDataArray(data, "selectedSpecialist", bookingInfo.tempStore.selectedSpecialist);
        }

        if (bookingInfo.tempStore.selectedBookingDateTime) {
            data = updateDataArray(data, "selectedBookingDateTime", bookingInfo.tempStore.selectedBookingDateTime);
        }

        if (bookingInfo.tempStore.selectedServices && bookingInfo.tempStore.selectedServices.length) {
            data = updateDataArray(data, "selectedServices", bookingInfo.tempStore.selectedServices);
        }
        return (
            <>
                {data.map((item: BookingPreviewItem, index: number) => {
                    const { key, value } = item;

                    if (key === "selectedSpecialist") {
                        return value?.profile_image_url || value?.full_name ? (
                            <div key={key} className="flex flex-col gap-2 mt-3">
                                <h1 className="text-[14px] leading-[19.6px] font-medium text-textGrey  -tracking-[0.01rem]">{t("Specialist")}</h1>
                                <div className="flex gap-3 items-center">
                                    <div className="img">
                                        <img src={value.profile_image_url} className="w-6 h-6 rounded-primary" alt="" onError={onError("Professional")} />
                                    </div>
                                    <div className="flex flex-col">
                                        <p className="text-txtAppointmentColor text-[14px] leading-[18.2px] font-medium -tracking-[0.01rem]">{value.full_name}</p>
                                    </div>
                                </div>
                            </div>
                        ) : null;
                    }

                    if (key === "selectedBookingDateTime") {
                        return (
                            <div key={`datetime-${index}`} className="flex flex-col gap-2 lg:px-0 mt-3 ">
                                <h2 className="text-[14px] leading-[19.6px] font-medium text-textGrey  -tracking-[0.01rem]">{t("Date and time")}</h2>
                                <div className="flex gap-2 items-center">
                                    <div className="bg-bgContent w-[25px] h-[25px] justify-center items-center rounded-primary text-[14px] text-textGrey hidden lg:flex">
                                        <Calendar className="w-[18px] h-[18px]" />
                                    </div>
                                    <p className="text-txtAppointmentColor text-sm leading-[18.2px] font-medium -tracking-[0.01rem]">{moment(value).format("dddd, MMMM Do - YYYY h:mm a")}</p>
                                </div>
                            </div>
                        );
                    }

                    if (key === "selectedServices") {
                        if (value.length) {
                            return <ServicesBasket value={value} key={key} />;
                        }
                    }

                    return null;
                })}
            </>
        );
    };
    return (
        <div className="lg:rounded-secondary relative h-full scrollbar-hide bg-cardBackground shadow-[0px_4px_24px_10px_#B4B4B412] overflow-hidden flex flex-col">
            <div className="relative w-full flex-col hidden lg:flex shrink-0">
                <img
                    src={bookingInfo.selectedShopLocation?.location_image_url}
                    className="rounded-secondary rounded-b-none w-full h-[120px] lg:h-[160px] object-cover"
                    alt=""
                    onError={onError("Location")}
                />{" "}
                <div className=" lg:absolute w-full lg:w-[94%] lg:top-[71px] pt-4 p-2.5 flex flex-col lg:mx-4 bg-[#00000066] rounded-secondary mt-2 lg:mt-0">
                    <div className="flex flex-col flex-1">
                        <h1 className="leading-[28px] text-lg lg:text-[12.8px] lg:leading-4 xl:text-base xl:leading-[22.4px] font-medium  text-white -tracking-[0.01rem]">
                            {bookingInfo.selectedShopLocation?.name}
                        </h1>
                    </div>
                    <div className="flex flex-row ">
                        <p className="mt-1 flex-1 text-secondaryTxt text-[11px] font-normal lg:text-xs lg:leading-5 truncate  md:text-sm md:leading-[18.2px] text-white -tracking-[0.01rem] mr-3">
                            {`${bookingInfo.selectedShopLocation?.street}, ${bookingInfo.selectedShopLocation?.city}, ${bookingInfo.selectedShopLocation?.country} ${bookingInfo.selectedShopLocation?.postal_code}`}
                        </p>
                        <span
                            className="flex items-center justify-bottom rounded-none text-white cursor-pointer text-sm whitespace-nowrap font-normal leading-[18.2px] border-b"
                            onClick={handleRedirect}
                        >
                            {t("Get directions")} <ArrowRight className="w-[18px] h-[18px] ml-2" />
                        </span>
                    </div>
                </div>
            </div>
            <div className={`lg:px-5 lg:pb-[5px] flex flex-col h-full overflow-y-auto scrollbar-hide mb-[68px]`}>
                <p className={`flex lg:mt-5 text-base font-semibold text-txtcolor -tracking-[0.02rem]  ${uiStates.isMobile ? "border-b border-borderColorPrimary px-4 pb-4" : ""}`}>
                    {t("Order summary")}
                </p>
                <div className="relative w-full flex-col gap-2 flex mt-3 lg:hidden px-4">
                    <span className="text-[14px] leading-[19.6px] font-medium text-textGrey -tracking-[0.01rem]">Location</span>
                    <div className="flex flex-row items-center gap-3">
                        <img src={bookingInfo.selectedShopLocation?.location_image_url} className="rounded-[8px]  w-[24px] h-[24px] object-cover" alt="" onError={onError()} />{" "}
                        <div className=" flex flex-col">
                            <h1 className="text-txtAppointmentColor font-medium text-[14px] leading-[19.6px] -tracking-[0.01rem] mb-1">{bookingInfo.selectedShopLocation?.name}</h1>

                            <p className="text-textGrey text-[12px] leading-[18px] font-normal -tracking-[0.01rem] ">
                                {`${bookingInfo.selectedShopLocation?.street}, ${bookingInfo.selectedShopLocation?.city}, ${bookingInfo.selectedShopLocation?.country} ${bookingInfo.selectedShopLocation?.postal_code}`}
                            </p>
                        </div>
                    </div>
                </div>

                <div className={`flex flex-col flex-1 ${uiStates.isMobile ? "px-4 " : ""}`}>
                    {bookingPreviewJsx()}
                    {bookingInfo.selectedProducts && bookingInfo.selectedProducts.length > 0 && <ProductBasket />}
                    {/* <div className="absolute bg-white bottom-10 w-full"> */}
                    {bookingInfo.step === EBookingTabState.FINALIZE_BOOKING && !bookingInfo.isModifyingAppointment && (
                        <div className="w-full mt-5">
                            <div className="text-[14px] leading-[19.6px] font-medium text-txtAppointmentColor -tracking-[0.01rem] mb-2">
                                {t("Add a promotion ")}
                                <span className="text-textGrey"> {t("(optional)")}</span>
                            </div>
                            <div
                                className="w-full h-[36px] lg:h-[40px] border border-borderColorSecondary rounded-secondary text-txtAppointmentColor px-3.5 py-2.5 flex justify-between items-center cursor-pointer text-base lg:text-xs xl:text-sm "
                                onClick={handlePromotion}
                            >
                                {bookingInfo?.selectedPromocode?.name ? (
                                    <div className="text-secondaryTxt flex h-[24px] border border-borderColorSecondary rounded-secondary px-[8px] py-[3px] font-medium text-[12px]  items-center">
                                        <span className="text-txtAppointmentColor pt-[2px]">{bookingInfo?.selectedPromocode?.name ?? ""}</span>
                                        <IoClose className="w-4 h-4 ml-2 text-disableLightGreyText" onClick={handlePromotionRemove} />
                                    </div>
                                ) : (
                                    t("Choose a promotion")
                                )}

                                <BiChevronDown className="text-textGrey w-5 h-5" />
                            </div>
                        </div>
                    )}

                    {bookingInfo.selectedSpecialist && bookingInfo.selectedBookingDate && bookingInfo.selectedServices && bookingInfo.selectedServices.length > 0 && <BasketCalculation />}
                    {/* </div> */}
                </div>

                {/* Show specialist detail component here */}
                {/* <AnimatePresence initial={false} onExitComplete={() => dispatch(setShowSpecialistProfileModalData(undefined))}>
                    {uiStates.isShowSpecialistProfileModal && (
                        <div className="absolute text-2xl bottom-0 w-full top-0 h-full pt-[80px] rounded-3xl bg-black bg-opacity-30">
                            <SpecialistDetail />
                        </div>
                    )}
                </AnimatePresence> */}
                {uiStates.isShowSpecialistProfileModal &&
                    (uiStates.isMobile ? (
                        <Sidebar isOpen={uiStates.isShowSpecialistProfileModal} position="bottom" handleClose={handleClose} isBottomView customClass="" size="w-[460px]" dismissable>
                            <div className="w-full flex flex-col flex-1 relative bg-contentBackground overflow-hidden h-full">
                                <SpecialistDetail />
                            </div>
                            <div className="flex w-full gap-3 mt-auto mb-3 bg-contentBackground">
                                <CustomButton className="w-full" secondary onClick={handleClose}>
                                    {t("Cancel")}
                                </CustomButton>
                                <CustomButton primary className="w-full" onClick={handleSpecialistSelect}>
                                    {t("Continue")}
                                </CustomButton>
                            </div>
                        </Sidebar>
                    ) : null)}
                <Sidebar
                    isOpen={isPromotionPopupOpen}
                    handleClose={handlePromotion}
                    size="w-[460px] top-line-remove"
                    position={uiStates.isMobile ? "bottom" : "right"}
                    customClass=""
                    isBottomView={uiStates.isMobile ? true : false}
                >
                    {isPromotionPopupOpen && <Promotion handleClose={handlePromotion} />}
                </Sidebar>
            </div>
            <div className="absolute lg:left-[20px] left-0 right-0 lg:right-[20px] bg-cardBackground bottom-[12px] lg:bottom-[20px]">
                <ManageSubmit isModal={uiStates.isMobile} />
            </div>
        </div>
    );
};

export default AppointmentPreview;
