import React from "react";
import "swiper/css";
import "swiper/css/pagination";
import { INSTAGRAM_URL } from "../../../../constants/common";
import CustomButton from "src/components/CustomButton";
import { useShopNavigation } from "src/hooks/useShopNavigation";
import StarRating from "src/components/StarRating/StarRating";
import { useTranslation } from "react-i18next";
import { useLocalizedSetting } from "src/hooks/useLocalizedSetting";
interface Props {
    data: any;
}
const Hero: React.FC<Props> = ({ data }) => {
    const { t } = useTranslation();
    const { getLocalizedSetting } = useLocalizedSetting();
    const { navigateBasedOnShopLocations } = useShopNavigation();
    const convertNumber = (input: string) => {
        const number = parseFloat(input);

        if (Number.isInteger(number)) {
            return Number(number.toFixed(1));
        } else {
            return Number(`${Math.floor(number * 10) / 10.0}`);
        }
    };

    const title = getLocalizedSetting(data, "title");
    const subtitle = getLocalizedSetting(data, "subtitle");
    const buttonTitle = getLocalizedSetting(data, "button");
    const reviewsDes = getLocalizedSetting(data, "reviews_description");

    return (
        <div className="w-full flex flex-col md:flex-row md:gap-10 md:items-end">
            {(data.is_title || data?.is_subtitle || data?.is_button || data?.is_instgram_button) && (
                <div className="flex pt-[54px] flex-col flex-1">
                    {data.is_title && <span className="text-[48px] leading-[48px] xl:text-[90px] xl:leading-[90px] uppercase text-TextColor font-normal mb-5 max-w-[1227px]">{title}</span>}
                    {(data?.is_subtitle || data?.is_button || data?.is_instgram_button) && (
                        <div className="flex-1 flex-col max-w-[1227px]">
                            {data?.is_subtitle && (
                                <div className="text-[16px] leading-[24px] font-light mb-[30px] text-TextColor">
                                    <span>{subtitle}</span>
                                </div>
                            )}

                            {(data?.is_button || data?.is_instgram_button) && (
                                <div className="flex flex-col gap-2 md:flex-row">
                                    {data?.is_button && (
                                        <CustomButton primary className="px-8 !h-[49px]" onClick={() => navigateBasedOnShopLocations()}>
                                            {buttonTitle}
                                        </CustomButton>
                                    )}
                                    {data?.is_instgram_button && (
                                        <a
                                            className="border border-TextColor txtcolor text-TextColor rounded-secondary text-center justify-center font-normal flex items-center lg:px-6 px-5 py-2 h-[49px] uppercase"
                                            target="_blank"
                                            href={`${INSTAGRAM_URL}/${data?.instgram_button}`}
                                        >
                                            {t("Follow us on Instagram")}
                                        </a>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
            <div className="flex flex-col md:items-end w-auto md:pb-[84px]">
                <span className="text-[22px] leading-[26.63px] opacity-50 font-normal mb-3 text-TextColor hidden md:block">{reviewsDes && `${reviewsDes}`}</span>
                {data?.is_reviews && (
                    <span className="flex items-center gap-2 flex-row mt-[20px] md:mt-0">
                        <div className="flex items-center gap-1">
                            <StarRating rate={convertNumber(data?.reviews)} className={"mx-auto md:mx-0"} starPadding="2px" />
                        </div>
                        <span className="text-TextColor max-md:flex max-md:gap-1 max-md:items-center text-[16px] leading-[22.2px] md:text-[28px] md:leading-[33.89px] font-medium">
                            {convertNumber(data?.reviews)}
                            <span className="flex md:hidden text-[16px] leading-[20.2px] md:text-[20px] font-normal md:leading-[24.2px]">({reviewsDes})</span>
                        </span>
                    </span>
                )}
                {/* Carousel Arrows */}
                {/* <div className="flex relative">
                    <button className="rounded-full flex justify-center items-center border border-TextColor cursor-pointer h-[52px] min-w-[52px] w-[52px] mr-2.5 bg-templateBg">
                        <ChevronLeft className="w-[24px] h-[24px] text-TextColor" />
                    </button>
                    <button className="rounded-full flex justify-center items-center cursor-pointer border border-TextColor bg-templateBg h-[52px] min-w-[52px] w-[52px]">
                        <ChevronRight className="w-[24px] h-[24px] text-TextColor" />
                    </button>
                </div> */}
            </div>
        </div>
    );
};

export default Hero;
