import React, { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomButton from "src/components/CustomButton";
import WPhoneOrEmail from "src/components/PhoneOrEmail/WPhoneOrEmail";
import { ChevronLeft } from "@untitled-ui/icons-react/build/cjs";
import { IPhoneEmail } from "../Login.interface";

const WPhoneEmail: FC<IPhoneEmail> = ({ authData, inputType, loading, handleBackToLogin, onInputChange, isButtonDisabled }) => {
    const { t } = useTranslation();
    const { control } = useFormContext();
    return (
        <div className="font-primary model-inner-wrapper">
            <div className="w-full">
                <span className="login-model-title">{!authData.isEmail ? t("Enter your email address") : t("Enter your mobile number")}</span>
                <p className="login-model-subtitle">
                    {!authData.isEmail ? t("We’ll use your email address to recover your account.") : t("We’ll use your mobile number to recover your account.")}
                    {/* {authData.email && !authData.phone
                    ? t('Add your mobile to aid in account recovery')
                    : authData.phone && !authData.email
                    ? t('Enter your email address')
                    : t('Enter your phone number or email address')} */}
                </p>
                <div className="mt-[30px]">
                    <div className="flex flex-wrap">
                        <label className="text-sm text-txtAppointmentColor leading-[18.2px] font-normal text-[14px] -tracking-[0.02rem] mb-1.5">
                            {!authData.isEmail ? t("Enter your email address") : t("Enter your phone number")}
                            <span className="asterisk">*</span>
                        </label>
                        <Controller
                            name="emailPhone"
                            control={control}
                            render={({ field: { onChange }, fieldState: { error } }: any) => (
                                <div className="flex flex-col w-full">
                                    <WPhoneOrEmail
                                        onNumberChange={(data: any) => {
                                            onChange(data);
                                            onInputChange(data);
                                        }}
                                        errors={error}
                                        singleType={!authData.isEmail ? "email" : "phone"}
                                        inputType={inputType}
                                        isFocus={true}
                                    />
                                </div>
                            )}
                        />
                    </div>
                    <div className="mt-[52px] w-full bottom-0 flex flex-col gap-2">
                        <CustomButton
                            type="button"
                            icon={<ChevronLeft width={20} />}
                            className="!border-0 ring-0 !p-0 !w-[32px] !h-[32px] absolute -left-[11px] lg:left-[12px] -top-[18px] lg:top-[12px] hover:!bg-gray-100 !rounded-lg  !bg-transparent text-center text-textGrey hover:!text-txtcolor"
                            shadow={false}
                            onClick={handleBackToLogin}
                        ></CustomButton>
                        <CustomButton
                            type="submit"
                            isLoading={loading}
                            disabled={loading || isButtonDisabled}
                            primary
                            className={`fl-btn bg-primary text-white font-bold !h-[48px] w-full rounded-secondary transition-all ease-in-out delay-75`}
                        >
                            <span>{t("Continue")}</span>
                        </CustomButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WPhoneEmail;
