import React, { useCallback, useState } from "react";
import { SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper/types";
import { format, parse } from "date-fns";
import { usePhone } from "../../../../hooks/usePhone";
import { ChevronLeft, ChevronRight } from "@untitled-ui/icons-react/build/cjs";
import CustomSwiper from "src/components/CustomSwiper/CustomSwiper";
import { onError } from "src/utils/global-functions";
import { useTranslation } from "react-i18next";
// import { Plus } from "@untitled-ui/icons-react";

interface Props {
    data: any;
    sectionIndex: number;
}
const Location: React.FC<Props> = ({ data, sectionIndex }) => {
    const { t } = useTranslation();
    const { getCustomFormatPhone } = usePhone();
    const [isCollapsed, setIsCollapsed] = useState<any>({ id: 1, isShow: true });
    const [swiperRef, setSwiperRef] = useState<SwiperType>();
    const handlePrevious = useCallback(() => {
        swiperRef?.slidePrev();
    }, [swiperRef]);
    const handleNext = useCallback(() => {
        swiperRef?.slideNext();
    }, [swiperRef]);

    const formatTimeRange = (hours: any) => {
        const from = format(parse(hours.from, "HH:mm:ss", new Date()), "h:mmaaa");
        const to = format(parse(hours.to, "HH:mm:ss", new Date()), "h:mmaaa");
        return `${from.toLowerCase()} → ${to.toLowerCase()}`;
    };

    const checkStatus = (array: any[]) => array.some((element) => element.status === true);

    return (
        <div className="flex flex-col">
            <div className="w-full flex items-center justify-between mb-4">
                <div className="slice-label">
                    <span className="num">{sectionIndex}</span>
                    <span className="slice-text">{t("Our locations")}</span>
                </div>
                {data?.locations && data?.locations.length > 0 && (
                    <div className="flex relative">
                        <button
                            disabled={data?.locations.length <= 1}
                            className={`rounded-full flex flex-col justify-center items-center border border-TextColor h-[26px] w-[26px] min-w-[26px] mr-3.5 bg-transparent ${
                                data?.locations.length <= 1 ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
                            }`}
                            onClick={handlePrevious}
                        >
                            <ChevronLeft className="text-TextColor w-4 h-4" />
                        </button>
                        <button
                            disabled={data?.locations.length <= 1}
                            className={`rounded-full flex flex-col justify-center items-center border border-TextColor h-[26px] w-[26px] min-w-[26px] bg-transparent ${
                                data?.locations.length <= 1 ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
                            }`}
                            onClick={handleNext}
                        >
                            <ChevronRight className="text-TextColor w-4 h-4" />
                        </button>
                    </div>
                )}
            </div>

            {data?.locations.length && (
                <div className="flex flex-col items-center">
                    <CustomSwiper setSwiperRef={setSwiperRef} className="mySwiper w-full" loop={data?.locations.length > 1}>
                        {data?.locations?.map((location: any, index: React.Key | null | undefined) => (
                            <SwiperSlide key={index}>
                                <>
                                    <div className="h-[192.29px] xl:h-[375px] 2xl:h-[370px] 3xl:h-[436px]">
                                        {location?.is_image && <img className="w-full h-full" src={`${location.image_url}`} alt="" onError={onError("Location")} />}
                                        {/* <img
                                        className="w-full h-[250px] md:h-[250px] lg:h-[295px] xl:h-[375px] 2xl:h-[425px] 3xl:h-[480px]"
                                        src={`${location?.is_image && location.image_url ? location.image_url : DefaultImageJPG}`}
                                        alt="Barbering"
                                    /> */}
                                    </div>
                                    {(checkStatus(location?.hours) || location.is_title || location.is_contact) && (
                                        <div className="pt-8">
                                            {checkStatus(location?.hours) && (
                                                <>
                                                    <h2 className="font-normal uppercase text-TextColor opacity-[.60] text-[16px] leading-[24px] tracking-[-0.01rem] mb-3">Opening Hours</h2>
                                                    <div className="w-full grid grid-cols-2 xl:grid-cols-3 gap-3">
                                                        {location?.hours
                                                            ?.filter((dataInfo: any) => dataInfo.status === true)
                                                            .map((dataInfo: any, dayIndex: React.Key | null | undefined) => (
                                                                <div key={`dayIndex${dayIndex}`} className="flex flex-col border border-templateCardBorderColor bg-templateCardBg p-3 location-hours">
                                                                    <div
                                                                        className="flex flex-col cursor-pointer text-TextColor "
                                                                        // onClick={() =>
                                                                        //     setIsCollapsed({
                                                                        //         isShow: isCollapsed.isShow && isCollapsed.id === `dayIndex${dayIndex}` ? false : true,
                                                                        //         id: `dayIndex${dayIndex}`,
                                                                        //     })
                                                                        // }
                                                                    >
                                                                        <span className="font-normal uppercase text-TextColor opacity-[.60] text-[12px] leading-[18px]">{dataInfo.day}</span>
                                                                        {/* <span className="text-xl font-thin text-TextColor">
                                                                {isCollapsed.isShow && isCollapsed.id === `dayIndex${dayIndex}` ? <Minus /> : <Plus />}
                                                            </span> */}
                                                                        <div className="font-normal lowercase text-TextColor text-[16px] leading-[24px] tracking-[-0.01rem]">
                                                                            {formatTimeRange(dataInfo)}
                                                                        </div>
                                                                    </div>
                                                                    {/* {isCollapsed.isShow && isCollapsed.id === `dayIndex${dayIndex}` && (
                                                            <div className="text-sm text-TextColor font-light max-w-[90%] mt-[6px]">{formatTimeRange(dataInfo)}</div>
                                                        )} */}
                                                                </div>
                                                            ))}
                                                    </div>
                                                </>
                                            )}
                                            {(location.is_title || location.is_contact) && (
                                                <div className="flex flex-col lg:flex-row mt-8 justify-between gap-3 lg:gap-2">
                                                    {location.is_title && (
                                                        <div className="flex flex-col">
                                                            <span className="text-[16px] leading-[24px] tracking-[-0.01px] font-normal uppercase text-TextColor opacity-[.60] mb-3">
                                                                {t("Location")}
                                                            </span>
                                                            <span className="text-[16px] leading-[24px] tracking-[-0.01px] font-medium uppercase text-TextColor">{location.title}</span>
                                                        </div>
                                                    )}
                                                    {location.is_contact && (
                                                        <div className="flex flex-col">
                                                            <span className="text-[16px] leading-[24px] tracking-[-0.01px] font-normal uppercase text-TextColor opacity-[.60] mb-3">
                                                                {t("Contact us")}
                                                            </span>
                                                            <div className="text-[16px] min-w-max leading-[24px] tracking-[-0.01px] font-medium uppercase text-TextColor">
                                                                {getCustomFormatPhone(location.contact, location?.country_code)}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </>
                            </SwiperSlide>
                        ))}
                    </CustomSwiper>
                </div>
            )}
        </div>
    );
};

export default Location;
