import { ISelectedTab } from "src/Interface";

export const navLinks: { name: string; tabName: ISelectedTab }[] = [
    { name: "Location", tabName: "location" },
    { name: "About us", tabName: "about" },
    { name: "Team", tabName: "team" },
    { name: "Services", tabName: "service" },
    { name: "Gallery", tabName: "gallery" },
    { name: "Academy", tabName: "academy" },
    { name: "Product", tabName: "product" },
    { name: "Testimonials", tabName: "testimonial" },
    { name: "(ENG / FRA)", tabName: "language" },
];

export const initAction = {
    completeProfile: false,
    profile: false,
};
export interface IInitAction {
    completeProfile: boolean;
    profile: boolean;
}
