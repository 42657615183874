import React, { useState } from "react";
import Info from "./Info";
import { useLocalizedSetting } from "src/hooks/useLocalizedSetting";
interface Props {
    data: any;
    sectionIndex: number;
}
const Service: React.FC<Props> = ({ data, sectionIndex }) => {
    const { getLocalizedSetting } = useLocalizedSetting();
    const [isCollapsed, setIsCollapsed] = useState<any>({ id: 0, isShow: false });
    const title = getLocalizedSetting(data, "title");
    return (
        <div className="w-full ">
            <div className="text-txtcolor">
                <div className="slice-label mb-[18px]">
                    <span className="num">{sectionIndex}</span>
                    {data?.is_title && <span className="slice-text">{title}</span>}
                </div>
                <div className="">
                    {data?.services?.map((service: any, index: number) => (
                        <Info index={index} service={service} key={service.id} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Service;
