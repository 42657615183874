import { ArrowRight } from "@untitled-ui/icons-react/build/cjs";
import React from "react";
import { useLocalizedSetting } from "src/hooks/useLocalizedSetting";

type Props = {
    service: any;
    isCollapsed: any;
    setIsCollapsed: any;
    index: number;
};
const Info: React.FC<Props> = ({ service, setIsCollapsed, isCollapsed, index }) => {
    const { getLocalizedSetting } = useLocalizedSetting();
    const name = getLocalizedSetting(service, "name");
    const description = getLocalizedSetting(service, "description");

    return (
        <div className="border-b flex flex-col relative  border-b-animation border-TextColor first:border-t pb-6 pt-6 max-md:py-4">
            <div className="w-full cursor-pointer flex items-start md:items-center">
                <div className="flex w-full flex-1 flex-col max-md:gap-1.5">
                    <h3 className="text-[20px] leading-[30px] md:text-[24px] md:leading-[36px] font-medium uppercase text-TextColor tracking-[-0.02em]">{name}</h3>
                    <h3 className="text-[16px] leading-[22.4px] font-normal tracking-[-0.01em] text-ProductBorderColor md:hidden">${Number(service.price).toFixed(2)}</h3>
                    <p className="font-light text-[16px] leading-[24px] tracking-[-0.01rem] text-TextColor">{description}</p>
                </div>
                <div className="flex justify-end items-start md:items-center">
                    <h3 className="text-[20px] leading-[28px] font-normal tracking-[-0.01em] text-ProductBorderColor pr-[48px] hidden md:block">${Number(service.price).toFixed(2)}</h3>
                    <ArrowRight className="text-TextColor h-5 w-5" />
                </div>
            </div>
        </div>
        //        <div className="border-b flex flex-col text-primaryText relative  border-b-animation border-borderColorPrimary">
        //        <div
        //            className="w-full cursor-pointer flex py-[5.2vw] md:py-[3vw] lg:py-[2vw] xl:py-[1.2vw]"
        //            onClick={() => setIsCollapsed({ isShow: isCollapsed.id === index ? !isCollapsed.isShow : true, id: index })}
        //        >
        //            <div className="flex w-full lg:w-1/2">
        //                <h3 className="text-[14px] lg:text-[1vw] leading-[1] pt-2.5 font-normal uppercase">{service.name}</h3>
        //            </div>
        //            <div className="flex lg:w-1/2 w--full justify-end lg:justify-between">
        //                <h3 className="text-[14px] lg:text-[1vw] hidden lg:flex font-normal leading-[1] pt-2.5 uppercase">{Number(service.price).toFixed(2)}</h3>
        //                {service?.is_description && (
        //                    <div className="text-right">
        //                        <button
        //                            onClick={() => setIsCollapsed({ isShow: isCollapsed.id === index ? !isCollapsed.isShow : true, id: index })}
        //                            className="text-right leading-[1] pt-2.5 text-[14px] lg:text-[1vw] flex justify-end font-normal min-w-36"
        //                        >
        //                            {isCollapsed.isShow && isCollapsed.id === index ? "LESS −" : "MORE +"}
        //                        </button>
        //                    </div>
        //                )}
        //            </div>
        //        </div>
        //        {isCollapsed.isShow && isCollapsed.id === index && service?.is_description && (
        //            <div className="flex flex-row">
        //                <div className="flex flex-col w-full lg:w-1/2 lg:ml-auto justify-end">
        //                    <h3 className="text-[14px] lg:text-[1vw] flex lg:hidden font-normal uppercase mb-2">{Number(service.price).toFixed(2)}</h3>
        //                    <p className="font-light text-[14px] md:text-[16px] xl:text-[1vw] mb-[1.4vw]">{service.description}</p>
        //                </div>
        //            </div>
        //        )}
        //    </div>
    );
};

export default Info;
