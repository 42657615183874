import { Calendar, SlashCircle01, XClose } from "@untitled-ui/icons-react/build/cjs";
import { format } from "date-fns";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { BiCalendarEdit } from "react-icons/bi";
import { GoDotFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { IAppointmentBooking, IAppointmentBookingServices } from "src/Interface/booked_appointment.interface";
import BookedBasketCalculation from "src/app/BookAppointment/AppointmentPreview/BasketCalculation/BookedBasketCalculation";
import {
    setBookingInitialState,
    setIsModifyingAppointment,
    setIsShowFinalizeBooking,
    setModifyingAppointmentId,
    setSelectedBookingDate,
    setSelectedBookingTime,
    setPaymentType,
    setPaymentOption,
    setOldPaymentOption,
    setSelectedProducts,
    setSelectedServices,
    setSelectedShopLocation,
    setSelectedSpecialist,
    setSelectedPromocode,
    setPreview,
    setBookingTotal,
} from "src/app/BookAppointment/Booking.slice";
import CustomButton from "src/components/CustomButton";
import DeletePopupModal from "src/components/DeletePopupModal/DeletePopupModal";
import { API } from "src/constants/api";
import { PATH } from "src/constants/path";
import { ROUTES } from "src/constants/routes";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { currentShop, getAllShopProducts } from "src/redux/reducers/common/Common.slice";
import { Facebook1SVG, GoogleSVG, Instagram1SVG, PlaceholderImgPNG } from "src/theme/Images";
import { calculateBookingTotal, onError } from "src/utils/global-functions";
import { axiosPatch } from "src/utils/requestClient";

interface IProps {
    booking: IAppointmentBooking;
    handleClose: () => void;
    setIsRefresh: (status: boolean) => void;
}

const BookingView = ({ booking, handleClose, setIsRefresh }: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const shop = useAppSelector(currentShop);
    const [isShowCancelAppointmentConfirmationModal, setIsShowCancelAppointmentConfirmationModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleModifyAppointment = async () => {
        dispatch(setBookingInitialState());
        handleClose();
        dispatch(setIsModifyingAppointment(true));
        dispatch(setModifyingAppointmentId(booking.id));

        dispatch(setIsShowFinalizeBooking(false));
        dispatch(setSelectedShopLocation(booking.location));

        dispatch(setSelectedSpecialist(booking.staff));
        dispatch(setPreview({ key: "selectedSpecialist", value: booking.staff }));
        dispatch(setSelectedBookingDate(booking.booking_date));
        dispatch(setSelectedBookingTime(format(new Date(`${booking.booking_date} ${booking.booking_start_time}`), "HH:mm")));
        dispatch(setPreview({ key: "selectedBookingDateTime", value: `${format(new Date(booking.booking_date), "Y-MM-dd")} ${booking.booking_start_time}` }));
        dispatch(setPaymentType(booking?.payment_type));
        dispatch(setPaymentOption(booking?.payment_option));
        dispatch(setOldPaymentOption(booking?.payment_option));

        if (booking.shop_promotion) {
            dispatch(setSelectedPromocode(booking?.shop_promotion));
        }

        const selectedServices: any = [];
        booking.booking_services.forEach((booking_service: IAppointmentBookingServices) => {
            const { service, quantity } = booking_service;
            for (let index = 0; index < quantity; index++) {
                selectedServices.push({ ...service, quantity: 1 });
            }
        });
        dispatch(setSelectedServices(selectedServices));
        dispatch(setPreview({ key: "selectedServices", value: selectedServices }));

        const selectedProducts: any = [];
        booking?.booking_products?.forEach((booking_product) => {
            const { product, quantity, product_variant: variant, product_inventory: inventory } = booking_product;
            for (let index = 0; index < quantity; index++) {
                selectedProducts.push({ ...product, selectedVariant: { ...variant, inventory } });
            }
        });
        dispatch(setSelectedProducts(selectedProducts));

        const payload = {
            params: {
                shop_id: shop.id,
                location_id: booking.location.id,
            },
            data: {
                booking_id: booking.id,
            },
        };
        await dispatch(getAllShopProducts(payload));
        const finalTotal = await calculateBookingTotal(selectedServices, selectedProducts, booking?.location?.sales_tax);
        dispatch(setBookingTotal(finalTotal));
        navigate(PATH.APPOINTEMENT);
    };

    const handleCancelAppointment = () => {
        setIsLoading(true);
        const payload = {
            status: "request_canceled",
        };
        const params = {
            shop_id: booking.shop?.id,
            id: booking.id,
        };
        axiosPatch(API.BOOKING.UPDATE_STATUS, payload, params)
            .then((response) => {
                toast.success(response.data.message);
                setIsRefresh(true);
                handleClose();
            })
            .catch((error: any) => {
                toast.error(error?.message);
            })
            .finally(() => {
                setIsLoading(false);
                setIsShowCancelAppointmentConfirmationModal(false);
            });
    };

    const handleModalClose = (status: boolean) => () => {
        setIsShowCancelAppointmentConfirmationModal(status);
    };

    return (
        <div className=" w-full flex flex-col relative  font-primary overflow-hidden h-full">
            <div className="flex flex-row justify-between items-center border-b min-h-[60px] h-[60px] px-4 lg:px-5 border-borderColorPrimary ">
                <h3 className="text-[16px] flex-1 leading-[22.4px] font-semibold text-txtAppointmentColor -tracking-[0.01rem] capitalize">
                    {t("Appointment")} #{booking.receipt_code}
                </h3>
                <div>
                    <XClose className="cursor-pointer text-textGrey w-[22px] h-[22px]" onClick={handleClose} />
                </div>
            </div>
            <div className="w-[100%] h-full scrollbar-hide overflow-hidden flex flex-col">
                <div className="overflow-y-auto h-full pt-4 lg:pt-5 scrollbar-hide px-4 pb-[20px] lg:px-5">
                    {/* Order summary */}
                    <div className="flex flex-row">
                        <p className={`flex text-base font-semibold text-txtcolor -tracking-[0.02rem] flex-1`}>{t("Order summary")}</p>
                        <div>
                            {booking.status === "confirmed" && (
                                <div className="flex items-center justify-center rounded-[6px] border border-borderCounter pr-1.5 pl-1 h-[24px] shadow-[0px_1px_2px_0px_#1018280D]">
                                    <span className="flex items-center">
                                        <GoDotFill size={12} className={`w-3 h-3 mr-0.5 text-success`} />
                                        <span className="text-[12px] font-medium text-textCounterColor leading-[18px] capitalize">{t("Confirmed")}</span>
                                    </span>
                                </div>
                            )}
                            {booking.status === "pending" && (
                                <div className="flex items-center justify-center rounded-[6px] border border-borderCounter pr-1.5 pl-1 h-[24px] shadow-[0px_1px_2px_0px_#1018280D]">
                                    <span className="flex items-center">
                                        <GoDotFill size={12} className={`w-3 h-3 mr-0.5 text-success`} />
                                        <span className="text-[12px] font-medium text-textCounterColor leading-[18px] capitalize">{t("Pending")}</span>
                                    </span>
                                </div>
                            )}
                            {booking.status === "declined" && (
                                <div className="flex items-center justify-center rounded-[6px] border border-borderCounter pr-1.5 pl-1 h-[24px] shadow-[0px_1px_2px_0px_#1018280D]">
                                    <span className="flex items-center">
                                        <GoDotFill size={12} className={`w-3 h-3 mr-0.5 text-success`} />
                                        <span className="text-[12px] font-medium text-textCounterColor leading-[18px] capitalize">{t("Declined")}</span>
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>

                    {/* location */}
                    <div className="relative w-full flex-col gap-2 flex mt-3">
                        <span className="text-[14px] leading-[19.6px] font-medium text-textGrey -tracking-[0.01rem]">{t("Location")}</span>
                        <div className="flex flex-row items-center gap-3 pb-3 border-b border-borderColorSecondary border-dashed">
                            <img src={booking.location.location_image_url} className="rounded-[8px]  w-[24px] h-[24px] lg:w-[28px] lg:h-[28px] object-cover" alt="" onError={onError("Location")} />
                            <div className="flex flex-col">
                                <h1 className="text-txtAppointmentColor font-medium text-[14px] leading-[19.6px] -tracking-[0.01rem] mb-1"> {booking?.location?.name}</h1>

                                <p className="text-textGrey text-[12px] leading-[18px] font-normal -tracking-[0.01rem] ">
                                    {`${booking?.location?.street}, ${booking?.location?.city}, ${booking?.location?.country} ${booking?.location?.postal_code}`}
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* Specialist */}
                    <div className="relative w-full flex-col gap-2 flex mt-3">
                        <h1 className="text-[14px] leading-[19.6px] font-medium text-textGrey -tracking-[0.01rem]">{t("Specialist")}</h1>
                        <div className="flex flex-row items-center gap-3 pb-3 border-b border-borderColorSecondary border-dashed">
                            <img src={booking.staff.profile_image_url} className="rounded-[8px]  w-[24px] h-[24px] lg:w-[28px] lg:h-[28px] object-cover" alt="" onError={onError("Professional")} />
                            <div className="flex flex-col">
                                <h2 className="text-txtAppointmentColor font-medium text-[14px] leading-[19.6px] -tracking-[0.01rem] mb-1">{booking?.staff?.full_name}</h2>
                                <p className="text-textGrey text-[12px] leading-[18px] font-normal -tracking-[0.01rem]">{booking.staff?.staff_role?.name}</p>
                            </div>
                        </div>
                    </div>

                    {/* Date And Time */}
                    <div className="flex flex-col gap-2 lg:px-0 mt-3 border-b border-borderColorSecondary border-dashed pb-3">
                        <h2 className="text-[14px] leading-[19.6px] font-medium text-textGrey  -tracking-[0.01rem]">{t("Date and time")}</h2>
                        <div className="flex gap-2 items-center">
                            <div className="bg-bgContent w-[25px] h-[25px] justify-center items-center rounded-primary text-[14px] text-textGrey hidden lg:flex">
                                <Calendar className="w-[18px] h-[18px]" />
                            </div>
                            <p className="text-txtAppointmentColor text-sm leading-[18.2px] font-medium -tracking-[0.01rem]">
                                {moment(booking.booking_date).format("dddd, DD MMM YYYY • ")}
                                {format(new Date(`${booking.booking_date} ${booking.booking_start_time}`), "hh:mm-")}
                                {format(new Date(`${booking.booking_date} ${booking.booking_end_time}`), "hh:mm a")}
                            </p>
                        </div>
                    </div>

                    {/* Service  */}
                    <div className="flex flex-col gap-2 mt-3 border-b border-borderColorSecondary border-dashed pb-3">
                        <h2 className="text-[14px] leading-[19.6px] font-medium text-textGrey  -tracking-[0.01rem]">{t("Service")}</h2>
                        <div className="flex flex-col gap-3">
                            {booking.booking_services.map((bookingService, index) => (
                                <div className="flex justify-between items-center" key={bookingService.service.name}>
                                    <div className="flex gap-2 items-center">
                                        <div className="bg-bgContent w-[25px] h-[25px] lg:flex justify-center items-center rounded-primary text-sm text-txtAppointmentColor hidden ">
                                            <div className="">{bookingService.quantity}</div>
                                        </div>
                                        <p className="text-txtAppointmentColor text-sm leading-[18.2px] font-medium -tracking-[0.01rem]">{bookingService.service.name}</p>
                                    </div>
                                    <p className="text-txtcolor text-sm leading-[18.2px] font-medium   w-1/3 text-right -tracking-[0.01rem]">${bookingService.price}</p>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Products */}
                    {booking.booking_products.length > 0 && (
                        <div className="flex flex-col gap-2 lg:px-0 mt-3 border-b border-borderColorSecondary border-dashed pb-3">
                            <h2 className="text-[14px] leading-[19.6px] font-medium text-textGrey -tracking-[0.01rem">{t("Product")}</h2>
                            <div className="flex gap-3 flex-col">
                                {booking.booking_products.map((product, index) => (
                                    <div className="flex justify-between items-center" key={`${product.product.name}-${index}`}>
                                        <div className="flex gap-2 items-center">
                                            <div className="bg-bgContent w-[25px] h-[25px] justify-center items-center rounded-primary text-sm text-txtAppointmentColor hidden lg:flex">
                                                <div className="">{product.quantity}</div>
                                            </div>
                                            <p className="text-txtAppointmentColor text-[14px] leading-[18.2px] font-medium max-w-[100px] lg:max-w-[148px] -tracking-[0.01rem]">
                                                {product.product.name}
                                            </p>
                                            <span className="w-max ml-[4px] rounded-[4px] text-xs font-normal border px-[6px] py-[2px] items-center justify-center flex text-txtAppointmentColor border-borderColorSecondary tracking-[-0.02rem]">
                                                <span> {product.product_variant.size} </span>
                                                <span> {product.product.unit_of_measure}</span>
                                            </span>
                                        </div>
                                        <p className="text-txtcolor text-sm leading-[18.2px] font-medium text-right -tracking-[0.01rem]">${product.product_inventory.price}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    <BookedBasketCalculation staticInfo={booking} />
                </div>
                <div className="mt-auto w-full flex flex-col shadow-[0px_-4px_10px_0px_#00000014]">
                    {(booking.status === "confirmed" || booking.status === "pending") && (
                        <div className="flex flex-row gap-4 w-full px-4 lg:px-5 py-4">
                            <CustomButton secondary className={`flex justify-center items-center w-full`} onClick={() => setIsShowCancelAppointmentConfirmationModal(true)}>
                                {t("Cancel")}
                            </CustomButton>
                            <CustomButton primary className={`flex justify-center items-center w-full`} onClick={handleModifyAppointment}>
                                {"Modify"}
                            </CustomButton>
                        </div>
                    )}
                    <div className="flex flex-row px-4 items-center lg:px-5 py-4 bg-mainBackgroundColor gap-4">
                        <span className="text-textGrey leading-[16.8px] text-[12px] lg:text-[14px] lg:leading-[18.2px] font-medium uppercase">{t("Follow us on:")}</span>
                        <div className="gap-3 flex flex-row items-center">
                            <button className={`h-[36px] w-[36px] min-w-[36px] border-borderCounter border rounded-secondary flex justify-center items-center shadow-[0px_1px_2px_0px_#1018280D]`}>
                                <GoogleSVG className="w-5 h-5" />
                            </button>
                            <button className={`h-[36px] w-[36px] min-w-[36px] border-borderCounter border rounded-secondary flex justify-center items-center shadow-[0px_1px_2px_0px_#1018280D]`}>
                                <Facebook1SVG className="w-5 h-5" />
                            </button>
                            <button className={`h-[36px] w-[36px] min-w-[36px] border-borderCounter border rounded-secondary flex justify-center items-center shadow-[0px_1px_2px_0px_#1018280D]`}>
                                <img src={Instagram1SVG} className="w-5 h-5" alt="" />
                            </button>
                        </div>
                    </div>
                    {isShowCancelAppointmentConfirmationModal && (
                        <DeletePopupModal
                            primary
                            headerIcon={<SlashCircle01 className="w-6 h-6 text-gray-700" />}
                            confirmButtonText="Confirm"
                            onClose={handleModalClose}
                            size="w-[400px]"
                            title={t("Cancel appointment")}
                            description={t("Do you want to cancel this appointment?")}
                            isLoading={isLoading}
                            onConfirm={handleCancelAppointment}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default BookingView;
