import React from "react";
import { useLocalizedSetting } from "src/hooks/useLocalizedSetting";

type Props = {
    service: any;
    isCollapsed: any;
    setIsCollapsed: any;
    index: number;
};
const Info: React.FC<Props> = ({ service, setIsCollapsed, isCollapsed, index }) => {
    const { getLocalizedSetting } = useLocalizedSetting();
    const name = getLocalizedSetting(service, "name");
    const description = getLocalizedSetting(service, "description");
    return (
        <div className="border-b flex flex-col text-TextColor relative border-b-animation border-borderColorPrimary first:border-t pb-6 pt-6">
            <div
                className="w-full cursor-pointer flex justify-between"
                // onClick={() => setIsCollapsed({ isShow: isCollapsed.id === index ? !isCollapsed.isShow : true, id: index })}
            >
                <div className="flex w-full">
                    <h3 className="text-[16px] leading-[19.36px] font-normal uppercase text-TextColor">{name}</h3>
                </div>
                <div className="flex w-full justify-end">
                    <h3 className="text-[16px] leading-[19.36px] font-normal uppercase text-TextColor">{`$${Number(service.price).toFixed(2)} CAD`}</h3>
                    {/* {service?.is_description && (
                    <div className="text-right">
                        <button
                            // onClick={() => setIsCollapsed({ isShow: isCollapsed.id === index ? !isCollapsed.isShow : true, id: index })}
                            className="text-right text-TextColor leading-[1] pt-2.5 text-[14px] lg:text-[1vw] flex justify-end font-normal min-w-36"
                        >
                            {isCollapsed.isShow && isCollapsed.id === index ? "LESS −" : "MORE +"}
                        </button>
                    </div>
                )} */}
                </div>
            </div>
            {/* {isCollapsed.isShow && isCollapsed.id === index && service?.is_description && ( */}
            <div className="flex flex-row pt-4">
                <p className="font-light text-[16px] leading-[22px] text-TextColor">{description}</p>
            </div>
            {/* )} */}
        </div>
        // service.price.description
        // <div className="border-b flex flex-col md:flex-row  text-TextColor">
        //     <div className="flex w-full md:w-[65%] 2xl:w-[70%]">
        //         <p className="text-base font-normal flex py-5 text-white align-top w-[70%] ">{name}</p>
        //         <p className="text-base font-normal flex py-5 text-white justify-end md:justify-start align-top w-[50%] md:w-[30%] ">${price} USD</p>
        //     </div>
        //     <div className="flex w-full md:w-[35%] 2xl:w-[30%]">
        //         <p className="text-base font-normal pt-0 pb-5 md:py-5 text-white align-top flex-1 w-full md:w-[30%] ">{description}</p>
        //     </div>
        // </div>
    );
};

export default Info;
