import { useMemo } from "react";

type BreakpointCondition = {
    breakpoint: string;
    minLength: number;
};

export const useEnableSliderButtons = (currentBreakpoint: string, dataLength: number, conditions: BreakpointCondition[], isMobileCheckEnabled: boolean): boolean =>
    useMemo(() => {
        const breakpointNum = parseInt(currentBreakpoint);

        if (isMobileCheckEnabled && (breakpointNum < 768 || currentBreakpoint === "max") && dataLength > 1) {
            return true;
        }

        return conditions.some((condition) => breakpointNum === parseInt(condition.breakpoint) && dataLength > condition.minLength);
    }, [currentBreakpoint, dataLength, conditions, isMobileCheckEnabled]);
