import React, { FC, useEffect } from "react";
import { ArrowLeft, Bell03, CreditCard02, DotsVertical, FileLock02, LogOut01, User01 } from "@untitled-ui/icons-react/build/cjs";
import { Dropdown } from "flowbite-react";
import { axiosGet } from "src/utils/requestClient";
import { API } from "src/constants/api";
import { toast } from "react-toastify";
import { currentUser } from "src/redux/reducers/common/Common.slice";
import { useAppSelector } from "src/redux/hooks";
import { onMessageListener } from "src/services/API/firebase";
import { GoDotFill } from "react-icons/go";
import { useTranslation } from "react-i18next";

interface IHeader {
    handleAction: (type: string) => () => void;
    handleClose: () => void;
    isAnyUnreadMsg: boolean;
    setTotalRecords: React.Dispatch<
        React.SetStateAction<{
            totalRecords: number;
            unread_count: number;
        }>
    >;
}

const Header: FC<IHeader> = ({ handleAction, handleClose, isAnyUnreadMsg, setTotalRecords }) => {
    const { t } = useTranslation();
    const user = useAppSelector(currentUser);

    useEffect(() => {
        if (user) {
            getNotificationCount();

            onMessageListener()
                .then((payload: any) => {
                    getNotificationCount();
                    toast.success(payload?.notification?.body);
                })
                .catch((err) => err);
        }
    }, [user]);

    const getNotificationCount = () => {
        axiosGet(API.NOTIFICATION.LIST, {}).then((response) => {
            setTotalRecords({ totalRecords: response.data.data.totalRecords, unread_count: response.data.data.unread_count });
        });
    };

    return (
        <div className="flex justify-between items-center border-b h-[52px] lg:h-[60px] lg:min-h-[60px] px-4 lg:px-5 border-borderColorPrimary">
            <div className="flex items-center flex-1">
                <ArrowLeft className="w-[20px] h-[20px] cursor-pointer text-txtAppointmentColor" onClick={handleClose} />
            </div>
            <div className="flex space-x-[12px] ">
                <div className="flex items-center justify-center relative">
                    <button type="button" className=" flex items-center justify-center" onClick={handleAction("notification")}>
                        <Bell03 className="w-[20px] h-[20px] text-textGrey" />
                    </button>
                    {isAnyUnreadMsg && (
                        <div className="absolute top-[-4px] right-[-4px]">
                            <GoDotFill size={9} color="#17B26A" className="rounded-full" />
                        </div>
                    )}
                </div>
                <Dropdown
                    className="z-10 bg-dropdownBackgroundColor border border-borderColorPrimary rounded-secondary flex items-center justify-center"
                    theme={{
                        arrowIcon: "h-4 w-4",
                        content: "py-2 px-2 focus:outline-none",
                        // auto: "border border-gray-200 bg-white text-gray-900 dark:border-none dark:bg-gray-700 dark:text-white",
                    }}
                    label=""
                    placement="bottom-start"
                    dismissOnClick={true}
                    renderTrigger={() => (
                        <button type="button" className=" flex items-center justify-center">
                            <DotsVertical className="w-[20px] h-[20px] text-textGrey" />
                        </button>
                    )}
                >
                    <Dropdown.Item
                        icon={User01}
                        onClick={handleAction("account_setting")}
                        className="dropdown-item-wrapper"
                        theme={{
                            container: "right-0",
                            icon: "item-icon-svg mr-3 h-[18px] w-[18px] ",
                            base: " flex items-center item-text justify-start py-2 pl-4 pr-4 text-sm text-txtcolor  font-normal text-[14px] cursor-pointer w-full hover:bg-mainBackgroundColor hover:rounded-[8px] focus:bg-mainBackgroundColor  focus:outline-none focus:ring-0",
                        }}
                    >
                        {t("Account Settings")}
                    </Dropdown.Item>
                    <Dropdown.Item
                        icon={FileLock02}
                        onClick={handleAction("appointment_history")}
                        className="dropdown-item-wrapper"
                        theme={{
                            container: "right-0",
                            icon: "item-icon-svg mr-3 h-[18px] w-[18px]",
                            base: "  flex items-center item-text justify-start py-2 pl-4 pr-4 text-sm text-txtcolor  font-normal text-[14px] cursor-pointer w-full hover:bg-mainBackgroundColor hover:rounded-[8px] focus:bg-mainBackgroundColor  focus:outline-none focus:ring-0",
                        }}
                    >
                        {t("Appointment History")}
                    </Dropdown.Item>

                    <Dropdown.Divider className="my-1 h-px bg-borderColorPrimary -mx-2.5" />

                    <Dropdown.Item
                        icon={Bell03}
                        onClick={handleAction("notification_setting")}
                        className="dropdown-item-wrapper"
                        theme={{
                            container: "right-0",
                            icon: "item-icon-svg mr-3 h-[18px] w-[18px]",
                            base: " flex items-center item-text justify-start py-2 pl-4 pr-4 text-sm text-txtcolor  font-normal text-[14px] cursor-pointer w-full hover:bg-mainBackgroundColor hover:rounded-[8px] focus:bg-mainBackgroundColor  focus:outline-none focus:ring-0",
                        }}
                    >
                        {t("Notification preferences")}
                    </Dropdown.Item>
                    <Dropdown.Item
                        icon={CreditCard02}
                        onClick={handleAction("payment_method")}
                        className="dropdown-item-wrapper"
                        theme={{
                            container: "right-0",
                            icon: "item-icon-svg mr-3 h-[18px] w-[18px]",
                            base: " flex items-center item-text justify-start py-2 pl-4 pr-4 text-sm text-txtcolor  font-normal text-[14px] cursor-pointer w-full hover:bg-mainBackgroundColor hover:rounded-[8px] focus:bg-mainBackgroundColor  focus:outline-none focus:ring-0",
                        }}
                    >
                        {t("Payment methods")}
                    </Dropdown.Item>
                    {/* <Dropdown.Item
                icon={Key01}
                theme={{
                    container: "right-0",
                    icon: "item-icon-svg mr-3 h-[18px] w-[18px]",
                    base: "flex items-center item-text justify-start py-2 pl-4 pr-4 text-sm text-txtcolor  font-normal text-[14px] cursor-pointer w-full hover:bg-mainBackgroundColor hover:rounded-[8px] focus:bg-mainBackgroundColor  focus:outline-none focus:ring-0",
                }}
            >
                Change Password
            </Dropdown.Item>
            <Dropdown.Item
                icon={Globe01}
                theme={{
                    container: "right-0",
                    icon: "item-icon-svg  mr-3 h-[18px] w-[18px]",
                    base: "flex items-center item-text justify-start py-2 pl-4 pr-4 text-sm text-txtcolor  font-normal text-[14px] cursor-pointer w-full hover:bg-mainBackgroundColor hover:rounded-[8px] focus:bg-mainBackgroundColor  focus:outline-none focus:ring-0",
                }}
            >
                Language & Region
            </Dropdown.Item> */}
                    <Dropdown.Divider className="my-1 h-px bg-borderColorPrimary -mx-2.5" />
                    <Dropdown.Item
                        icon={LogOut01}
                        className="text-danger"
                        onClick={handleAction("logout")}
                        theme={{
                            container: "right-0",
                            icon: "item-icon-svg text-danger mr-3 h-[18px] w-[18px]",
                            base: " flex items-center item-text justify-start py-2 pl-4 pr-4 text-sm text-txtcolor  font-normal text-[14px] cursor-pointer w-full hover:bg-mainBackgroundColor hover:rounded-[8px] focus:bg-mainBackgroundColor  focus:outline-none focus:ring-0",
                        }}
                    >
                        {t("Log out")}
                    </Dropdown.Item>
                </Dropdown>
            </div>
        </div>
    );
};

export default Header;
