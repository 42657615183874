import { useAppSelector } from "src/redux/hooks";
import { useLanguage } from "./useCommon";
import { globalLanguage } from "src/redux/reducers/common/Common.slice";

export const useLocalizedSetting = () => {
    const { languageOptions } = useLanguage();
    const currentLanguage = useAppSelector(globalLanguage);

    const getLocalizedSetting = (settings: any, keyBase: string) => {
        const key = `${currentLanguage}_${keyBase}`;
        if (settings?.[key]) {
            return settings[key];
        }

        for (let option of languageOptions as any[]) {
            if (option.value !== currentLanguage) {
                const fallbackKey = `${option.value}_${keyBase}`;
                if (settings[fallbackKey]) {
                    return settings[fallbackKey];
                }
            }
        }

        return settings?.[key] || undefined;
    };

    return { getLocalizedSetting };
};
