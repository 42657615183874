import React, { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import InputWithLabel from "src/components/InputWithLabel";
import { CardCvcElement, CardExpiryElement, CardNumberElement } from "@stripe/react-stripe-js";
import FloatingInputbox from "src/components/FloatingInputbox";
import { useAppSelector } from "src/redux/hooks";
import { currentUser } from "src/redux/reducers/common/Common.slice";

interface FieldState {
    cardnumber: boolean;
    date: boolean;
    cvv: boolean;
}

const MCardTabComponent = ({ setCardError, cardError }: any) => {
    const { t } = useTranslation();
    const { control } = useFormContext();
    const [isFocus, setIsFocus] = useState<FieldState>({ cardnumber: false, date: false, cvv: false });
    const [isLabel, setIsLabel] = useState<FieldState>({ cardnumber: true, date: true, cvv: true });
    const [isEmpty, setIsEmpty] = useState<FieldState>({ cardnumber: true, date: true, cvv: true });
    const user = useAppSelector(currentUser);

    const onFocus = (name: keyof FieldState) => () => {
        setIsFocus((prev: FieldState) => ({
            ...prev,
            [name]: true,
        }));
        setIsLabel((prev: FieldState) => ({
            ...prev,
            [name]: true,
        }));
    };

    const onBlur = (name: keyof FieldState) => () => {
        if (!isEmpty[name]) {
            setIsLabel((prev: FieldState) => ({
                ...prev,
                [name]: false,
            }));
        } else {
            setIsLabel((prev: FieldState) => ({
                ...prev,
                [name]: true,
            }));
        }
        setIsFocus((prev: FieldState) => ({
            ...prev,
            [name]: false,
        }));
    };
    return (
        <div className={`flex-1  ${user && user.total_loyalty_points > 0 ? "mt-5" : ""}`}>
            <Controller
                name="holdername"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <div className="w-full mb-5">
                        {/* <label htmlFor="cardnumber" className="text-txtAppointmentColor text-[14px] font-normal leading-[20px] -tracking-[0.03rem] mb-1.5 block">
                            Name on Card
                        </label> */}
                        <FloatingInputbox label={t("Cardholder Name")} handleChange={(e) => onChange(e.target.value)} value={value} name="holdername" inputClass={`${error && "is-error"}`} />
                        {error && <p className="text-error">{error && error.message}</p>}
                    </div>
                )}
            />

            <div className="flex flex-col w-full tracking-[0.03rem]">
                <Controller
                    name="cardnumber"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <div className="w-full mb-5">
                            <div
                                className={`border-none bg-transparent rounded-secondary shadow-[0px_1px_2px_0px_#1018280D] w-full relative card-number-wrapper ${
                                    isFocus.cardnumber ? "card-number-wrapper-focus " : ""
                                }`}
                            >
                                <label htmlFor="cardnumber" className={`card-number-label ${isFocus.cardnumber ? "card-number-label-focus !absolute !top-1.5 !left-3.5 !text-[11px] " : ""}`}>
                                    {isLabel.cardnumber ? t("Card number") : ""}
                                </label>
                                <CardNumberElement
                                    id="number-123"
                                    className={`card-number-input ${isFocus.cardnumber ? "card-number-input-focus !pt-5" : ""} ${(error || cardError.cardNumber) && "is-invalid"}`}
                                    options={{
                                        style: {
                                            base: {
                                                color: document.documentElement.style.getPropertyValue("--main-text-color"),
                                            },
                                        },
                                        placeholder: "",
                                    }}
                                    onChange={(e) => {
                                        setIsEmpty((prev: any) => ({
                                            ...prev,
                                            cardnumber: e.empty,
                                        }));
                                        onChange(e.error ? "" : e.complete);
                                        setCardError((prev: any) => ({
                                            ...prev,
                                            cardNumber: e.complete ? "" : e.error?.message,
                                        }));
                                    }}
                                    onFocus={onFocus("cardnumber")}
                                    onBlur={onBlur("cardnumber")}
                                />
                                {/* <CardNumberElement
                            options={{
                                style: {
                                    base: {
                                        lineHeight: "35px",
                                        color: cardNumberColor,
                                        textAlign: "left",
                                    },
                                },
                            }}
                            id="number-123"
                            className={`ba_form_control form_control_payment ${(errors.cardnumber || cardError.cardNumber) && "is-invalid border-solid text-secondaryTxt"}`}
                            onChange={(e) => {
                                onChange(e.error ? "" : e.complete);
                                setCardError((prev) => ({
                                    ...prev,
                                    cardNumber: e.complete ? "" : e.error?.message,
                                }));
                            }}
                        /> */}
                            </div>
                            {(error || cardError.cardNumber) && <p className="text-error">{(error || cardError.cardNumber) && (error?.message || cardError.cardNumber)}</p>}
                        </div>
                    )}
                />
            </div>

            <div className="grid grid-cols-2 gap-4 w-full">
                <Controller
                    name="date"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <div className="w-full">
                            <div
                                className={`border-none bg-transparent rounded-secondary shadow-[0px_1px_2px_0px_#1018280D] w-full relative card-number-wrapper ${
                                    isFocus.cardnumber ? "card-number-wrapper-focus " : ""
                                }`}
                            >
                                <label htmlFor="date" className={`card-number-label ${isFocus.date ? "card-number-label-focus !absolute !top-1.5 !left-3.5 !text-[11px]" : ""}`}>
                                    {isLabel.date ? t("Date") : ""}
                                </label>

                                {/* <CardExpiryElement
                                    options={{
                                        style: {
                                            base: {
                                                lineHeight: "35px",
                                            },
                                        },
                                    }}
                                    className={`ba_form_control form_control_payment ${(errors.date || cardError.cardExpiry) && "is-invalid border-solid"}`}
                                    onChange={(e) => {
                                        onChange(e.error ? "" : e.complete);
                                        setCardError((prev) => ({
                                            ...prev,
                                            cardExpiry: e.complete ? "" : e.error?.message,
                                        }));
                                    }}
                                /> */}
                                <CardExpiryElement
                                    className={`card-number-input ${isFocus.date ? "card-number-input-focus !pt-5" : ""} ${(error || cardError.cardExpiry) && "is-invalid"}`}
                                    options={{
                                        style: {
                                            base: {
                                                color: document.documentElement.style.getPropertyValue("--main-text-color"),
                                            },
                                        },
                                        placeholder: "",
                                    }}
                                    onChange={(e) => {
                                        setIsEmpty((prev: any) => ({
                                            ...prev,
                                            date: e.empty,
                                        }));
                                        onChange(e.error ? "" : e.complete);
                                        setCardError((prev: any) => ({
                                            ...prev,
                                            cardExpiry: e.complete ? "" : e.error?.message,
                                        }));
                                    }}
                                    onBlur={onBlur("date")}
                                    onFocus={onFocus("date")}
                                />
                            </div>
                            {(error || cardError.cardExpiry) && <p className="text-error">{(error || cardError.cardExpiry) && (error?.message || cardError.cardExpiry)}</p>}
                        </div>
                    )}
                />
                <Controller
                    name="cvv"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <div className="w-full mb-5">
                            <div
                                className={`border-none bg-transparent rounded-secondary shadow-[0px_1px_2px_0px_#1018280D] w-full relative card-number-wrapper ${
                                    isFocus.cvv ? "card-number-wrapper-focus" : ""
                                }`}
                            >
                                <label htmlFor="cvv" className={`card-number-label ${isFocus.cvv ? "card-number-label-focus !absolute !top-1.5 !left-3.5 !text-[11px]" : ""}`}>
                                    {isLabel.cvv ? "CVC" : ""}
                                </label>
                                {/* <CardCvcElement
                                    options={{
                                        style: {
                                            base: {
                                                lineHeight: "35px",
                                            },
                                        },
                                    }}
                                    className={`ba_form_control form_control_payment ${(errors.cvv || cardError.cardCvc) && "is-invalid border-solid"}`}
                                    onChange={(e) => {
                                        onChange(e.error ? "" : e.complete);
                                        setCardError((prev) => ({
                                            ...prev,
                                            cardCvc: e.complete ? "" : e.error?.message,
                                        }));
                                    }}
                                /> */}
                                <CardCvcElement
                                    className={`card-number-input ${isFocus.cvv ? "card-number-input-focus !pt-5" : ""}  ${(error || cardError.cardCvc) && "is-invalid"}`}
                                    options={{
                                        style: {
                                            base: {
                                                color: document.documentElement.style.getPropertyValue("--main-text-color"),
                                            },
                                        },
                                        placeholder: "",
                                    }}
                                    onChange={(e) => {
                                        setIsEmpty((prev: any) => ({
                                            ...prev,
                                            cvv: e.empty,
                                        }));
                                        onChange(e.error ? "" : e.complete);
                                        setCardError((prev: any) => ({
                                            ...prev,
                                            cardCvc: e.complete ? "" : e.error?.message,
                                        }));
                                    }}
                                    onBlur={onBlur("cvv")}
                                    onFocus={onFocus("cvv")}
                                />
                            </div>
                            {(error || cardError.cardCvc) && <p className="text-error">{(error || cardError.cardCvc) && (error?.message || cardError.cardCvc)}</p>}
                        </div>
                    )}
                />
            </div>
        </div>
    );
};

export default MCardTabComponent;
