import React from "react";
import { useTranslation } from "react-i18next";
import { Step3PNG } from "src/theme/Images";
const Step3 = () => {
    const { t } = useTranslation();
    return (
        <>
            <div className="flex flex-col">
                <span className="text-[16px] leading-[22.4px] font-normal text-txtAppointmentColor tracking-[-0.01rem] mb-3 min-h-[44px] ">
                    {t("Scroll down and select “Add to Home Screen.”")} <span className="text-primary leading-[22.4px] text-[16px]">{t("“Add to Home Screen”")}</span>
                </span>
                <div className="phone-body-bg">
                    <img src={Step3PNG} className="w-full rounded-secondary h-[305px]" alt="" />
                </div>
            </div>
        </>
    );
};

export default Step3;
