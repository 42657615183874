import React from "react";
import { useLocalizedSetting } from "src/hooks/useLocalizedSetting";
import { onError } from "src/utils/global-functions";
interface Props {
    data: any;
    sectionIndex: number;
}
const About: React.FC<Props> = ({ data, sectionIndex }) => {
    const { getLocalizedSetting } = useLocalizedSetting();
    const title = getLocalizedSetting(data, "title");
    const subtitle = getLocalizedSetting(data, "subtitle");
    const description = getLocalizedSetting(data, "description");
    return (
        <div className="flex flex-col">
            <div className="w-full flex items-center mb-4">
                <div className="slice-label text-TextColor">
                    <span className="num">{sectionIndex}</span>
                    {data?.is_title && <span className="slice-text text-TextColor">{title}</span>}
                </div>
            </div>
            {data?.is_image && (
                <div className="h-[192.29px] xl:h-[375px] 2xl:h-[370px] 3xl:h-[436px]">
                    <img className="w-full object-cover h-full" src={data.image_url} alt="" onError={onError("Location")} />
                    {/* aspect-[1.78/1] */}
                </div>
            )}

            {(data?.is_subtitle || data?.is_description) && (
                <div className="grid grid-cols-1 lg:grid-cols-2 mt-8 gap-4 lg:gap-8">
                    {data?.is_subtitle && <div className="text-[32px] 2xl:text-[42px] leading-[38.4px] font-normal 2xl:leading-[50.4px] w-full -tracking-[0.01rem] text-TextColor">{subtitle}</div>}
                    {data?.is_description && <div className="text-[16px] leading-[24px] -tracking-[0.01rem] font-light text-TextColor">{description}</div>}
                </div>
            )}
        </div>
    );
};

export default About;
