import React, { FC, useState } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { IPasswordFiled, IPasswordVerificationProps } from "./Password.interface";
import { axiosPost } from "src/utils/requestClient";
import { API } from "src/constants/api";
import { LOCAL_STORAGE_KEYS, errorCode } from "src/constants/common";
import { IInitAction } from "../Auth.interface";
import { me } from "src/redux/reducers/common/Common.slice";
import { toast } from "react-toastify";
import WVerifyPassword from "./Web/WVerifyPassword";
import MVerifyPassword from "./Mobile/MVerifyPassword";

const VerifyPassword: FC<IPasswordVerificationProps> = ({ authData, resetAuthAction, onCloseModal, handleAuthAction }) => {
    const uiState = useAppSelector((uiData) => uiData.UiStates);
    const schema = Yup.object({
        password: Yup.string().required("This field is required"),
    }).required();
    const dispatch = useAppDispatch();
    const [loading, setIsLoading] = useState(false);
    // const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    // const [isLoading, setIsLoading] = useState(false);

    const methods = useForm<IPasswordFiled>({
        resolver: yupResolver(schema),
        defaultValues: {
            password: "",
        },
    });
    const { handleSubmit, setError } = methods;

    const handleSave = async (data: IPasswordFiled) => {
        setIsLoading(true);
        let payload = {};
        if (authData.isEmail) {
            payload = {
                password: data.password,
                email: authData.email,
                otp: authData.otp,
            };
        } else {
            const mainNumber = `+${authData.phone.code}${authData.phone.number}`;
            payload = {
                password: data.password,
                phone: mainNumber,
                phone_country_code: authData.phone.country,
                otp: authData.otp,
            };
        }
        axiosPost(API.USER.VERIFY_PASSWORD, payload)
            .then(async (response) => {
                localStorage.setItem(LOCAL_STORAGE_KEYS.authToken, response.data.data.token);
                await dispatch(me());
                onCloseModal();
            })
            .catch((error) => {
                const response = error.response.data;
                if (response.status === errorCode.unprocessable) {
                    if (response.data) {
                        Object.keys(response.data).forEach((field) => {
                            setError(field as keyof IPasswordFiled, {
                                type: "manual",
                                message: response.data[field][0],
                            });
                        });
                        return;
                    }
                }
                toast.error(response.message);
            })
            .finally(() => setIsLoading(false));
    };

    const handleAction = (type: keyof IInitAction) => () => {
        resetAuthAction();
        handleAuthAction(type);
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(handleSave)}>
                {uiState.isTablet ? (
                    <WVerifyPassword loading={loading} authData={authData} handleAction={handleAction} resetAuthAction={resetAuthAction} />
                ) : (
                    <MVerifyPassword authData={authData} handleAction={handleAction} loading={loading} />
                )}
            </form>
        </FormProvider>
    );
};

export default VerifyPassword;
