import React, { useCallback, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper/types";
import "swiper/css";
import "swiper/css/pagination";
import { ArrowLeft, ArrowRight } from "@untitled-ui/icons-react/build/cjs";
import { onError } from "src/utils/global-functions";
import { useTranslation } from "react-i18next";
// Image path
interface Props {
    data: any;
}
const Gallery: React.FC<Props> = ({ data }) => {
    const { t } = useTranslation();
    const [swiperRef, setSwiperRef] = useState<SwiperType>();
    const handlePrevious = useCallback(() => {
        swiperRef?.slidePrev();
    }, [swiperRef]);

    const handleNext = useCallback(() => {
        swiperRef?.slideNext();
    }, [swiperRef]);

    return (
        <div className={`relative h-[50vh] sm:h-[60vh] md:h-[65vh] lg:h-screen w-full bg-gray-800 bg-opacity-50`}>
            <Swiper
                slidesPerView={1}
                spaceBetween={0}
                pagination={{
                    clickable: true,
                    dynamicBullets: true,
                }}
                loop={true}
                onSwiper={setSwiperRef}
                navigation={false} // Disable Swiper default navigation
                className="mySwiper w-full h-[50vh] sm:h-[60vh] md:h-[65vh] lg:h-screen"
                // Apply dynamic height here
            >
                {data?.urls?.map((img: any, index: React.Key | null | undefined) => (
                    <SwiperSlide key={index}>
                        <>
                            <div className="relative w-full h-[50vh] sm:h-[60vh] md:h-[65vh] lg:h-screen before:absolute before:left-0 before:right-0 before:top-0 before:z-10 before:h-full before:w-full before:bg-black before:opacity-35">
                                <img src={img} alt="" className="w-full h-[50vh] sm:h-[60vh] md:h-[65vh] lg:h-screen object-cover " onError={onError("Location")} />
                            </div>
                        </>
                    </SwiperSlide>
                ))}
                <div className="absolute left-5 xl:left-[100px] bottom-[56px] z-50 text-TextColor">
                    <h2 className="text-7xl md:text-8xl xl:text-[120px] xl:leading-[132px] text-white font-normal">{t("Gallery")}</h2>
                    <p className="font-normal text-2xl xl:text-[32px] xl:leading-[48px] text-white">{t("From our studio")}</p>
                    {data?.urls.length > 1 && (
                        <div className="flex mt-4 relative">
                            <button className="rounded-full flex justify-center items-center cursor-pointer h-[40px] min-w-[40px] w-[40px] mr-2.5 bg-templateBg" onClick={handlePrevious}>
                                <ArrowLeft className="w-[20px] h-[20px] text-TextColor" />
                            </button>
                            <button className="rounded-full flex justify-center items-center cursor-pointer bg-templateBg h-[40px] min-w-[40px] w-[40px]" onClick={handleNext}>
                                <ArrowRight className="w-[20px] h-[20px] text-TextColor" />
                            </button>
                        </div>
                    )}
                </div>
            </Swiper>
        </div>
    );
};

export default Gallery;
