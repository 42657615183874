import React, { FC } from "react";
import { FaInstagram } from "react-icons/fa6";
import { formatInstagramLink, onError } from "src/utils/global-functions";
import { IInfo } from "./Professional.interface";

const Info: FC<IInfo> = ({ data }) => (
    <div className="flex flex-col bg-templateCardBg">
        <p className="font-medium text-[18px] leading-[27px] tracking-[-0.01rem] uppercase p-3 text-TextColor">{data.name}</p>
        <img src={data.image_url} alt="" className="aspect-[1/1] object-cover" onError={onError("Professional")} />
        <div className="w-[-webkit-fill-available] relative p-3 text-TextColor ">
            <h3 className="leading-[19.36px] text-[16px] font-normal text-TextColor">{data?.role}</h3>
            {data?.is_instgram_button && (
                <a
                    href={`${formatInstagramLink(data?.instgram_button)}`}
                    target="_blank"
                    className="text-[16px] mt-1 font-normal flex flex-row gap-1.5 items-center leading-[24px] tracking-[-0.01rem] text-TextColor"
                >
                    <FaInstagram size={20} className="cursor-pointer" color="text-templateCardBg mt-[1.5px]" /> @{data?.instgram_button ? data?.instgram_button : ""}
                </a>
            )}
        </div>
        {/* <p className="text-lg ">{title}</p>

        <img src={profileImageUrl} alt={name} className="mt-2 " />

        <h3 className="text-xl font-semithin">{name}</h3>
        {instagram_link && (
            <a href={`${formatInstagramLink(instagram_link)}`} target="_blank" className="text-xs border p-1">
                @{getInstagramUsernames(instagram_link)}
            </a>
        )} */}
    </div>
);

export default Info;
