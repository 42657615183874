import React from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "src/redux/hooks";
import { currentShop } from "src/redux/reducers/common/Common.slice";
import { Steplockicon, StepaA, Stepreload, StepSymbolleft, StepSymbolright, StepSymbolshare, StepSymbolcopy, Stepbook } from "src/theme/Images";
const Step1 = () => {
    const { t } = useTranslation();
    const shop = useAppSelector(currentShop);
    const slugValue = shop && shop.slug ? `www.${shop.slug}.getflair.ca` : "www.studios.getflair.ca";
    const websiteUrl = shop && shop.slug ? `${shop.slug}.getflair.ca` : "flairapp.com";
    return (
        <div className="flex flex-col">
            <span className="text-[16px] leading-[22.4px] font-normal text-txtAppointmentColor tracking-[-0.01rem] mb-3 min-h-[44px]">
                {t("Open")} <span className="text-primary leading-[22.4px] text-[16px]">{slugValue}</span> {t("on your browser")}
            </span>
            <div className="phone-body-bg">
                <div className="phone-body">
                    <div className="frame-1171278336">
                        <div className="url-bar-mini">
                            <div className="searchbar">
                                <div className="search-bottom">
                                    <img src={StepaA} className="a-a" alt="" />
                                    <div className="domain">
                                        <div className="div mr-1">
                                            <img src={Steplockicon} alt="" />
                                        </div>
                                        <div className="domain2">{websiteUrl}</div>
                                    </div>
                                    <img className="reload" src={Stepreload} alt="" />
                                </div>
                            </div>
                            <div className="toolbar">
                                <img className="symbol-left" src={StepSymbolleft} alt="" />
                                <img className="symbol-right" src={StepSymbolright} alt="" />
                                <img className="symbol-share" src={StepSymbolshare} alt="" />
                                <img className="symbol-book" src={Stepbook} alt="" />
                                <img className="symbol-copy" src={StepSymbolcopy} alt="" />
                            </div>
                            <div className="home-bar-mini">
                                <div className="home-indicator"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Step1;
