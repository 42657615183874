import React, { useEffect, useState } from "react";
import { IShopLocationCategory, ISpecialistService } from "../../../Interface";
import { toast } from "react-toastify";
import { API } from "../../../constants/api";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { axiosGet, axiosPost } from "../../../utils/requestClient";
import ServiceCardComponent from "./ServiceCardComponent";
import { getBookingInfo, setStep, setTempStore } from "../Booking.slice";
import { useTranslation } from "react-i18next";
import { EBookingTabState } from "../bookAppointment.interface";
import ManageSubmit from "../AppointmentPreview/ManageSubmit";
import { Collapse } from "src/components/Collapse/Collapse";
import { Dropdown } from "flowbite-react";
import { Check, FilterLines } from "@untitled-ui/icons-react/build/cjs";
import { useNavigate } from "react-router-dom";
import { Item } from "./SelectServices.interface";
import { PATH } from "src/constants/path";
import Stepper from "../Stepper";
import { useTrail, animated, useTransition } from "react-spring";
import { currentShop, globalLanguage } from "src/redux/reducers/common/Common.slice";
import { isGetApp } from "src/redux/reducers/Landing.slice";

interface ILoadingStates {
    category: false;
    filter: false;
}

const SelectServices = () => {
    const { t } = useTranslation();
    const currentLanguage = useAppSelector(globalLanguage);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const shop = useAppSelector(currentShop);
    const bookingState = useAppSelector(getBookingInfo);
    const uiState = useAppSelector((data) => data.UiStates);
    const [isCategoryLoading, setIsCategoryLoading] = useState(true);
    const [filteredServices, setFilteredServices] = useState<ISpecialistService[]>([]);
    const [categoryLists, setCategoryLists] = useState<IShopLocationCategory[]>([]);
    const [categoryFilterValue, setCategoryFilterValue] = useState<any | null>(null);
    const [searchFilterValue, setSearchFilterValue] = useState<string>("");
    const [services, setServices] = useState<ISpecialistService[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const isGetAppVisible = useAppSelector(isGetApp);

    useEffect(() => {
        dispatch(setTempStore({ SelectionCount: bookingState.selectedServices.length > 0 ? 1 : 0 }));
    }, [bookingState.selectedServices]);

    const selectedTransitions = useTransition(
        bookingState?.tempStore?.selectedServices.map((service, index) => ({ ...service, index })),
        {
            key: (item: any) => item.index,
            from: { opacity: 0, transform: "translateY(-20px)" },
            enter: { opacity: 1, transform: "translateY(0)" },
            leave: { opacity: 0, transform: "translateY(-20px)" },
            config: {
                duration: 300,
                // Combines ease-in and ease-out. Starts slowly, speeds up, then slows down
                easing: (time: any) => time * (2 - time),
            },
        },
    );
    const serviceAnimation = useTrail(filteredServices.length, {
        from: { opacity: 0, transform: "translateY(20px) scale(0.95) rotateX(90deg)" },
        to: { opacity: 1, transform: "translateY(0px) scale(1) rotateX(0deg)" },
        config: { tension: 220, friction: 20 },
    });

    const getServices = async () => {
        setIsLoading(true);
        const payloadData = {
            staff_id: bookingState.selectedSpecialist?.id,
            booking_date: bookingState.selectedBookingTime ? bookingState.selectedBookingDate : undefined,
            booking_time: bookingState.selectedBookingTime ? `${bookingState.selectedBookingTime}:00` : undefined,
            booking_id: bookingState.isModifyingAppointment ? bookingState.modifyingAppointmentId : null,
        };
        const urlParamObj = {
            shop_id: shop.id,
            location_id: bookingState.selectedShopLocation?.id,
        };
        axiosPost(API.SERVICE.LIST, payloadData, urlParamObj)
            .then(async (response) => {
                setServices(response.data.data);
                return;
            })
            .catch((error) => {
                toast.error(error?.message ?? "Something went wrong, Please try again");
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const loadCategories = async () => {
        setIsCategoryLoading(true);
        const payloadObj = {
            shop_id: bookingState.selectedShopLocation?.shop_id,
            location_id: bookingState.selectedShopLocation?.id,
        };
        axiosGet(API.CATEGORY.LIST, {}, payloadObj)
            .then((response) => {
                const data = response.data.data;
                setCategoryLists(data);
            })
            .finally(() => setIsCategoryLoading(false));
    };

    useEffect(() => {
        const initialLoad = async () => {
            await dispatch(setTempStore({ selectedServices: bookingState.selectedServices }));
            await loadCategories();
        };
        dispatch(setStep(EBookingTabState.SELECT_SERVICE));
        getServices();
        initialLoad();
    }, [currentLanguage]);

    useEffect(() => {
        const filteredRecords = services.filter((serviceRecord: ISpecialistService) => {
            let searchMatched = false;
            let categoryMatched = false;

            if (searchFilterValue !== "") {
                if (serviceRecord.name.toLocaleLowerCase().includes(searchFilterValue.toLocaleLowerCase())) {
                    searchMatched = true;
                }
            } else {
                searchMatched = true;
            }

            if (categoryFilterValue && categoryFilterValue.value) {
                categoryMatched = serviceRecord.shop_category_id === categoryFilterValue.value;
            } else {
                categoryMatched = true;
            }
            return searchMatched && categoryMatched;
        });
        const groupByParent = groupByParentId(filteredRecords as unknown as Item[]);
        setFilteredServices(groupByParent as unknown as ISpecialistService[]);

        setFilteredServices(filteredRecords);
    }, [categoryFilterValue, searchFilterValue, services]);

    const groupByParentId = (data: Item[]): Item[] => {
        // Create a map to hold the grouped data
        const map: { [key: number]: Item } = {};

        // Loop through the data and initialize the map
        data.forEach((item) => {
            if (!map[item.id]) {
                map[item.id] = { ...item, children: [] };
            }
            if (item.parent_id) {
                if (!map[item.parent_id]) {
                    map[item.parent_id] = { children: [] } as unknown as Item;
                }
                map[item.parent_id].children!.push(map[item.id]);
            }
        });

        // Extract the grouped data into an array
        return Object.values(map).filter((item) => !item.parent_id);
    };

    const handleClose = async () => {
        navigate(`${PATH.APPOINTEMENT}`);
        await dispatch(setStep(EBookingTabState.TAB_MENU));
        await dispatch(setTempStore({ selectedServices: [] }));
    };

    const handleRemoveService = async (id: any) => {
        const removeFromTemp = bookingState?.tempStore?.selectedServices?.filter((_, idx) => idx !== id);
        await dispatch(setTempStore({ selectedServices: removeFromTemp }));
    };

    return (
        <div className="h-full relative flex flex-col overflow-hidden max-lg:pb-[48px]">
            <div className="flex flex-row w-full justify-between items-center px-4 lg:px-2 mb-4 shrink-0">
                <div className="title text-txtcolor font-bold text-2xl leading-[128%]  md:mt-0 w-full">
                    <h3 className="location-title-text ">{t("Select a service")}</h3>
                    <p className="location-sub-text">{uiState.isMobile ? t("Choose your preferred service.") : t("Please choose a service as per your needs.")}</p>
                </div>
                {!isCategoryLoading && (
                    <div className="flex relative">
                        <Dropdown
                            className="bg-dropdownBackgroundColor border-borderColorPrimary border-0"
                            theme={{
                                arrowIcon: "ml-2 h-4 w-4",
                                content:
                                    "py-1 focus:outline-none bg-dropdownBackgroundColor !right-0 fixed z-50 rounded-secondary shadow-InputAndButton px-2.5 py-2 space-y-1 border border-borderColorPrimary shadow-InputAndButton",
                            }}
                            label=""
                            placement="bottom-start"
                            dismissOnClick={true}
                            inline
                            renderTrigger={() => (
                                <button
                                    type="button"
                                    className="min-w-[36px] h-[36px] sm:min-w-[40px] sm:h-[40px] border border-borderColorSecondary lg:border-borderColorSecondary rounded-md flex items-center justify-center lg:mt-0 relative"
                                >
                                    <FilterLines color="#8C9CBB" className="" />
                                </button>
                            )}
                        >
                            {categoryLists.map((categoryList) => (
                                <Dropdown.Item
                                    theme={{
                                        container: "right-0",
                                        icon: "text-textGrey mr-3 h-4 w-4 bg-black ",
                                        base: `${
                                            categoryFilterValue?.value === categoryList.id && "bg-mainBackgroundColor rounded-[8px] text-txtcolor focus:outline-none"
                                        }  flex w-[250px] md:w-[210px]  right-0 -tracking-[0.02rem] cursor-pointer items-center justify-start px-2.5  py-2 text-sm font-normal text-txtcolor  active:text-txtcolor active:bg-mainBackgroundColor active:rounded-[8px] hover:rounded-[8px] focus:rounded-[8px] hover:bg-mainBackgroundColor focus:bg-mainBackgroundColor focus:outline-none focus:ring-0`,
                                    }}
                                    key={categoryList.id}
                                    onClick={() => {
                                        categoryFilterValue?.value === categoryList.id ? setCategoryFilterValue(null) : setCategoryFilterValue({ label: categoryList.name, value: categoryList.id });
                                    }}
                                >
                                    <div className="flex items-center justify-between gap-2 w-full">
                                        {categoryList.name}
                                        {categoryFilterValue?.value === categoryList.id && <Check className="text-primary h-4 w-4 mr-1.5" />}
                                    </div>
                                </Dropdown.Item>
                            ))}
                        </Dropdown>
                        {categoryFilterValue && <div className="w-[13px] h-[13px] bg-primary rounded-full absolute -top-1 -right-1 border-[3px]  border-contentBackground "></div>}
                    </div>
                )}
            </div>
            <div className={`px-4 lg:px-2 shrink-0 ${filteredServices.length <= 0 || isLoading ? "!mb-3" : "mb-[36px]"}`}>
                <Stepper />
            </div>
            {/* <div className="mt-2 md:mt-0 w-full md:w-[320px] md:block">
                    <InputWithLabelNo available specialists
                        buttonIcon={searchFilterValue ? <IoCloseCircle className="text-secondaryTxt text-xl" /> : <IoSearchOutline color="#8C9CBB" size={18} className="mr-1" />}
                        handlerIconClick={() => setSearchFilterValue("")}
                        type="text"
                        inputClass="pl-[41px]"
                        placeholder={t("Search for a service")}
                        handleChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchFilterValue(e.target.value)}
                        value={searchFilterValue}
                        name="firstname"
                        disabled={isShopLocationServicesLoading}
                    />
                </div> */}
            {/* <div className="flex flex-wrap pb-5"></div> */}

            {/* {uiState.isMobile ? (
                <div>
                    <div className="-mx-[10px] h-[calc(100vh-250px)] lg:h-[60vh] overflow-scroll scrollbar-hide py-5 pb-14 2xl:pb-24 content-baseline">
                        {bookingState?.tempStore?.selectedServices?.map((serviceData: ISpecialistService) => (
                            <ServiceCardMobileComponent key={`selected-${serviceData.id}`} serviceData={serviceData} />
                        ))}
                        {filteredServices.map((serviceData: ISpecialistService) => (
                            <ServiceCardMobileComponent key={serviceData.id} serviceData={serviceData} />
                        ))}
                        {filteredServices.length <= 0 && !isShopLocationServicesLoading && (
                            <div className=" justify-center flex w-full h-full items-center">
                                <h1 className="text-txtcolor font-bold  text-base ">{t("No available services")}</h1>
                            </div>
                        )}
                    </div>
                    <ManageSubmit />
                </div>
            ) : ( */}
            {isLoading ? (
                <div className="custom_loading_wrapper">
                    <div className="custom_loading"></div>
                </div>
            ) : (
                <div className={`flex -lg:mx-[10px] mt-[-6px] pt-[6px] max-lg:pb-[20px] overflow-y-auto h-full scrollbar-hide flex-wrap md:pb-3 content-baseline`}>
                    {filteredServices.length <= 0 ? (
                        <div className=" justify-center flex w-full h-full items-center px-2">
                            <h1 className="text-center text-textGrey text-base font-normal -tracking-[0.03rem]">{t("No available services")}</h1>
                        </div>
                    ) : (
                        <div className="w-full gap-4 flex flex-col px-4 lg:px-2">
                            {selectedTransitions((style, service) => (
                                <animated.div key={service.id} style={style}>
                                    <ServiceCardComponent serviceData={service} removeable={true} handleRemoveService={() => handleRemoveService(service.index)} isSelected />
                                </animated.div>
                            ))}
                            {bookingState?.tempStore?.selectedServices.length > 0 && (
                                <div className="font-semibold text-[14px] text-txtAppointmentColor pl-[3px] leading-[16.8px] -tracking-[0.03rem] my-1">
                                    {" "}
                                    {t("Would you like to add anything else?")}
                                </div>
                            )}
                            {serviceAnimation.map((style, index) => {
                                const serviceData = filteredServices[index];
                                return (
                                    <animated.div key={`${serviceData.id}-${index}`} style={style}>
                                        {serviceData?.children && serviceData?.children?.length > 0 ? (
                                            <Collapse
                                                title={serviceData?.children[0]?.parent?.name}
                                                description={`${serviceData?.children?.length} Options`}
                                                isCollapsed={false}
                                                classNames="w-full text-txtcolor"
                                            >
                                                <div className="border-t border-t-borderColorPrimary mt-4">
                                                    <div className="mt-4 gap-4 flex flex-col">
                                                        {serviceData.children.map((child: any) => (
                                                            <ServiceCardComponent key={child.id} serviceData={child} />
                                                        ))}
                                                    </div>
                                                </div>
                                            </Collapse>
                                        ) : (
                                            <ServiceCardComponent serviceData={serviceData} />
                                        )}
                                    </animated.div>
                                );
                            })}
                        </div>
                    )}
                    {uiState.isMobile && <ManageSubmit />}
                </div>
            )}
            {/* )} */}
        </div>
    );
};

export default SelectServices;
