import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomButton from "src/components/CustomButton";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import {
    getBookingInfo,
    setBookingInitialState,
    setBookingTotal,
    setIsShowNoShowPolicy,
    setIsShowOrderSummary,
    setIsValidPaymentType,
    setPreview,
    setSelectedBookingDate,
    setSelectedBookingTime,
    setSelectedServices,
    setSelectedShopLocation,
    setSelectedSpecialist,
    setStep,
    setSubmitTriggered,
    setTempStore,
} from "../Booking.slice";
import { BookingTABS, EBookingTabState } from "../bookAppointment.interface";
import { axiosPatch, axiosPost } from "src/utils/requestClient";
import { API } from "src/constants/api";
import { ISpecialistService } from "src/Interface";
import { toast } from "react-toastify";
import { setIsShowLoginModal } from "src/redux/reducers/Login.slice";
import { useNavigate } from "react-router-dom";
import { allShopLocations, allShopProducts, currentShop, currentUser, me } from "src/redux/reducers/common/Common.slice";
import { PATH } from "src/constants/path";
import { useShopNavigation } from "src/hooks/useShopNavigation";
import { calculateBookingTotal } from "src/utils/global-functions";
import { useBookingConditionsStatus } from "src/hooks/useBookingConditionsStatus";

interface IManageSubmit {
    isModal?: boolean;
}

const ManageSubmit: FC<IManageSubmit> = ({ isModal = false }) => {
    const { t } = useTranslation();
    const bookingInfo = useAppSelector(getBookingInfo);
    const productList = useAppSelector(allShopProducts);
    const user = useAppSelector(currentUser);
    const shop = useAppSelector(currentShop);
    const dispatch = useAppDispatch();
    const uiState = useAppSelector((data) => data.UiStates);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const { navigateBasedOnShopLocations, syncTempStoreOnClose } = useShopNavigation();
    const { validateConditions, hasSelectedRequiredFields } = useBookingConditionsStatus();
    const [isServiceBtnClick, setIsServiceBtnClick] = useState(false);
    const shopLocations: any[] = useAppSelector(allShopLocations);

    // isModal props only true when ShowOrderSummary and noshowpolicy modal
    // for specialist bookingTotal send in manageBookingProcess and for other set using useeffect
    // bookingTotal set -> when data in main then 1 otherwise default 0
    // checkForNavigateFurtherStep use for navigate finalize or product first check there is tempdata for particular step
    // isNoShowPolicyApplicable use before NoShowPolicy show check there is temp data
    // remove service and date time when specialist change

    useEffect(() => {
        if (bookingInfo.step !== EBookingTabState.SELECT_SERVICE) {
            setIsServiceBtnClick(false);
        }
    }, [bookingInfo.step]);

    useEffect(() => {
        if (validateConditions(bookingInfo.step === EBookingTabState.SELECT_SERVICE, !!bookingInfo.selectedSpecialist, bookingInfo.tempStore.selectedServices.length > 0)) {
            const finalTotal = calculateBookingTotal(bookingInfo.tempStore.selectedServices, bookingInfo.selectedProducts, bookingInfo.selectedShopLocation?.sales_tax);
            dispatch(setBookingTotal(finalTotal));
        } else if (bookingInfo.tempStore.selectedServices.length === 0 && isServiceBtnClick) {
            dispatch(setBookingTotal(0));
        }
    }, [bookingInfo.tempStore.selectedServices]);

    useEffect(() => {
        if (bookingInfo.selectedServices.length > 0) {
            const finalTotal = calculateBookingTotal(bookingInfo.selectedServices, bookingInfo.selectedProducts, bookingInfo.selectedShopLocation?.sales_tax);
            dispatch(setBookingTotal(finalTotal));
        } else {
            dispatch(setBookingTotal(0));
        }
    }, [bookingInfo.selectedServices]);

    useEffect(() => {
        const finalTotal = calculateBookingTotal(bookingInfo.selectedServices, bookingInfo.selectedProducts, bookingInfo.selectedShopLocation?.sales_tax);
        dispatch(setBookingTotal(finalTotal));
    }, [bookingInfo.selectedProducts]);

    const isNoShowPolicyApplicable = () => {
        const baseCondition = bookingInfo.tempStore.SelectionCount > 0 && hasSelectedRequiredFields;
        // Dynamically check for either selectedServices or selectedSpecialist based on the step
        switch (bookingInfo.step) {
            case EBookingTabState.SELECT_SERVICE:
                return baseCondition && bookingInfo.tempStore.selectedServices.length > 0;
            case EBookingTabState.SELECT_SPECIALIST:
                return baseCondition && bookingInfo.tempStore.selectedSpecialist;
            case EBookingTabState.SELECT_DATE_TIME:
                return baseCondition && bookingInfo.tempStore.selectedBookingDate && bookingInfo.tempStore.selectedBookingTime;
            case EBookingTabState.TAB_MENU:
                return bookingInfo.selectedSpecialist && bookingInfo.selectedServices.length > 0 && bookingInfo.selectedBookingDate && bookingInfo.selectedBookingTime;
            case EBookingTabState.PRODUCT_BOOKING:
                return true;
            case EBookingTabState.FINALIZE_BOOKING:
                return true;
            default:
                return false;
        }
    };

    const checkForNavigateFurtherStep = (isTempData?: null | number) => {
        switch (bookingInfo.step) {
            case EBookingTabState.SELECT_SERVICE:
                return bookingInfo.tempStore.selectedServices.length > 0;
            case EBookingTabState.SELECT_SPECIALIST:
                return !!isTempData;
            case EBookingTabState.SELECT_DATE_TIME:
                return bookingInfo.tempStore.selectedBookingDate && bookingInfo.tempStore.selectedBookingTime;
            default:
                return false;
        }
    };

    const getButtonLabel = () => {
        if (uiState.isMobile && bookingInfo.isShowOrderSummary) {
            return t("Complete");
        }
        if (isModal) {
            return t("I agree");
        }

        const hasSelectedAllRequiredFields = [
            bookingInfo.selectedSpecialist,
            bookingInfo.selectedBookingDate,
            bookingInfo.selectedBookingTime,
            bookingInfo.selectedServices?.length,
            !(productList.length > 0),
            !shop.admin.is_verified_stripe_account && !(user && user.total_loyalty_amount >= bookingInfo.bookingTotal),
        ].every(Boolean);

        if (bookingInfo.isModifyingAppointment) {
            if (hasSelectedAllRequiredFields && bookingInfo.isShowNoShowPolicy) {
                return t("Complete");
            } else {
                return t("Modify");
            }
        }

        return hasSelectedAllRequiredFields && bookingInfo.isShowNoShowPolicy ? t("Complete") : t("Continue");
    };

    const manageBookingProcess = (finalTotal?: null | number, isTempData?: null | number) => {
        const checkValue = finalTotal ? user && user.total_loyalty_amount >= finalTotal : user && user.total_loyalty_amount >= bookingInfo.bookingTotal;
        if (bookingInfo.isModifyingAppointment && bookingInfo.oldPaymentOption === "pay_now") {
            updateBooking();
        } else if (productList.length > 0) {
            if (checkForNavigateFurtherStep(isTempData)) {
                navigate(`${PATH.APPOINTEMENT}/${BookingTABS.PRODUCT_BOOKING}`);
            }
        } else if (!user) {
            dispatch(setIsShowLoginModal(true));
        } else if (shop.admin.is_verified_stripe_account || checkValue) {
            if (checkForNavigateFurtherStep(isTempData)) {
                navigate(`${PATH.APPOINTEMENT}/${BookingTABS.FINALIZE_BOOKING}`);
            }
        } else if (bookingInfo.isModifyingAppointment) {
            if (!bookingInfo.isShowNoShowPolicy) {
                if (bookingInfo.tempStore.SelectionCount === 0 || isNoShowPolicyApplicable() || isTempData) {
                    dispatch(setIsShowNoShowPolicy(true));
                }
            } else {
                updateBooking();
            }
        } else {
            handleCreateBooking(isTempData);
        }
    };

    const handleCreateBooking = async (isTempData?: null | number) => {
        if (uiState.isMobile) {
            if (!bookingInfo.isShowNoShowPolicy && !bookingInfo.isShowOrderSummary) {
                if (bookingInfo.tempStore.SelectionCount === 0 || isNoShowPolicyApplicable() || isTempData) {
                    dispatch(setIsShowNoShowPolicy(true));
                }
                // dispatch(setIsShowNoShowPolicy(true));
            } else if (bookingInfo.isShowNoShowPolicy) {
                dispatch(setIsShowOrderSummary(true));
                dispatch(setIsShowNoShowPolicy(false));
            } else if (bookingInfo.isShowOrderSummary) {
                createBooking();
            }
        } else {
            if (!bookingInfo.isShowNoShowPolicy) {
                if (bookingInfo.tempStore.SelectionCount === 0 || isNoShowPolicyApplicable() || isTempData) {
                    dispatch(setIsShowNoShowPolicy(true));
                }
            } else {
                createBooking();
            }
        }
    };

    const handleCardAdd = () => {
        dispatch(setSubmitTriggered(true));
    };

    const updateBooking = () => {
        setIsLoading(true);
        let url = API.BOOKING.UPDATE_PAY_NOW;

        const params = {
            id: bookingInfo.modifyingAppointmentId,
            shop_id: bookingInfo.selectedShopLocation?.shop_id,
            location_id: bookingInfo.selectedShopLocation?.id,
        };
        const payload: any = {
            booking_date: bookingInfo.selectedBookingDate,
            booking_time: bookingInfo.selectedBookingTime ? `${bookingInfo.selectedBookingTime}:00` : undefined,
        };

        if (bookingInfo.oldPaymentOption === "pay_later") {
            url = API.BOOKING.UPDATE_PAY_LATER;

            payload.payment_type = bookingInfo.paymentType;
            payload.payment_option = bookingInfo.paymentOption;

            const products = bookingInfo.selectedProducts.map((product: any) => ({
                id: product.selectedVariant.inventory.id,
                quantity: 1,
            }));
            payload.products = products;

            const services = bookingInfo.selectedServices.map((service: any) => ({
                id: service.id,
                quantity: service.quantity,
            }));
            payload.services = services;
        }

        axiosPatch(url, payload, params)
            .then(async (response) => {
                dispatch(setBookingInitialState());
                await dispatch(me());
                toast.success(response.data.message);
                if (shopLocations && shopLocations.length === 1) {
                    dispatch(setSelectedShopLocation(shopLocations[0]));
                }
                navigateBasedOnShopLocations();
            })
            .catch((error) => {
                toast.error(error?.message ?? "Something went wrong, Please try again");
            })
            .finally(() => setIsLoading(false));
    };

    const createBooking = () => {
        setIsLoading(true);
        const products = bookingInfo.selectedProducts.map((product: any) => ({
            id: product.selectedVariant.inventory.id,
            quantity: 1,
        }));

        const services = bookingInfo.selectedServices.map((service: any) => ({
            id: service.id,
            quantity: service.quantity,
        }));

        const params = {
            shop_id: bookingInfo.selectedShopLocation?.shop_id,
            location_id: bookingInfo.selectedShopLocation?.id,
        };

        const payload = {
            staff_id: bookingInfo.selectedSpecialist?.id,
            services,
            products,
            payment_type: bookingInfo.paymentType,
            payment_option: bookingInfo.paymentOption,
            booking_date: bookingInfo.selectedBookingDate,
            booking_time: bookingInfo.selectedBookingTime ? `${bookingInfo.selectedBookingTime}:00` : undefined,
            shop_promotion_id: bookingInfo.selectedPromocode?.id ?? undefined,
        };
        axiosPost(API.BOOKING.CREATE, payload, params)
            .then(async () => {
                dispatch(setBookingInitialState());
                await dispatch(me());
                if (shopLocations && shopLocations.length === 1) {
                    dispatch(setSelectedShopLocation(shopLocations[0]));
                }
                navigateBasedOnShopLocations();
            })
            .catch((error) => {
                toast.error(error?.message ?? "Something went wrong, Please try again");
            })
            .finally(() => setIsLoading(false));
    };

    const handleBack = (step: EBookingTabState) => () => {
        switch (step) {
            case EBookingTabState.TAB_MENU:
                navigateBasedOnShopLocations();
                break;
            case EBookingTabState.SELECT_SERVICE:
                dispatch(setTempStore({ selectedServices: [] }));
                dispatch(setStep(EBookingTabState.TAB_MENU));
                navigate(`${PATH.APPOINTEMENT}`);
                break;
            case EBookingTabState.SELECT_DATE_TIME:
                dispatch(setTempStore({ selectedBookingDate: undefined, selectedBookingTime: undefined, selectedBookingDateTime: undefined }));
                dispatch(setStep(EBookingTabState.TAB_MENU));
                navigate(`${PATH.APPOINTEMENT}`);
                break;
            case EBookingTabState.SELECT_SPECIALIST:
                dispatch(setTempStore({ selectedSpecialist: undefined }));
                dispatch(setStep(EBookingTabState.TAB_MENU));
                navigate(`${PATH.APPOINTEMENT}`);
                break;
            case EBookingTabState.PRODUCT_BOOKING:
                navigate(PATH.APPOINTEMENT);
                break;
            case EBookingTabState.FINALIZE_BOOKING:
                if (bookingInfo.isShowOrderSummary) {
                    dispatch(setIsShowOrderSummary(false));
                } else {
                    let navigateStep = BookingTABS.TAB_MENU;
                    if (productList.length > 0) {
                        navigateStep = BookingTABS.PRODUCT_BOOKING;
                    }
                    navigate(`${PATH.APPOINTEMENT}/${navigateStep}`);
                }
                break;
            default:
                break;
        }
    };

    const handleChange = (step: EBookingTabState) => () => {
        switch (step) {
            case EBookingTabState.SELECT_DATE_TIME:
                if (isModal && uiState.isMobile && !bookingInfo.isShowOrderSummary) {
                    if (hasSelectedRequiredFields) {
                        syncTempStoreOnClose(bookingInfo);
                    }
                } else if (!bookingInfo.isShowOrderSummary) {
                    dispatch(setSelectedBookingDate(bookingInfo.tempStore?.selectedBookingDate));
                    dispatch(setSelectedBookingTime(bookingInfo.tempStore?.selectedBookingTime));
                    dispatch(setTempStore({ selectedBookingDateTime: undefined }));
                    dispatch(setPreview({ key: "selectedBookingDateTime", value: bookingInfo.tempStore?.selectedBookingDateTime }));
                }
                if (!bookingInfo.selectedSpecialist) {
                    navigate(`${PATH.APPOINTEMENT}/${BookingTABS.SELECT_SPECIALIST}`);
                } else if (!bookingInfo.selectedServices.length) {
                    navigate(`${PATH.APPOINTEMENT}/${BookingTABS.SELECT_SERVICE}`);
                } else {
                    manageBookingProcess();
                }
                break;
            case EBookingTabState.SELECT_SERVICE:
                setIsServiceBtnClick(true);
                if (isModal && uiState.isMobile && !bookingInfo.isShowOrderSummary) {
                    if (hasSelectedRequiredFields) {
                        syncTempStoreOnClose(bookingInfo);
                    }
                } else if (!bookingInfo.isShowOrderSummary) {
                    dispatch(setTempStore({ selectedServices: [] }));
                    dispatch(setSelectedServices(bookingInfo.tempStore?.selectedServices));
                    dispatch(setPreview({ key: "selectedServices", value: bookingInfo.tempStore?.selectedServices }));
                }
                if (!bookingInfo.selectedSpecialist) {
                    navigate(`${PATH.APPOINTEMENT}/${BookingTABS.SELECT_SPECIALIST}`);
                } else if (!(bookingInfo.selectedBookingDate && bookingInfo.selectedBookingTime)) {
                    navigate(`${PATH.APPOINTEMENT}/${BookingTABS.SELECT_DATE_TIME}`);
                } else {
                    manageBookingProcess();
                }
                break;
            case EBookingTabState.SELECT_SPECIALIST:
                if (isModal && uiState.isMobile) {
                    // manage for mobile when i agree modal open sync temp data to main on i agree click
                    if (hasSelectedRequiredFields && !bookingInfo.isShowOrderSummary) {
                        syncTempStoreOnClose(bookingInfo);
                    }
                    // no need to manage update booking flow because we dont change specialist booking update time
                    if (bookingInfo.isShowNoShowPolicy) {
                        dispatch(setIsShowOrderSummary(true));
                        dispatch(setIsShowNoShowPolicy(false));
                    } else if (bookingInfo.isShowOrderSummary) {
                        createBooking();
                    }
                } else if (!bookingInfo.isShowOrderSummary) {
                    dispatch(setSelectedSpecialist(bookingInfo.tempStore?.selectedSpecialist));
                    dispatch(setPreview({ key: "selectedSpecialist", value: bookingInfo.tempStore?.selectedSpecialist }));
                    dispatch(setTempStore({ selectedSpecialist: undefined }));
                    if (bookingInfo.isShowNoShowPolicy) {
                        createBooking();
                    } else {
                        if (bookingInfo.selectedServices.length) {
                            setIsLoading(true);
                            const payload = {
                                staff_id: bookingInfo.tempStore?.selectedSpecialist?.id,
                                booking_date: bookingInfo.selectedBookingDate,
                                booking_time: bookingInfo.selectedBookingTime ? `${bookingInfo.selectedBookingTime}:00` : undefined,
                            };
                            const params = {
                                shop_id: bookingInfo.selectedShopLocation?.shop_id,
                                location_id: bookingInfo.selectedShopLocation?.id,
                            };
                            axiosPost(API.SERVICE.LIST, payload, params)
                                .then(async (response) => {
                                    const services = response.data.data;
                                    const selectedServices: ISpecialistService[] = [];
                                    bookingInfo.selectedServices.forEach((selectedService: ISpecialistService) => {
                                        const service = services.find((item: ISpecialistService) => item.id === selectedService.id);
                                        if (service) {
                                            selectedServices.push({ ...selectedService, price: service.price });
                                        }
                                    });
                                    const filteredServicesBookingTotal = await calculateBookingTotal(selectedServices, bookingInfo.selectedProducts, bookingInfo.selectedShopLocation?.sales_tax);
                                    const selectedServicesBookingTotal = await calculateBookingTotal(
                                        bookingInfo.selectedServices,
                                        bookingInfo.selectedProducts,
                                        bookingInfo.selectedShopLocation?.sales_tax,
                                    );
                                    // manage if old service total and filtered service total is same then specialist not change and work as it is but if change
                                    // then clear service and time from main data so user want to reselect that
                                    if (!bookingInfo.tempStore?.selectedSpecialist) {
                                        navigate(`${PATH.APPOINTEMENT}/${BookingTABS.SELECT_SPECIALIST}`);
                                    } else if (filteredServicesBookingTotal !== selectedServicesBookingTotal && bookingInfo.selectedSpecialist) {
                                        dispatch(setSelectedServices([]));
                                        dispatch(setPreview({ key: "selectedServices", value: [] }));
                                        dispatch(setSelectedBookingDate(undefined));
                                        dispatch(setSelectedBookingTime(undefined));
                                        dispatch(setPreview({ key: "selectedBookingDateTime", value: undefined }));
                                        navigate(`${PATH.APPOINTEMENT}/${BookingTABS.SELECT_SERVICE}`);
                                    } else {
                                        dispatch(setSelectedServices(selectedServices));
                                        const finalTotal = await calculateBookingTotal(selectedServices, bookingInfo.selectedProducts, bookingInfo.selectedShopLocation?.sales_tax);
                                        dispatch(setBookingTotal(finalTotal));
                                        if (!(bookingInfo.selectedBookingDate && bookingInfo.selectedBookingTime)) {
                                            navigate(`${PATH.APPOINTEMENT}/${BookingTABS.SELECT_DATE_TIME}`);
                                        } else {
                                            dispatch(setTempStore({ SelectionCount: bookingInfo.selectedSpecialist ? 1 : 0 }));
                                            manageBookingProcess(finalTotal, bookingInfo.tempStore.selectedSpecialist ? 1 : 0);
                                        }
                                        dispatch(setPreview({ key: "selectedServices", value: selectedServices }));
                                    }
                                    // dispatch(setSelectedServices(selectedServices));
                                    // const finalTotal = await calculateBookingTotal(selectedServices, bookingInfo.selectedProducts, bookingInfo.selectedShopLocation?.sales_tax);
                                    // dispatch(setBookingTotal(finalTotal));
                                    // if (!(selectedServices.length > 0)) {
                                    //     navigate(`${PATH.APPOINTEMENT}/${BookingTABS.SELECT_SERVICE}`);
                                    // } else if (!(bookingInfo.selectedBookingDate && bookingInfo.selectedBookingTime)) {
                                    //     navigate(`${PATH.APPOINTEMENT}/${BookingTABS.SELECT_DATE_TIME}`);
                                    // } else {
                                    //     dispatch(setTempStore({ SelectionCount: bookingInfo.selectedSpecialist ? 1 : 0 }));
                                    //     manageBookingProcess(finalTotal, bookingInfo.tempStore.selectedSpecialist ? 1 : 0);
                                    // }
                                    // dispatch(setPreview({ key: "selectedServices", value: selectedServices }));
                                })
                                .catch((error) => toast.error(error?.message ?? "Something went wrong, Please try again"))
                                .finally(() => setIsLoading(false));
                        } else if (!bookingInfo.selectedServices.length) {
                            navigate(`${PATH.APPOINTEMENT}/${BookingTABS.SELECT_SERVICE}`);
                        }
                    }
                }
                break;
            default:
                break;
        }
    };

    const handleContinue = (step: EBookingTabState) => () => {
        switch (step) {
            case EBookingTabState.TAB_MENU:
                if (bookingInfo.isModifyingAppointment && bookingInfo.oldPaymentOption === "pay_now") {
                    updateBooking();
                } else {
                    if (productList.length > 0) {
                        navigate(`${PATH.APPOINTEMENT}/${BookingTABS.PRODUCT_BOOKING}`);
                    } else if (!user) {
                        dispatch(setIsShowLoginModal(true));
                        return;
                    } else {
                        if (shop.admin.is_verified_stripe_account || (user && user.total_loyalty_amount >= bookingInfo.bookingTotal)) {
                            navigate(`${PATH.APPOINTEMENT}/${BookingTABS.FINALIZE_BOOKING}`);
                        } else {
                            if (bookingInfo.isModifyingAppointment) {
                                if (!bookingInfo.isShowNoShowPolicy) {
                                    dispatch(setIsShowNoShowPolicy(true));
                                } else {
                                    updateBooking();
                                }
                            } else {
                                handleCreateBooking();
                            }
                        }
                    }
                }
                break;
            case EBookingTabState.PRODUCT_BOOKING:
                if (!user) {
                    dispatch(setIsShowLoginModal(true));
                    return;
                } else {
                    if (shop.admin.is_verified_stripe_account || (user && user.total_loyalty_amount >= bookingInfo.bookingTotal)) {
                        navigate(`${PATH.APPOINTEMENT}/${BookingTABS.FINALIZE_BOOKING}`);
                    } else {
                        if (bookingInfo.isModifyingAppointment) {
                            if (!bookingInfo.isShowNoShowPolicy) {
                                dispatch(setIsShowNoShowPolicy(true));
                            } else {
                                updateBooking();
                            }
                        } else {
                            handleCreateBooking();
                        }
                    }
                }
                // handleStep(EBookingTabState.FINALIZE_BOOKING);
                break;
            case EBookingTabState.FINALIZE_BOOKING:
                if (!bookingInfo.paymentType) {
                    dispatch(setIsValidPaymentType(true));
                } else if (bookingInfo.isModifyingAppointment) {
                    if (!bookingInfo.isShowNoShowPolicy) {
                        dispatch(setIsShowNoShowPolicy(true));
                    } else {
                        updateBooking();
                    }
                } else {
                    handleCreateBooking();
                }
                break;
        }
    };

    // eslint-disable-next-line complexity
    const renderStepComponent = () => {
        const isTabMenuDisabled = !(
            bookingInfo.selectedShopLocation &&
            bookingInfo.selectedBookingDate &&
            bookingInfo.selectedBookingTime &&
            bookingInfo.selectedServices.length &&
            bookingInfo.selectedSpecialist
        );

        const isSpecialistDisabled =
            !validateConditions(!!bookingInfo.selectedShopLocation, !!bookingInfo.tempStore?.selectedSpecialist) &&
            !validateConditions(!!bookingInfo.selectedShopLocation, !!bookingInfo.selectedSpecialist);

        const isServiceDisabled =
            !validateConditions(!!bookingInfo.selectedShopLocation, bookingInfo.tempStore?.selectedServices?.length > 0) &&
            !validateConditions(!!bookingInfo.selectedShopLocation, bookingInfo.selectedServices?.length > 0);

        const isDateDisabled =
            !validateConditions(!!bookingInfo.selectedShopLocation, !!bookingInfo.tempStore?.selectedBookingDate, !!bookingInfo.tempStore?.selectedBookingTime) &&
            !validateConditions(!!bookingInfo.selectedShopLocation, !!bookingInfo.selectedBookingDate, !!bookingInfo.selectedBookingTime);

        const isProductDisabled = !validateConditions(
            !!bookingInfo.selectedShopLocation,
            !!bookingInfo.selectedBookingDate,
            !!bookingInfo.selectedBookingTime,
            bookingInfo.selectedServices.length > 0,
            !!bookingInfo.selectedSpecialist,
        );

        switch (bookingInfo.step) {
            case EBookingTabState.TAB_MENU:
                return (
                    <div className="absolute max-lg:bottom-0 gap-2 mt-auto max-lg:w-[calc(100%+32px)] lg:w-full lg:relative -tracking-[0.03rem] lg:bg-transparent flex lg:gap-3 lg:items-center lg:!mx-auto bg-containerBgColor max-lg:mx-[-16px] max-lg:px-8 max-lg:pt-3 max-lg:shadow-[0px_-4px_4px_0px_#0000000D]">
                        {/*  mx-auto items-center  justify-center mt-2.5  lg:mb-0`} */}
                        {!isModal && (
                            <CustomButton
                                secondary
                                onClick={handleBack(EBookingTabState.TAB_MENU)}
                                disabled={isLoading}
                                className=" -tracking-[0.03rem] hover:border-primary  px-5 h-[36px] !w-full lg:mb-0"
                            >
                                {t("Back")}
                            </CustomButton>
                        )}
                        <CustomButton
                            primary
                            disabled={isTabMenuDisabled || isLoading}
                            isLoading={isLoading}
                            className={`${bookingInfo.isModifyingAppointment ? "w-full !mx-0" : "w-full"} -tracking-[0.03rem]  mx-auto items-center  justify-center lg:mb-0`}
                            onClick={handleContinue(EBookingTabState.TAB_MENU)}
                        >
                            {getButtonLabel()}
                        </CustomButton>
                    </div>
                );
            case EBookingTabState.SELECT_DATE_TIME:
                return (
                    <div
                        className={`absolute max-lg:bottom-0 gap-2 mt-auto max-lg:w-[calc(100%+32px)] lg:w-full lg:relative -tracking-[0.03rem] lg:bg-transparent flex lg:gap-3 lg:items-center lg:!mx-auto bg-containerBgColor max-lg:mx-[-16px] max-lg:px-8 max-lg:pt-3 max-lg:shadow-[0px_-4px_4px_0px_#0000000D] ${
                            isModal ? (bookingInfo.isShowOrderSummary ? "max-lg:!w-[calc(100%)] max-lg:!mx-[0px] max-lg:!bottom-[0] max-lg:!px-4" : "max-lg:!px-4 max-lg:bottom-[16px]") : ""
                        }`}
                    >
                        {!isModal && (
                            <CustomButton
                                secondary
                                onClick={handleBack(EBookingTabState.SELECT_DATE_TIME)}
                                disabled={isLoading}
                                className="border hover:border-primary -tracking-[0.03rem] px-5 lg:mt-2.5 h-[36px] w-full lg:mb-0"
                            >
                                {t("Back")}
                            </CustomButton>
                        )}
                        <CustomButton
                            className={`${bookingInfo.isModifyingAppointment ? "w-full !mx-0" : "w-full"} mx-auto -tracking-[0.03rem] items-center justify-center lg:mt-2.5 lg:mb-0`}
                            primary
                            onClick={handleChange(EBookingTabState.SELECT_DATE_TIME)}
                            isLoading={isLoading}
                            disabled={isLoading || (bookingInfo.isShowNoShowPolicy || bookingInfo.isShowOrderSummary ? false : isDateDisabled)}
                        >
                            {getButtonLabel()}
                        </CustomButton>
                    </div>
                );
            case EBookingTabState.SELECT_SERVICE:
                return (
                    <div
                        className={`absolute max-lg:bottom-[0px] gap-2 mt-auto max-lg:w-[calc(100%+32px)] lg:w-full lg:relative -tracking-[0.03rem] lg:bg-transparent flex lg:gap-3 lg:items-center lg:!mx-auto bg-contentBackground max-lg:mx-[-16px] max-lg:px-8 max-lg:pt-3 max-lg:shadow-[0px_-4px_4px_0px_#0000000D] ${
                            isModal ? (bookingInfo.isShowOrderSummary ? "max-lg:!w-[calc(100%)] max-lg:!mx-[0px] max-lg:!bottom-[0] max-lg:!px-4" : "max-lg:!px-4 max-lg:bottom-[16px]") : ""
                        }`}
                    >
                        {!isModal && (
                            <CustomButton
                                secondary
                                onClick={handleBack(EBookingTabState.SELECT_SERVICE)}
                                disabled={isLoading}
                                className=" hover:border-primary -tracking-[0.03rem] hover:text-primary px-5 lg:mt-2.5 h-[36px] w-full  lg:mb-0"
                            >
                                {t("Back")}
                            </CustomButton>
                        )}
                        <CustomButton
                            className={`${bookingInfo.isModifyingAppointment ? "w-full " : "w-full"}   mx-auto items-center -tracking-[0.03rem] justify-center lg:mt-2.5 lg:mb-0`}
                            primary
                            isLoading={isLoading}
                            disabled={isLoading || (bookingInfo.isShowNoShowPolicy || bookingInfo.isShowOrderSummary ? false : isServiceDisabled)}
                            onClick={handleChange(EBookingTabState.SELECT_SERVICE)}
                        >
                            {getButtonLabel()}
                        </CustomButton>
                    </div>
                );
            case EBookingTabState.SELECT_SPECIALIST:
                return (
                    <div
                        className={`absolute max-lg:bottom-0 gap-2 mt-auto max-lg:w-[calc(100%+32px)] lg:w-full lg:relative -tracking-[0.03rem] lg:bg-transparent flex lg:gap-3 lg:items-center lg:!mx-auto bg-containerBgColor max-lg:mx-[-16px] max-lg:px-8 max-lg:pt-3 max-lg:shadow-[0px_-4px_4px_0px_#0000000D] ${
                            isModal ? (bookingInfo.isShowOrderSummary ? "max-lg:!w-[calc(100%)] max-lg:!mx-[0px] max-lg:!bottom-[0] max-lg:!px-4" : "max-lg:!px-4 max-lg:bottom-[16px]") : ""
                        }`}
                    >
                        {!isModal && (
                            <CustomButton
                                secondary
                                onClick={handleBack(EBookingTabState.SELECT_SPECIALIST)}
                                disabled={isLoading}
                                className="hover:border-primary hover:text-primary -tracking-[0.03rem] px-5 lg:mt-2.5 h-[36px] w-full lg:mb-0"
                            >
                                {t("Back")}
                            </CustomButton>
                        )}
                        <CustomButton
                            className="w-full lg:relative mx-auto  items-center -tracking-[0.03rem] justify-center lg:mt-2.5  lg:mb-0"
                            primary
                            onClick={handleChange(EBookingTabState.SELECT_SPECIALIST)}
                            isLoading={isLoading}
                            disabled={isLoading || (bookingInfo.isShowNoShowPolicy || bookingInfo.isShowOrderSummary ? false : isSpecialistDisabled)}
                        >
                            {getButtonLabel()}
                        </CustomButton>
                    </div>
                );

            case EBookingTabState.PRODUCT_BOOKING:
                return (
                    <div
                        className={`absolute max-lg:bottom-0 gap-2 mt-auto max-lg:w-[calc(100%)] lg:w-full lg:relative -tracking-[0.03rem] lg:bg-transparent flex lg:gap-3 lg:items-center lg:!mx-auto bg-containerBgColor  max-lg:px-4 max-lg:pt-3 max-lg:shadow-[0px_-4px_4px_0px_#0000000D] ${
                            isModal && !bookingInfo.isShowOrderSummary ? "max-lg:!bottom-[16px] max-lg:w-[calc(100%+32px)] max-lg:mx-[-16px] max-lg:px-[16px]" : ""
                        } `}
                    >
                        {!isModal && (
                            <CustomButton
                                secondary
                                onClick={handleBack(EBookingTabState.PRODUCT_BOOKING)}
                                disabled={isLoading}
                                className=" hover:border-primary -tracking-[0.03rem]  px-5 lg:mt-2.5 h-[36px] w-full  lg:mb-0"
                            >
                                {t("Back")}
                            </CustomButton>
                        )}
                        <CustomButton
                            className={`${bookingInfo.isModifyingAppointment ? "w-full !mx-0" : "w-full "}  -tracking-[0.03rem] mx-auto items-center  justify-center lg:mt-2.5  lg:mb-0`}
                            primary
                            disabled={isProductDisabled || isLoading}
                            isLoading={isLoading}
                            onClick={handleContinue(EBookingTabState.PRODUCT_BOOKING)}
                        >
                            {uiState.isMobile && bookingInfo.isShowOrderSummary
                                ? t("Complete")
                                : isModal
                                ? t("I agree")
                                : bookingInfo.isModifyingAppointment
                                ? !shop.admin.is_verified_stripe_account && !(user && user.total_loyalty_amount >= bookingInfo.bookingTotal) && bookingInfo.isShowNoShowPolicy
                                    ? t("Complete")
                                    : t("Modify")
                                : !shop.admin.is_verified_stripe_account && !(user && user.total_loyalty_amount >= bookingInfo.bookingTotal) && bookingInfo.isShowNoShowPolicy
                                ? t("Complete")
                                : t("Continue")}
                        </CustomButton>
                    </div>
                );

            case EBookingTabState.FINALIZE_BOOKING:
                if (bookingInfo.selectedShopLocation && bookingInfo.selectedBookingDate && bookingInfo.selectedBookingTime && bookingInfo.selectedServices.length && bookingInfo.selectedSpecialist) {
                    return (
                        <div
                            className={`absolute max-lg:bottom-[36px] gap-2 mt-auto max-lg:w-[calc(100%)] max-lg:mx-[-16px] max-lg:px-8  lg:w-full  lg:relative -tracking-[0.03rem] lg:bg-transparent flex lg:gap-3 lg:items-center lg:!mx-auto bg-containerBgColor max-lg:pt-3 max-lg:shadow-[0px_-4px_4px_0px_#0000000D] ${
                                !isModal
                                    ? "max-lg:w-[calc(100%)] max-lg:!bottom-[0px] max-lg:mx-[0px] max-lg:px-[16px]"
                                    : bookingInfo.isShowOrderSummary
                                    ? "max-lg:w-[calc(100%)] max-lg:mx-[0px] max-lg:!bottom-[0] max-lg:!px-4"
                                    : "max-lg:w-[calc(100%+32px)] max-lg:!bottom-[16px] max-lg:!px-4"
                            }  ${bookingInfo.isShowNoShowPolicy ? "max-lg:!shadow-none" : ""}`}
                        >
                            {!isModal && (
                                <CustomButton
                                    secondary
                                    onClick={handleBack(EBookingTabState.FINALIZE_BOOKING)}
                                    disabled={isLoading || bookingInfo.isCardLoading}
                                    className=" hover:border-primary -tracking-[0.03rem]  px-5 lg:mt-2.5 h-[36px] w-full  lg:mb-0"
                                >
                                    {t("Back")}
                                </CustomButton>
                            )}
                            {/* {bookingInfo.isCardAdd || (user && user.total_loyalty_amount >= bookingInfo.bookingTotal) ? ( */}
                            {bookingInfo.paymentType === "loyalty" || (bookingInfo.isCardAdd && bookingInfo.paymentType === "stripe") ? (
                                <CustomButton
                                    className={`w-full mx-auto items-center tracking-[0.03rem] justify-center lg:mt-2.5 lg:mb-0 ${bookingInfo.isShowNoShowPolicy ? "lg:!h-[48px]" : ""}`}
                                    primary
                                    onClick={handleContinue(EBookingTabState.FINALIZE_BOOKING)}
                                    disabled={isLoading}
                                    isLoading={isLoading}
                                >
                                    {uiState.isMobile && bookingInfo.isShowOrderSummary ? t("Complete") : isModal ? t("I agree") : t("Complete")}
                                </CustomButton>
                            ) : (
                                <CustomButton
                                    className={`w-full mx-auto items-center tracking-[0.03rem] justify-center lg:mt-2.5 lg:mb-0 ${bookingInfo.isShowNoShowPolicy ? "lg:!h-[48px]" : ""}`}
                                    primary
                                    onClick={handleCardAdd}
                                    disabled={bookingInfo.isCardLoading}
                                    isLoading={bookingInfo.isCardLoading}
                                >
                                    {t("Continue")}
                                </CustomButton>
                            )}
                        </div>
                    );
                } else {
                    return <></>;
                }

            default:
                return <></>;
        }
    };

    return renderStepComponent();
};

export default ManageSubmit;
