import React, { FC } from "react";
import CustomSwiper from "src/components/CustomSwiper/CustomSwiper";
import { SwiperSlide } from "swiper/react";
import { ChevronLeft, ChevronRight } from "@untitled-ui/icons-react/build/cjs";
import { IMProduct } from "./Product.interface";
import MProductInfo from "./MProductInfo";
import { useTranslation } from "react-i18next";

const MProduct: FC<IMProduct> = ({ isLoading, productList, handlePrevious, handleNext, setSwiperRef, handleProduct }) => {
    const { t } = useTranslation();
    return isLoading ? (
        <div className="custom_loading_wrapper">
            <div className="custom_loading"></div>
        </div>
    ) : productList.length > 0 ? (
        <div className="flex flex-col items-center">
            <CustomSwiper setSwiperRef={setSwiperRef} className="mySwiper w-full">
                {productList.map((product, index) => (
                    <SwiperSlide key={index}>
                        <MProductInfo productData={product} key={index} handleProduct={handleProduct} />
                    </SwiperSlide>
                ))}
            </CustomSwiper>
            {productList.length > 1 && (
                <div className="flex relative mt-4">
                    <button
                        className="rounded-full flex flex-col justify-center items-center border border-TextColor cursor-pointer h-[24px] w-[24px] min-w-[24px] smd:w-[40px] smd:h-[40px] smd:min-w-[40px] mr-3.5 bg-transparent"
                        onClick={handlePrevious}
                    >
                        <ChevronLeft className="text-TextColor w-4 h-4 smd:w-[20px] smd:h-[20px]" />
                    </button>
                    <button
                        className="rounded-full flex flex-col justify-center items-center border border-TextColor cursor-pointer h-[24px] w-[24px] min-w-[24px] smd:w-[40px] smd:h-[40px] smd:min-w-[40px] bg-transparent"
                        onClick={handleNext}
                    >
                        <ChevronRight className="text-TextColor w-4 h-4 smd:w-[20px] smd:h-[20px]" />
                    </button>
                </div>
            )}
        </div>
    ) : (
        !isLoading && (
            <div className="flex flex-col min-h-[320px] items-center justify-center">
                <span className="text-TextColor text-[16px] leading-[21px] font-medium">{t("No products available.")}</span>
            </div>
        )
    );
};

export default MProduct;
