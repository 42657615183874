import React, { FC, useLayoutEffect, useState } from "react";
import CustomSwiper from "src/components/CustomSwiper/CustomSwiper";
import { SwiperSlide } from "swiper/react";
import { ChevronLeft, ChevronRight } from "@untitled-ui/icons-react/build/cjs";
import ProductInfo from "./ProductInfo";
import { init, IProductInfo, IWProduct } from "./Product.interface";
import { useEnableSliderButtons } from "src/hooks/useEnableSliderButtons";
import IndividualProductDetail from "./IndividualProductDetail";
import { useTranslation } from "react-i18next";

const WProduct: FC<IWProduct> = ({ isLoading, productData, handlePrevious, handleNext, setSwiperRef, handleProduct }) => {
    const { t } = useTranslation();
    const [currentBreakpoint, setCurrentBreakpoint] = useState("1280");
    const conditions = [
        { breakpoint: "1280", minLength: 4 },
        { breakpoint: "1024", minLength: 3 },
        { breakpoint: "640", minLength: 2 },
    ];
    const isEnableSliderButtons = useEnableSliderButtons(currentBreakpoint, productData.length, conditions, true);
    const handleSlideChange = (event: any) => {
        setCurrentBreakpoint(event.currentBreakpoint);
    };
    const [breakPoint, setBreakPoint] = useState(init);
    const [swiperKey, setSwiperKey] = useState(0);

    useLayoutEffect(() => {
        if (productData.length === 2 || productData.length === 3) {
            setBreakPoint((prev: any) => ({
                ...prev,
                1024: {
                    slidesPerView: productData.length,
                    spaceBetween: 20,
                },
                1280: {
                    slidesPerView: productData.length,
                    spaceBetween: 20,
                },
            }));
        } else {
            setBreakPoint(init);
        }
        setSwiperKey((prevKey) => prevKey + 1);
    }, [productData]);

    return (
        <div className="flex flex-wrap justify-between gap-1">
            <div className="flex relative mt-5 justify-end w-full mb-5">
                <button
                    onClick={handlePrevious}
                    disabled={!isEnableSliderButtons || isLoading}
                    className={`rounded-full flex justify-center items-center border border-TextColor h-[52px] min-w-[52px] w-[52px] mr-2.5 bg-templateBg ${
                        !isEnableSliderButtons ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
                    }`}
                >
                    <ChevronLeft className="w-[24px] h-[24px] text-TextColor" />
                </button>
                <button
                    onClick={handleNext}
                    disabled={!isEnableSliderButtons || isLoading}
                    className={`rounded-full flex justify-center items-center border border-TextColor bg-templateBg h-[52px] min-w-[52px] w-[52px] ${
                        !isEnableSliderButtons ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
                    }`}
                >
                    <ChevronRight className="w-[24px] h-[24px] text-TextColor" />
                </button>
            </div>
            {isLoading ? (
                <div className="custom_loading_wrapper h-[425px]">
                    <div className="custom_loading"></div>
                </div>
            ) : productData.length > 0 ? (
                productData.length === 1 ? (
                    <IndividualProductDetail productData={productData} />
                ) : (
                    <CustomSwiper key={swiperKey} setSwiperRef={setSwiperRef} className="mySwiper w-full" loop={isEnableSliderButtons} onInit={handleSlideChange} breakpoints={breakPoint}>
                        {productData.map((products: IProductInfo, index: number) => (
                            <SwiperSlide key={`product-${index}`}>
                                <ProductInfo productData={products} handleProduct={handleProduct} />
                            </SwiperSlide>
                        ))}
                    </CustomSwiper>
                )
            ) : (
                <div className="flex w-full text-TextColor justify-center items-center h-[425px]">{t("No products available.")}</div>
            )}
        </div>
    );
};

export default WProduct;
