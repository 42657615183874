import React, { useEffect, useState } from "react";
import AppointmentPreview from "./AppointmentPreview";
import { BookingTABS, EBookingTabState } from "./bookAppointment.interface";
import SelectSpecialists from "./SelectSpecialists";
import SelectDateAndTime from "./SelectDateAndTime";
import SelectServices from "./SelectServices";
import BookAppointmentTabMenu from "./BookAppointmentTabMenu";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import FinalizeBooking from "./FinalizeBooking";
import ProductBooking from "./ProductBooking";
import Stepper from "./Stepper";
import { getBookingInfo, setStep } from "src/app/BookAppointment/Booking.slice";
import { Link, useNavigate, useParams } from "react-router-dom";
import { allShopProducts, currentShop, getAllShopProducts } from "src/redux/reducers/common/Common.slice";
import { ISpecialistService } from "src/Interface";
import { axiosPost } from "src/utils/requestClient";
import { API } from "src/constants/api";
import { toast } from "react-toastify";
import { PATH } from "src/constants/path";
import { useShopNavigation } from "src/hooks/useShopNavigation";
import { useSpring, animated } from "react-spring";
import { onError } from "src/utils/global-functions";
import { isGetApp } from "src/redux/reducers/Landing.slice";
import { useTranslation } from "react-i18next";

export interface ILoading {
    specialist: boolean;
    service: boolean;
}
const BookAppointment = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { tab: currentTab } = useParams();
    const { navigateBasedOnShopLocations } = useShopNavigation();
    const productList = useAppSelector(allShopProducts);
    const booking = useAppSelector(getBookingInfo);
    const shop = useAppSelector(currentShop);
    const [activeTab, setActiveTab] = useState<any>();
    const [isLoading, setIsLoading] = useState<ILoading>({ specialist: false, service: false });
    const [count, setCount] = useState<any>({ specialist: 0, service: 0 });
    const previewAnimationProps = useSpring<React.CSSProperties>({
        from: { opacity: 0, transform: "translateX(20%)" },
        to: { opacity: 1, transform: "translateX(0%)" },
        config: { tension: 180, friction: 40, duration: 450 },
        immediate: false,
    });
    const isGetAppVisible = useAppSelector(isGetApp);

    useEffect(() => {
        if (!booking.selectedShopLocation) {
            navigateBasedOnShopLocations();
        }
        handleStep(EBookingTabState.TAB_MENU);
        if (booking.selectedShopLocation) {
            // getSpecialists();
            // getServices();
            listProducts();
        }
    }, []);

    useEffect(() => {
        if (booking.step !== EBookingTabState.FINALIZE_BOOKING) {
            if (booking.selectedShopLocation) {
                getSpecialists();
                getServices();
            }
        }
    }, [booking.selectedSpecialist, booking.selectedBookingDate, booking.selectedBookingTime, booking.selectedServices]);

    const handleBackToBookingStep = () => {
        let step = BookingTABS.TAB_MENU;
        if (productList.length > 0) {
            if (booking.isModifyingAppointment && booking.oldPaymentOption === "pay_now") {
                step = BookingTABS.TAB_MENU;
            } else {
                step = BookingTABS.PRODUCT_BOOKING;
            }
        }
        navigate(`${PATH.APPOINTEMENT}/${step}`);
    };

    useEffect(() => {
        if (booking.selectedShopLocation) {
            switch (currentTab) {
                case BookingTABS.SELECT_SPECIALIST:
                    if (booking.isModifyingAppointment) {
                        navigate(PATH.APPOINTEMENT);
                    } else {
                        setActiveTab(<SelectSpecialists />);
                    }
                    break;
                case BookingTABS.SELECT_SERVICE:
                    if (booking.isModifyingAppointment && booking?.oldPaymentOption === "pay_now") {
                        navigate(PATH.APPOINTEMENT);
                    } else {
                        setActiveTab(<SelectServices />);
                    }
                    break;
                case BookingTABS.SELECT_DATE_TIME:
                    setActiveTab(<SelectDateAndTime />);
                    break;
                case BookingTABS.PRODUCT_BOOKING:
                    if (booking.isModifyingAppointment && booking?.oldPaymentOption === "pay_now") {
                        navigate(PATH.APPOINTEMENT);
                    } else if (booking.selectedShopLocation && booking.selectedBookingDate && booking.selectedBookingTime && booking.selectedServices.length && booking.selectedSpecialist) {
                        setActiveTab(<ProductBooking />);
                    } else {
                        navigate(PATH.APPOINTEMENT);
                    }
                    break;
                case BookingTABS.FINALIZE_BOOKING:
                    if (booking.isModifyingAppointment && booking?.oldPaymentOption === "pay_now") {
                        navigate(PATH.APPOINTEMENT);
                    } else if (booking.selectedShopLocation && booking.selectedBookingDate && booking.selectedBookingTime && booking.selectedServices.length && booking.selectedSpecialist) {
                        setActiveTab(<FinalizeBooking handleBackToBooking={handleBackToBookingStep} />);
                    } else {
                        navigateBasedOnShopLocations();
                    }
                    break;
                default:
                    setActiveTab(<BookAppointmentTabMenu isLoading={isLoading} count={count} />);
                    break;
            }
        } else {
            navigateBasedOnShopLocations();
        }
    }, [currentTab, count]);

    const listProducts = () => {
        const payload = {
            params: {
                shop_id: shop.id,
                location_id: booking.selectedShopLocation?.id,
            },
            data: {
                booking_id: booking.modifyingAppointmentId || null,
            },
        };
        dispatch(getAllShopProducts(payload));
    };

    const getSpecialists = async () => {
        setIsLoading((prev) => ({ ...prev, specialist: true }));
        const payloadServiceIds = booking.selectedServices.length ? booking.selectedServices?.map((service: ISpecialistService) => service.id) : undefined;
        const payloadData = {
            service_id: payloadServiceIds,
            booking_date: booking.selectedBookingDate,
            booking_time: booking.selectedBookingTime ? `${booking.selectedBookingTime}:00` : undefined,
        };
        const payloadObj = {
            shop_id: shop.id,
            location_id: booking.selectedShopLocation?.id,
        };
        axiosPost(API.STAFF.LIST, payloadData, payloadObj)
            .then(async (response) => {
                setCount((prev: any) => ({ ...prev, specialist: response.data.data.length }));
                return;
            })
            .catch((error) => {
                toast.error(error?.message ?? "Something went wrong, Please try again");
            })
            .finally(() => {
                setIsLoading((prev) => ({ ...prev, specialist: false }));
            });
    };

    const getServices = async () => {
        setIsLoading((prev) => ({ ...prev, service: true }));
        const payloadData = {
            staff_id: booking.selectedSpecialist?.id,
            booking_date: booking.selectedBookingTime ? booking.selectedBookingDate : undefined,
            booking_time: booking.selectedBookingTime ? `${booking.selectedBookingTime}:00` : undefined,
            booking_id: booking.isModifyingAppointment ? booking.modifyingAppointmentId : null,
        };
        const urlParamObj = {
            shop_id: shop.id,
            location_id: booking.selectedShopLocation?.id,
        };
        axiosPost(API.SERVICE.LIST, payloadData, urlParamObj)
            .then(async (response) => {
                setCount((prev: any) => ({ ...prev, service: response.data.data.length }));
                return;
            })
            .catch((error) => {
                toast.error(error?.message ?? "Something went wrong, Please try again");
            })
            .finally(() => {
                setIsLoading((prev) => ({ ...prev, service: false }));
            });
    };

    const handleStep = (step: EBookingTabState) => {
        dispatch(setStep(step));
    };

    return (
        <div className={`p-2 lg:p-8 bg-mainBackgroundColor h-full flex-1 overflow-hidden flex flex-col`}>
            <div
                className={`rounded-[16px] lg:rounded-3xl bg-containerBgColor shadow-[0px_5.33px_16px_0px_#928C971A] p-4 max-lg:pb-3 lg:px-8 lg:py-8 text-secondary-appointment font-primary flex flex-col lg:flex-row flex-1 scrollbar-hide md:overflow-hidden overflow-hidden h-full`}
            >
                <div className="lg:w-[52%] xl:w-[60%] 2xl:flex-1 flex flex-col relative h-full pr-0 lg:pr-[40px] max-lg:-mx-4 -mx-2  lg:pt-6 overflow-hidden">
                    <div className="flex flex-col flex-1 relative  overflow-hidden max-lg:pt-[16px] h-full">
                        {/* {!(
                            uiState.isMobile &&
                            (booking.step === EBookingTabState.SELECT_DATE_TIME || booking.step === EBookingTabState.SELECT_SERVICE || booking.step === EBookingTabState.SELECT_SPECIALIST)
                        ) && (
                            <div className="flex flex-col relative mb-3 lg:mb-0 max-lg:mx-2 lg:px-2">
                                <Stepper />
                            </div>
                        )} */}
                        {booking.step !== EBookingTabState.SELECT_DATE_TIME && booking.step !== EBookingTabState.SELECT_SERVICE && booking.step !== EBookingTabState.SELECT_SPECIALIST && (
                            <div className="flex flex-col relative mb-6 lg:mb-0 max-lg:mx-4 lg:px-2 shrink-0">
                                <Stepper />
                            </div>
                        )}
                        {booking.step === EBookingTabState.TAB_MENU && (
                            <div className="p-3 bg-appointmentBgColor border border-borderColorPrimary flex items-center rounded-secondary lg:hidden max-lg:mb-4 mx-4 shrink-0">
                                <img
                                    className="rounded-[6px] aspect-square w-[60px] h-[60px] min-w-[60px] mr-3 object-cover"
                                    src={booking.selectedShopLocation?.location_image_url}
                                    alt=""
                                    onError={onError("Location")}
                                />
                                <div className="flex flex-col">
                                    <h1 className="leading-[22.4px] text-base font-normal mb-[3px] text-txtcolor">{booking.selectedShopLocation?.name}</h1>
                                    <p className="leading-[18.2px] text-sm text-textGrey font-normal max-w-full">
                                        {`${booking.selectedShopLocation?.street}, ${booking.selectedShopLocation?.city}, ${booking.selectedShopLocation?.country} ${booking.selectedShopLocation?.postal_code}`}
                                    </p>
                                </div>
                            </div>
                        )}
                        {activeTab}
                    </div>
                    <div className="w-full pt-2 px-2 pl-2.5 hidden lg:block bg-containerBgColor z-10">
                        <p className="text-sm leading-[19.6px] font-normal -tracking-[0.03rem] text-secondaryTxt flex items-center gap-[4px]">
                            <span>{t("powered by")}</span>{" "}
                            <Link className="text-primaryAppointment" target="_blank" to={"https://www.getflair.ca/"}>
                                {t("getflair.ca")}
                            </Link>
                        </p>
                    </div>
                </div>
                <animated.div
                    className="hidden relative lg:block h-full lg:flex-1 2xl:flex-none overflow-hidden px-6 md:px-[30px] lg:px-0 lg:w-[400px] xl:w-[480px] 2xl:w-[480px] 3xl:w-[550px] rounded-secondary  sidebar-shadow"
                    style={previewAnimationProps}
                >
                    <AppointmentPreview />
                </animated.div>
            </div>
        </div>
    );
};

export default BookAppointment;
