import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomButton from "src/components/CustomButton";
import { getShortName } from "src/utils/global-functions";
import { InfoCircle, User01 } from "@untitled-ui/icons-react/build/cjs";
import Tooltip from "src/components/Tooltip/Tooltip";
import FloatingInputbox from "src/components/FloatingInputbox";
import MPhoneOrEmail from "src/components/PhoneOrEmail/MPhoneOrEmail";

const MSocialProfile = ({ errorImage, cropData, onErrorImage, referralConfig, onChangeCrop, isLoading }: any) => {
    const { t } = useTranslation();
    const { control, watch, setValue } = useFormContext();
    const firstName = watch("first_name");
    const lastName = watch("last_name");
    return (
        <div className={`flex flex-col px-4 mobile-model-inner`}>
            <div className="flex flex-col gap-4">
                <Controller
                    name="profile_image_name"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <div className="w-full flex flex-col">
                            <div className="flex flex-row gap-4 lg:gap-5 relative">
                                {!errorImage.profile && cropData ? (
                                    <img src={cropData} alt="" onError={onErrorImage("profile")} className="w-16 h-16 border-[0.75px] border-uploadProfileBg rounded-lg object-cover" />
                                ) : firstName && lastName ? (
                                    <div className="rounded-lg bg-uploadProfileBg flex justify-center items-center  text-primaryAppointment font-medium h-[64px] w-[64px] ">
                                        <span className="text-[24px] leading-[36px] font-medium">{getShortName(`${firstName} ${lastName}`)}</span>
                                    </div>
                                ) : (
                                    <div className="rounded-lg bg-uploadProfileBg flex justify-center items-center  text-primaryAppointment font-medium h-[64px] w-[64px] ">
                                        <span className="text-[24px] leading-[36px] font-medium">
                                            <User01 className="text-[#8C9CBB] w-5 h-5" />
                                        </span>
                                    </div>
                                )}

                                <div
                                    className={`rounded-lg border border-uploadProfileBorder bg-uploadInputBg hover:shadow shadow-sm cursor-pointer flex-auto items-center justify-center py-[11px] px-0 `}
                                >
                                    <div className="relative overflow-hidden cursor-pointer">
                                        <input type="file" id="image" className=" absolute opacity-0 rounded-full h-[64px] w-[64px]" onChange={onChangeCrop} />
                                        <label htmlFor="image" className="cursor-pointer text-center ">
                                            <div className="text-textGrey text-[14px] leading-[20px] font-normal">
                                                <p className="mb-1 flex items-center justify-center">
                                                    <span className="text-primary text-[14px] leading-[20px] font-semibold pr-1">{t("Click to upload")}</span>
                                                    <span className=" text-textGrey text-[14px] leading-[20px] font-normal">{t("or drag and drop")}</span>
                                                </p>
                                                <p className="text-[12px] text-textGrey font-normal leading-[18px]">{"PNG or JPG file"}</p>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {error && <p className="text-error">{error.message}</p>}
                        </div>
                    )}
                />
                <Controller
                    name="first_name"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <div className="w-full flex flex-col">
                            <FloatingInputbox label={t("First Name")} required type="text" handleChange={onChange} value={value} name="first_name" error={error} />
                            {error && <p className="text-error">{error.message}</p>}
                        </div>
                    )}
                />
                <Controller
                    name="last_name"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <div className="w-full flex flex-col">
                            <FloatingInputbox label={t("Last Name")} required type="text" handleChange={onChange} value={value} name="last_name" error={error} />
                            {error && <p className="text-error">{error.message}</p>}
                        </div>
                    )}
                />
                <Controller
                    name="phone"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                        <div className="w-full flex flex-col">
                            <MPhoneOrEmail
                                singleType="phone"
                                inputType="phone"
                                borderClass="!h-[44px]"
                                onNumberChange={({ data: { country, code, number } }: any) => {
                                    setValue("phone", `+${code}${number}`);
                                    setValue("phone_country_code", country);
                                }}
                                errors={error}
                            />
                        </div>
                    )}
                />

                {referralConfig.status && (
                    <Controller
                        name="referral_code"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <div className="md:w-full flex flex-col">
                                <FloatingInputbox
                                    buttonIconRight={
                                        <div className="flex flex-row items-center">
                                            <Tooltip
                                                position="top"
                                                description={t("Get 0% off any service when you refer a friend!", {
                                                    value: referralConfig.refferer_type === "percentage" ? `${referralConfig.refferer}%` : `$${referralConfig.refferer}`,
                                                })}
                                            >
                                                <InfoCircle className="text-textGrey cursor-pointer w-[16px]" />
                                            </Tooltip>
                                        </div>
                                    }
                                    label={t("Referral code")}
                                    type="text"
                                    handleChange={(e) => onChange(e.target.value.toUpperCase())}
                                    value={value ?? ""}
                                    name="referral_code"
                                    error={error}
                                />
                                {error && <p className="text-error">{error.message}</p>}
                                <p className="text-[#475467] text-[12px] font-normal leading-[20px] mt-1.5">
                                    {t("Enter a referral code to receive")}
                                    &nbsp;
                                    <span className="font-semibold text-[#475467]">{referralConfig.referee_type === "percentage" ? `${referralConfig.referee}%` : `$${referralConfig.referee}`}</span>
                                    &nbsp;
                                    {t("your first appointment")}
                                </p>
                            </div>
                        )}
                    />
                )}
            </div>
            <div className="pt-5">
                <p className="text-txtcolor text-[12px] leading-[20px] font-normal text-left ">
                    {t("By signing up, you agree to the")}&nbsp;
                    <a href="https://www.getflair.ca/terms-of-service" target="_blank" className="text-primary font-semibold">
                        {t("Terms of Service")}
                    </a>
                    &nbsp;{t("and")}&nbsp;
                    <a href="https://www.getflair.ca/privacy-policy" target="_blank" className="text-primary font-semibold">
                        {t("Privacy Policy, ")}
                    </a>
                    &nbsp;
                    {t("and consent to receive personalized offers and update.")}
                </p>
                <div className="flex flex-col mt-[20px] max-md:mb-[20px]">
                    <CustomButton primary type="submit" size="w-full" className="!h-[48px] " isLoading={isLoading} disabled={isLoading}>
                        {t("Continue")}
                    </CustomButton>
                </div>
            </div>
        </div>
    );
};

export default MSocialProfile;
