import React, { useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.module.css";
import { format } from "date-fns";
import { CalendarDate, Clock } from "@untitled-ui/icons-react/build/cjs";
import { IDateSelectProps } from "./dateSelect.interface";

const DateSelect = React.forwardRef(
    (
        {
            inputName,
            placeholder,
            dateFormat,
            className,
            onKeyDown,
            required,
            label,
            labelClassName,
            isFormate = true,
            tabIndex,
            onChangeValue,
            value,
            disabled = false,
            labelEndIcon,
            labelIconClassName,
            InputClass,
            readOnly = false,
            isTime = false,
            timeIntervals = 15,
            timeCaption = "Time",
            isRange = false,
            startDate = null,
            endDate = null,
            isMobileView = false,
            ...props
        }: IDateSelectProps,
        ref: React.ForwardedRef<any>,
    ) => {
        const datePickerRef = useRef<DatePicker | null>(null);
        const [startDateState, setStartDateState] = useState(startDate);
        const [endDateState, setEndDateState] = useState(endDate);

        const onChangeDate = (date: any) => {
            console.log(date, "date");

            if (isRange) {
                const [start, end] = date;
                setStartDateState(start);
                setEndDateState(end);
                onChangeValue([start, end]);
                return;
            } else if (date) {
                if (isFormate) {
                    const formattedDate = format(date, dateFormat);
                    onChangeValue(formattedDate);
                } else {
                    onChangeValue(date);
                }
            }
        };
        React.useImperativeHandle(ref, () => ({
            setOpen: (isOpen: boolean) => {
                if (datePickerRef.current) {
                    datePickerRef.current.setOpen(isOpen);
                }
            },
        }));
        return (
            <>
                {/* {label && <label className={`fl-field-title-label ${labelClassName}`}>{label}</label>}
                {required && <span className="text-red-600">*</span>} */}
                {label && (
                    <div className="flex items-center mb-1.5">
                        <label htmlFor={""} className={`text-txtAppointmentColor text-sm font-normal leading-[18.2px] -tracking-[0.01rem] block ${labelClassName}`}>
                            {label}
                            {required && <span className="text-red-600">*</span>}
                        </label>
                    </div>
                )}
                <div
                    className={`${
                        isMobileView
                            ? "flex flex-col bg-transparent rounded-secondary w-full relative shadow-[0px_1px_2px_0px_#1018280D] h-[54px] border border-borderCounter px-3.5 items-center justify-center"
                            : "fl-input-field fl-input-field fl-phone-field !h-[44px] gap-2 rounded-secondary border border-borderColorSecondary form_input_control shadow-InputAndButton relative px-3.5 w-full"
                    }  ${InputClass}`}
                >
                    <div className={`${labelIconClassName} ${isMobileView ? "absolute top-1/2 -translate-y-1/2 right-3.5" : "absolute top-1/2 -translate-y-1/2 right-3.5"}`}>
                        <span className="text">{isTime ? <Clock className="text-textGrey w-[18px] h-[18px]" /> : <CalendarDate className="text-textGrey w-[18px] h-[18px]" />}</span>
                    </div>

                    <DatePicker
                        ref={datePickerRef}
                        placeholderText={placeholder}
                        onChange={onChangeDate}
                        className={`!border-0 focus:ring-0 px-0 !focus:outline-none outline-none  text-txtAppointmentColor -tracking-[0.01rem] placeholder:-tracking-[-0.01rem] h-full  ${className} ${
                            isMobileView
                                ? "placeholder:text-textGrey placeholder:text-[14px] bg-transparent text-[14px] font-normal text-txtAppointmentColor placeholder:font-normal"
                                : "placeholder:text-textGrey placeholder:text-[14px] bg-transparent text-[14px] font-normal text-txtAppointmentColor placeholder:font-normal"
                        }`}
                        tabIndex={tabIndex}
                        id={props.id}
                        onKeyDown={(e) => {
                            if (e?.keyCode === 9 || e?.which === 9) {
                                if (datePickerRef.current) {
                                    datePickerRef.current.setOpen(false);
                                }
                            }
                        }}
                        selected={value && !isNaN(new Date(value).getTime()) ? new Date(value) : null}
                        // selected={value ? new Date(value) : null}
                        value={value}
                        autoComplete="off"
                        name={inputName}
                        dropdownMode="select"
                        popperPlacement="top-end"
                        {...props}
                        showYearDropdown
                        showMonthDropdown
                        disabled={disabled}
                        readOnly={readOnly}
                        showTimeSelect={isTime}
                        showTimeSelectOnly={isTime}
                        timeIntervals={timeIntervals}
                        timeCaption={isTime ? timeCaption : ""}
                        dateFormat={dateFormat}
                        startDate={startDateState}
                        endDate={endDateState}
                        selectsRange={isRange}
                    />
                    {labelEndIcon && (
                        <div
                            className="input-group-append"
                            onClick={() => {
                                if (datePickerRef.current) {
                                    datePickerRef.current.setOpen(true);
                                }
                            }}
                        >
                            <span className="text">{labelEndIcon}</span>
                        </div>
                    )}
                </div>
            </>
        );
    },
);

export default DateSelect;
