import React, { FC } from "react";
import { FaInstagram } from "react-icons/fa6";
import { formatInstagramLink, onError } from "src/utils/global-functions";
import { IProfessionalList } from "./Professional.interface";

const ProfessionalList: FC<IProfessionalList> = ({ prop }) => (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 lg:gap-6 2xl:gap-8 text-TextColor">
        {prop.map((propData, index) => (
            <div key={index} className="flex flex-col w-full bg-templateCardBg">
                <p className="font-medium text-[18px] leading-[27px] tracking-[-0.01rem] uppercase p-3 text-TextColor">{propData.name}</p>
                <img src={propData.image_url} alt="" className="aspect-[1/1] object-cover" onError={onError("Professional")} />
                <div className="w-[-webkit-fill-available] relative p-3 text-TextColor">
                    <h3 className="leading-[19.36px] text-[16px] font-normal text-TextColor">{propData?.role}</h3>
                    {propData?.is_instgram_button && (
                        <a
                            href={`${formatInstagramLink(propData?.instgram_button)}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-[16px] mt-1 font-normal flex flex-row gap-1.5 items-center leading-[24px] tracking-[-0.01rem] text-TextColor"
                        >
                            <FaInstagram size={20} className="cursor-pointer" color="text-templateCardBg mt-[1.5px]" /> @{propData?.instgram_button ? propData?.instgram_button : ""}
                        </a>
                    )}
                </div>
            </div>
        ))}
    </div>
);

export default ProfessionalList;
