import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { DefaultUserImagePNG, UnionSVG } from "src/theme/Images";
import { useTranslation } from "react-i18next";
import Profile from "../Profile";
import { useLocation } from "react-router-dom";
import { setIsShowLoginModal } from "src/redux/reducers/Login.slice";
import { IInitAction, initAction } from "../Landing/Template1/Header/Header.interface";
import CompleteProfile from "src/app/Auth/CompleteProfile/CompleteProfile";
import Auth from "../Auth";
import { currentShop, currentUser } from "src/redux/reducers/common/Common.slice";
import { Menu02, XClose } from "@untitled-ui/icons-react/build/cjs";
import CustomButton from "src/components/CustomButton";
import ConditionalNoShowView from "../BookAppointment/ConditionalNoShowView";
import { isGetApp } from "src/redux/reducers/Landing.slice";

const CommonHeader: React.FC<any> = () => {
    const AuthUserState = useAppSelector((data) => data.AuthUser);
    const shop = useAppSelector(currentShop);
    const user = useAppSelector(currentUser);
    const [action, setAction] = useState<IInitAction>(initAction);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const [nav, setNav] = useState(false);
    const isGetAppVisible = useAppSelector(isGetApp);

    useEffect(() => {
        if (user) {
            const isAccountComplete = user.is_account_complete;
            if (!isAccountComplete) {
                handleAction("completeProfile")();
            }
        }
    }, [user]);

    const handleAction = (type: keyof IInitAction) => () => {
        if (nav) {
            handleNav();
        }
        setAction(() => ({ ...initAction, [type]: true }));
    };

    const handleLogin = () => {
        if (nav) {
            handleNav();
        }
        dispatch(setIsShowLoginModal(true));
    };

    const handleClose = () => {
        setAction(initAction);
    };

    const handleNav = () => {
        setNav(!nav);
    };

    return (
        <div
            className={`flex justify-between border-b border-borderColorPrimary items-center px-5 ${isGetAppVisible ? "" : "getAppHeader"} ${
                location.pathname === "/appointment" ? "lg:px-8" : "md:px-[32px]"
            } h-[52px] lg:h-[60px] w-full z-[9999] bg-contentBackground top-0 sticky `}
        >
            <div className={"items-center cursor-pointer flex w-full justify-between"}>
                <div onClick={() => navigate("/")} className="flex items-center">
                    {shop && <img src={shop?.logo_image_url ?? UnionSVG} className="max-w-48 max-h-[2.7rem] me-2" alt="" />}
                    {shop?.business_name && (
                        <p className="hover:text-primary font-custom cursor-pointer text-[14px] md:text-[14px] lg:text-[14px] 2xl:text-[16px] 3xl:text-[1vw] font-logo font-logoWeight">
                            {shop?.business_name}
                        </p>
                    )}
                </div>
                <div className="w-[35px] justify-end pl-2.5 ml-3 border-l md:hidden border-borderColorPrimary " onClick={handleNav}>
                    {nav ? <XClose color="var(--brand-color)" className="w-[24px]" /> : <Menu02 className="w-[24px]" color="var(--brand-color)" />}
                </div>
            </div>
            <ul
                className={
                    nav
                        ? `ease-in-out right-0 z-[99999] absolute h-[100vh] top-[48px] bg-contentBackground flex flex-col md:flex-row md:justify-between md:items-center md:h-[56px] 2xl:h-[56px] md:space-x-3 lg:space-x-6 md:top-0 xl:h-[56px] xl:space-x-7 duration-500 md:sticky pl-[12px] pt-[12px] md:pl-[0px] md:pt-[0px] justify-start navbar-duration items-start`
                        : `space-x-3 md:space-x-3 lg:space-x-6 xl:space-x-7 navbar-duration ease-in-out duration-500 hidden md:flex justify-end min-w-auto`
                }
            >
                {user ? (
                    <div className="flex gap-2">
                        <div onClick={handleAction("profile")} className="flex cursor-pointer items-center rounded-primary w-9 h-9">
                            <img src={user?.profile_image_url ?? DefaultUserImagePNG} className="w-full h-full rounded-primary" alt="" />
                        </div>
                    </div>
                ) : (
                    <CustomButton secondary size="w-full" className="btn-appointment-primary text-primary px-5 !py-[2px] !h-[38px] text-sm whitespace-nowrap" onClick={handleLogin}>
                        {t("Log in")}
                    </CustomButton>
                )}
                {AuthUserState.isShowLoginModal && <Auth />}
                {user && <ConditionalNoShowView />}
                <Profile handleClose={handleClose} isOpen={action.profile} />
                {action.completeProfile && <CompleteProfile handleClose={handleClose} />}
            </ul>
        </div>
    );
};

export default CommonHeader;
