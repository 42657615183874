import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { UnionSVG } from "src/theme/Images";
import { useTranslation } from "react-i18next";
import { ISelectedTab } from "../../../../Interface";
import { isGetApp, selectTab, setIsScrollable } from "../../../../redux/reducers/Landing.slice";
import Profile from "../../../Profile";
import { setIsShowLoginModal } from "src/redux/reducers/Login.slice";
import { IInitAction, initAction, navLinks } from "./Header.interface";
import CompleteProfile from "src/app/Auth/CompleteProfile/CompleteProfile";
import Auth from "src/app/Auth";
import { currentSettings, currentShop, currentUser, globalLanguage } from "src/redux/reducers/common/Common.slice";
import { Menu02, User01, XClose } from "@untitled-ui/icons-react/build/cjs";
import CustomButton from "src/components/CustomButton";
import SelectBox from "src/components/SelectBox";
import { useShopNavigation } from "src/hooks/useShopNavigation";
import ConditionalNoShowView from "src/app/BookAppointment/ConditionalNoShowView";
import { ROUTES } from "src/constants/routes";
import { useLanguage } from "src/hooks/useCommon";
import { onError } from "src/utils/global-functions";
import RadioSwitch from "src/components/RadioSwitch";

const Header: React.FC<any> = () => {
    const currentLanguage = useAppSelector(globalLanguage);
    const AuthUserState = useAppSelector((data) => data.AuthUser);
    const user = useAppSelector(currentUser);
    const shop = useAppSelector(currentShop);
    const location = useLocation();
    const activeTab = useAppSelector((data) => data.LandingStates.initSelectedTab);
    const settings = useAppSelector(currentSettings);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [action, setAction] = useState<IInitAction>(initAction);
    const [nav, setNav] = useState(false);
    const [template, setTemplate] = useState<string[]>([]);
    const [appearance, setAppearance] = useState<any>(null);
    const { languageOptions, languages, handleLanguage, handleRadioChange } = useLanguage();
    const { navigateBasedOnShopLocations } = useShopNavigation();
    const isGetAppVisible = useAppSelector(isGetApp);

    useEffect(() => {
        if (settings.length) {
            const templates = settings.find((setting: any) => setting.type === "template");
            if (templates) {
                setTemplate(extractKeysWithStatusTrue(templates.value));
            }

            const appearances = settings.find((setting: any) => setting.type === "appearance");
            if (appearances) {
                setAppearance(appearances.value);
            }
        }
    }, [settings]);

    useEffect(() => {
        if (user) {
            const isAccountComplete = user.is_account_complete;
            if (!isAccountComplete) {
                handleAction("completeProfile")();
            }
        }
    }, [user]);

    const extractKeysWithStatusTrue = (obj: any) => {
        const result = [];
        for (const key in obj) {
            if (obj[key].status === true) {
                result.push(key);
            }
        }
        return result;
    };

    const handleNavigate = (tabName: ISelectedTab) => () => {
        dispatch(setIsScrollable(true));
        dispatch(selectTab(tabName));
    };

    const handleAction = (type: keyof IInitAction) => () => {
        if (nav) {
            handleNav();
        }
        setAction(() => ({ ...initAction, [type]: true }));
    };

    const handleLogin = () => {
        if (nav) {
            handleNav();
        }
        dispatch(setIsShowLoginModal(true));
    };

    const handleClose = () => {
        setAction(initAction);
    };

    const handleNav = () => {
        setNav(!nav);
    };

    const getLogo = () => {
        let response;
        let logo;
        let text;

        if (shop) {
            const logoHeight = appearance ? `${appearance?.logo_height}px` : "50px";
            logo = (
                <img
                    src={shop.logo_image_url ?? UnionSVG}
                    className="max-w-[120px] object-contain"
                    alt=""
                    style={{
                        height: logoHeight,
                        minHeight: logoHeight,
                        maxHeight: logoHeight,
                    }}
                />
            );
            text = (
                <p className="text-txtcolor hover:text-primary ml-2 cursor-pointer text-[14px] md:text-[14px] lg:text-[14px] 2xl:text-[16px] 3xl:text-[1vw] flex items-center font-logo font-logoWeight">
                    {shop.business_name}
                </p>
            );

            if (appearance) {
                switch (appearance.logo_type) {
                    case "logo":
                        response = logo;
                        break;
                    case "logo_text":
                        response = (
                            <>
                                {logo} {text}
                            </>
                        );
                        break;
                    case "text":
                        response = text;
                        break;
                    default:
                        response = logo;
                        break;
                }
            }
        }

        return response;
    };

    const handleBookNow = () => {
        if (nav) {
            handleNav();
        }
        navigateBasedOnShopLocations();
    };

    return (
        <div
            className={`flex justify-between  border-textGray ${isGetAppVisible ? "" : "getAppHeader"} ${
                location.pathname === ROUTES.HOME
                    ? "shadow-[0px_4px_4px_0px_#0000000D] bg-navbarBgColor lg:pl-[34px] lg:pr-[34px]"
                    : "lg:pl-[64px] lg:pr-[32px] shadow-[0px_4px_4px_0px_#0000000D] bg-navbarBgColor"
            } items-center px-4 sticky  min-h-[60px] h-[60px] lg:h-[60px] w-full z-[9999]  top-0 shrink-0`}
        >
            <div className="items-center cursor-pointer justify-between  w-full lg:w-auto flex">
                <div onClick={() => navigate("/")} className="flex items-center">
                    {getLogo()}
                </div>
                <div className="flex flex-row justify-end pl-2.5 ml-3  lg:hidden items-center">
                    {user ? (
                        <div onClick={handleAction("profile")} className="flex cursor-pointer items-center rounded-secondary w-[30px] h-[30px]">
                            {user?.profile_image_url ? (
                                <img src={user.profile_image_url} className="w-full h-full rounded-primary" alt="" />
                            ) : (
                                <div className="w-full h-full rounded-primary bg-primaryHover flex items-center justify-center">
                                    <User01 className="text-primary w-5 h-5" />
                                </div>
                            )}
                        </div>
                    ) : (
                        <CustomButton secondary size="w-full" className="btn-appointment-primary text-primary px-5 !py-[2px] !h-[32px] text-sm whitespace-nowrap" onClick={handleLogin}>
                            {t("Log in")}
                        </CustomButton>
                    )}
                    <div className="w-[35px] justify-end pl-2.5 ml-3 border-l items-center lg:hidden border-borderColorPrimary" onClick={handleNav}>
                        {nav ? <XClose color="var(--brand-color)" className="w-[24px]" /> : <Menu02 className="w-[24px]" color="var(--brand-color)" />}
                    </div>
                </div>
            </div>
            <ul
                className={
                    nav
                        ? `ease-in-out right-0 left-0 z-[99999] absolute  top-[60px] bg-navbarBgColor flex flex-col lg:flex-row lg:justify-between lg:items-center lg:h-[44px] 2xl:h-[44px] lg:top-0 xl:h-[45px] lg:space-y-0 lg:space-x-2 xls:space-x-3 xl:space-x-5  lg:sticky  lg:pr-0 pt-[24px] lg:pl-[0px] lg:pt-[0px] justify-start navbar-duration duration-1000 items-start  lg:border-l-0 border-borderColorPrimary *:w-full menu_wrapper `
                        : "  right-0 z-[99999] absolute menu_wrapper top-[45px] bg-navbarBgColor flex-col lg:flex-row lg:justify-between lg:items-center lg:h-[44px] 2xl:h-[44px] lg:top-0 xl:h-[45px] lg:sticky  lg:pl-[0px] lg:pt-[0px] min-w-[240px] justify-start navbar-duration space-x-3 lg:space-x-2 xls:space-x-3 xl:space-x-5 navbar-duration ease-in-out duration-500 hidden lg:flex items-center"
                }
            >
                {navLinks
                    .filter(({ tabName }) => template.includes(tabName))
                    .map(({ name, tabName }) => (
                        <li
                            key={tabName}
                            className={`hover:lg:text-primary max-lg:hover:bg-primaryHover capitalize lg:uppercase max-lg:rounded-[8px] font-normal cursor-pointer -tracking-[-0.01rem] whitespace-nowrap p-0 flex items-center max-lg:mb-[2px] max-lg:ml-[12px] max-lg:mr-[12px] max-lg:w-[calc(100%-24px)]  ${
                                activeTab[tabName] ? "lg:text-primary max-lg:bg-primaryHover max-lg:!font-medium" : "text-txtcolor"
                            }`}
                            onClick={handleNavigate(tabName)}
                        >
                            <span onClick={handleNav} className="max-lg:pl-[12px] text-[14px] flex items-center leading-[20px] max-lg:min-h-[36px] max-lg:w-full ">
                                {t(name)}
                            </span>
                        </li>
                    ))}
                {languages.length > 1 && (
                    <li className="max-lg:hidden">
                        <SelectBox
                            options={languageOptions}
                            value={languageOptions.find((lan: any) => lan.value === currentLanguage)}
                            onChange={handleLanguage}
                            inputClass="outline-select-box rounded-secondary navigation-select" // Change this to inputClass
                            allowClear={false}
                            searchable={false}
                            disabled={false}
                            name={"language"}
                        />
                    </li>
                )}
                {/* <li className="hover:text-primary font-normal cursor-pointer text-[14px] md:text-[14px] lg:text-[15px] 2xl:text-[15px] text-txtcolor ">
                    <span onClick={handleNav}>(ENG / FRA)</span>
                </li> */}
                {languages.length > 1 && <RadioSwitch options={languageOptions} name="about_language" value={currentLanguage} onChange={handleRadioChange} />}

                <li className="max-lg:mt-auto max-lg:border-t max-lg:p-4 max-lg:pb-2 max-lg:border-mainBackgroundColor max-lg:shadow-[0px_-4px_10px_0px_#0000000D] max-lg:w-full">
                    <CustomButton
                        primary
                        size="w-full"
                        className="bg-primary  flex items-center !px-3 !py-[2px] lg:!h-[30px] !text-[12px] rounded-secondary  whitespace-nowrap 
                        max-lg:w-full max-lg:!h-[36px] max-lg:rounded-[8px] max-lg:shadow-[0px_1px_2px_0px_#1018280D] max-lg:!text-[14px]"
                        onClick={handleBookNow}
                    >
                        {t("Book now")}
                    </CustomButton>
                </li>
                {user ? (
                    <li className="hidden lg:flex">
                        <div className="flex gap-2">
                            <div onClick={handleAction("profile")} className="flex cursor-pointer items-center rounded-secondary w-[30px] h-[30px]">
                                {user?.profile_image_url ? (
                                    <img src={user.profile_image_url} className="w-full h-full rounded-primary" alt="" onError={onError("Professional")} />
                                ) : (
                                    <div className="w-full h-full rounded-primary bg-primaryHover flex items-center justify-center">
                                        <User01 className="text-primary w-5 h-5" />
                                    </div>
                                )}
                            </div>
                        </div>
                    </li>
                ) : (
                    <li className="hidden lg:flex">
                        <CustomButton secondary size="w-full" className="btn-appointment-primary text-primary !px-3 !py-[2px] !h-[30px] !text-[12px] text-sm whitespace-nowrap" onClick={handleLogin}>
                            {t("Log in")}
                        </CustomButton>
                    </li>
                )}
                {AuthUserState.isShowLoginModal && <Auth />}
                {user && <ConditionalNoShowView />}
                <Profile handleClose={handleClose} isOpen={action.profile} />
                {action.completeProfile && <CompleteProfile handleClose={handleClose} />}
            </ul>
        </div>
    );
};

export default Header;
