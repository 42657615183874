import React, { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomButton from "src/components/CustomButton";
import WPhoneOrEmail from "src/components/PhoneOrEmail/WPhoneOrEmail";
import { AppleSVG, Facebook1SVG, GoogleSVG } from "src/theme/Images";
import { ILogin } from "../Login.interface";

const WLogin: FC<ILogin> = ({ onInputChange, isLoading, isButtonDisabled, handleLogin }) => {
    const { t } = useTranslation();
    const { control } = useFormContext();
    return (
        <div className="model-inner-wrapper font-primary">
            <span className="login-model-title">{t("Get Started")}</span>
            <p className="login-model-subtitle">{t("Enter your phone number or email address")}</p>
            <div className="mt-[30px]">
                <Controller
                    name="emailPhone"
                    control={control}
                    render={({ field: { onChange }, fieldState: { error } }: any) => (
                        <div className="flex flex-col w-full">
                            <label className="text-sm text-txtAppointmentColor leading-[18.2px] flex font-normal text-[14px] -tracking-[0.02rem] mb-1.5">
                                {t("Phone or Email")}
                                <span className="asterisk">*</span>
                            </label>
                            <WPhoneOrEmail
                                errors={error}
                                onNumberChange={(data: any) => {
                                    onChange(data);
                                    onInputChange(data);
                                }}
                                isFocus={true}
                            />
                        </div>
                    )}
                />

                <CustomButton primary isLoading={isLoading} disabled={isButtonDisabled || isLoading} className={`w-full mt-4 !h-[48px]`} type="submit">
                    <span>{t("Continue")}</span>
                </CustomButton>
            </div>
            <div className="flex items-center my-[30px] max-w-[200px] mx-auto">
                <hr className="border-spacing-2 border-borderColorPrimary opacity-70 w-full my-0" />
                <span className="px-4 font-normal text-[18px] -tracking-[0.03rem] leading-[23.4px] text-textGrey ">{t("or")}</span>
                <hr className="border-spacing-2 border-borderColorPrimary opacity-70 w-full my-0 " />
            </div>
            <div className="text-center">
                <CustomButton outlineSecondary icon={<GoogleSVG className="me-2 w-5" />} size="w-full" className="!font-semibold h-[44px] " onClick={handleLogin("google")}>
                    {t("Continue with Google")}
                </CustomButton>
                <CustomButton outlineSecondary icon={<AppleSVG className="me-2 w-5 text-txtcolor" />} size="w-full" className="!font-semibold h-[44px] mt-3" onClick={handleLogin("apple")}>
                    {t("Continue with Apple")}
                </CustomButton>
                <CustomButton outlineSecondary icon={<Facebook1SVG className="me-2 w-5" />} size="w-full" className="!font-semibold h-[44px] mt-3" onClick={handleLogin("facebook")}>
                    {t("Continue with Facebook")}
                </CustomButton>
            </div>
        </div>
    );
};

export default WLogin;
