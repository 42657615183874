import React, { FC, useState } from "react";
import { useForm, Controller, Resolver } from "react-hook-form";
import { IOtp, IProfileVerifyProps } from "../Auth.interface";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { errorCode } from "src/constants/common";
import { userLogin } from "src/redux/reducers/Login.slice";
import { useAppDispatch } from "src/redux/hooks";
import OTPField from "src/components/OTPField/OTPField";
import CustomButton from "src/components/CustomButton";
import { axiosPatch } from "src/utils/requestClient";
import { API } from "src/constants/api";
import { me } from "src/redux/reducers/common/Common.slice";
import { toast } from "react-toastify";
import { usePhone } from "src/hooks/usePhone";

const VerifyOtp: FC<IProfileVerifyProps> = ({ authData, handleAction, setAuthData, handleClose }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { getCustomFormatPhone } = usePhone();
    const [loading, setLoading] = useState(false);

    const schema = Yup.object({
        otp: Yup.string().length(4, t("OTP must be exactly 4 number")).required(t("This field is a required")),
    }).required();

    const { control, handleSubmit, setError, reset, watch } = useForm<IOtp>({
        resolver: yupResolver(schema) as Resolver<IOtp>,
        defaultValues: {
            otp: "",
        },
    });

    const otpWatch = watch("otp");

    const handleSave = (data: any) => {
        setLoading(true);
        const payload = {
            phone: authData.phone,
            phone_country_code: authData.phone_country_code,
            otp: data.otp,
        };
        axiosPatch(API.USER.SOCIAL_ACCOUNT_VERIFY, payload)
            .then(async () => {
                await dispatch(me());
                handleClose();
            })
            .catch((error) => {
                const response = error.response.data;
                if (response.status === errorCode.unprocessable) {
                    Object.keys(response.data).forEach((field) => {
                        setError("otp", {
                            type: "manual",
                            message: response.data[field][0],
                        });
                    });
                    return;
                }
                toast.error(response.message);
            })
            .finally(() => setLoading(false));
    };

    const handleResend = async () => {
        setLoading(true);
        reset();
        const payload = {
            phone: authData.phone,
            phone_country_code: authData.phone_country_code,
        };
        await dispatch(userLogin(payload));
        setLoading(false);
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
            e.preventDefault();
            handleSubmit(handleSave)();
        }
    };

    return (
        <div className="font-primary max-w-[400px] mx-auto lg:w-full" onKeyDown={handleKeyPress}>
            <h2 className="login-title-text ">{t("Enter the verification code")}</h2>
            <div className="login-subtitle-text flex flex-col mt-3 -tracking-[0.03rem]">
                <span>{t("We’ve sent a 4-digit code to your phone number")}</span>
                <span className="text-txtAppointmentColor font-normal text-base mt-1.5">{getCustomFormatPhone(authData.phone, authData.phone_country_code)}</span>
            </div>

            <div className="mx-auto mt-[40px] ">
                <form onSubmit={handleSubmit(handleSave)}>
                    <Controller
                        name="otp"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                            <div className="flex flex-col mt-8">
                                <OTPField otp={value} setOtp={onChange} errors={error} />
                                {/* {error && <p className="text-error text-center">{error.message}</p>} */}
                            </div>
                        )}
                    />
                    <div className="mt-6 flex flex-col items-center">
                        <div className="flex items-center">
                            <p className="text-textGrey font-medium text-sm leading-5 tracking-[0.02rem]">{t("Didn’t receive the code?")}?</p>
                            <CustomButton
                                type="button"
                                disabled={loading}
                                onClick={handleResend}
                                className="text-primary !border-0 !ml-2 !px-0 !bg-transparent text-center flex flex-col ring-0"
                                shadow={false}
                            >
                                {t("Resend")}
                            </CustomButton>
                        </div>
                        {authData.phone && (
                            <CustomButton
                                type="button"
                                className="!border-0 !bg-transparent text-center ring-0 leading-[19.6px] text-sm font-semibold mt-2 text-textGrey"
                                shadow={false}
                                onClick={handleAction("socialProfile")}
                            >
                                {t("Changed your phone number?")}
                            </CustomButton>
                        )}
                    </div>
                    <div className="mt-[52px] w-full bottom-0">
                        <CustomButton type="submit" className="w-full !h-[48px]" primary disabled={loading || (otpWatch && otpWatch.length !== 4 ? true : false)} isLoading={loading}>
                            <span> {t("Continue")}</span>
                        </CustomButton>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default VerifyOtp;
