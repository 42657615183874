import React, { useState } from "react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import StepProgressBar from "./StepProgressBar";
import CustomButton from "src/components/CustomButton";
import { useTranslation } from "react-i18next";
interface IDrawerProps {
    handleClose: () => void;
}

const DownloadApp = ({ handleClose }: IDrawerProps) => {
    const { t } = useTranslation();
    const [activeStep, setActiveStep] = useState<number>(1);
    const totalSteps = 6;
    const handleActiveStep = () => {
        if (totalSteps === activeStep) {
            handleClose();
        } else {
            setActiveStep(activeStep + 1);
        }
    };
    const renderStepComponent = () => {
        switch (activeStep) {
            case 1:
                return <Step1 />;
            case 2:
                return <Step2 />;
            case 3:
                return <Step3 />;
            case 4:
                return <Step4 />;
            case 5:
                return <Step5 />;
            case 6:
                return <Step6 />;
            default:
                break;
        }
    };
    // return <>{renderStepComponent()}</>;
    return (
        <div className="w-full font-primary px-4 pt-[20px] pb-[20px] flex flex-col max-w-[355px] mx-auto bg-cardBackgroundColor shadow-[0px_2px_6px_0px_#0000000D]">
            <div className="flex flex-col">
                <h3 className="text-txtAppointmentColor font-medium text-[22px] leading-[28.6px] tracking-[-0.02rem] mb-1.5">{t("Get the app")}</h3>
                <p className="text-textGrey text-[16px] leading-[22.4px] tracking-[-0.01rem]">{t("Save FLAIR Studios as a mobile app")}</p>
                <div className="pt-3 pb-5">
                    <StepProgressBar steps={Array.from({ length: totalSteps }, (_, i) => i + 1)} currentStep={activeStep} />
                </div>
            </div>
            {renderStepComponent()}
            <div className="items-center w-full mt-[18px]">
                <CustomButton primary onClick={handleActiveStep} className={`w-full`} type="submit">
                    {totalSteps === activeStep ? t("Close") : t("Next")}
                </CustomButton>
            </div>
        </div>
    );
};

export default DownloadApp;
