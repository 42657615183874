import { isError } from "lodash";
import React, { ReactElement } from "react";

export interface IPops {
    disabled?: boolean;
    handleChange: (e: React.ChangeEvent<HTMLInputElement>, index?: number) => void;
    value: string;
    name: string;
    placeholder?: string;
    label?: string;
    type?: string;
    index?: number;
    handlerIconClick?: () => void;
    buttonIcon?: ReactElement | null;
    buttonIconRight?: ReactElement | null;
    inputClass?: string;
    min?: number;
    borderClass?: string;
    required?: boolean;
    error?: boolean;
}

const InputWithLabel: React.FC<IPops> = ({
    disabled = false,
    handleChange,
    value,
    name,
    placeholder,
    label,
    type,
    index,
    handlerIconClick,
    buttonIcon,
    borderClass,
    inputClass,
    buttonIconRight,
    min,
    required,
    error,
}) => (
    <div className="flex-col form_group w-full">
        {label && (
            <label className="text-sm text-txtAppointmentColor leading-[18.2px] flex font-normal text-[14px] -tracking-[0.02rem] mb-1.5">
                {label}
                {required && <span className="asterisk">*</span>}
            </label>
        )}
        <div className={`fl-input-field fl-phone-field !h-[44px] rounded-secondary form_input_control relative ${borderClass} ${error ? "!is-invalid" : "border border-borderColorSecondary"}`}>
            <input
                className={`rounded-secondary w-full py-0 !border-0 text-base focus:ring-0 !focus:outline-none outline-none font-normal placeholder:text-textGrey placeholder:text-base bg-transparent placeholder:font-normal text-txtAppointmentColor -tracking-[0.03em] placeholder:-tracking-[0.03em] h-[-webkit-fill-available] ${inputClass}`}
                type={type || "text"}
                name={name}
                value={value}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, index)}
                placeholder={placeholder}
                disabled={disabled}
                min={min}
            />
            {buttonIcon && (
                <span onClick={handlerIconClick ?? undefined} className="cursor-pointer absolute top-1/2 -translate-y-1/2 left-3 ">
                    {buttonIcon}
                </span>
            )}
            {buttonIconRight && (
                <span onClick={handlerIconClick ?? undefined} className="text-secondaryTxt  cursor-pointer">
                    {buttonIconRight}
                </span>
            )}
        </div>
    </div>
);

export default InputWithLabel;
