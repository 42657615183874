import React, { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import InputWithLabel from "../../../components/InputWithLabel";
import { BiGift } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { getBookingInfo, setSelectedPromocode } from "src/app/BookAppointment/Booking.slice";
import { axiosGet } from "src/utils/requestClient";
import { API } from "src/constants/api";
import { currentShop } from "src/redux/reducers/common/Common.slice";
import { SearchLg, SearchSm, X } from "@untitled-ui/icons-react/build/cjs";

interface IProps {
    handleClose: () => void;
}

const Promotion: FC<IProps> = ({ handleClose }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const shop = useAppSelector(currentShop);
    const booking = useAppSelector(getBookingInfo);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [promotions, setPromotions] = useState([]);
    const [filterPromotions, setFilterPromotions] = useState([]);

    useEffect(() => {
        listPromotions();
    }, []);

    useEffect(() => {
        if (search) {
            const filteredPromotions = promotions.filter((promotion: any) => promotion.name.toLowerCase().includes(search.toLowerCase()));
            setFilterPromotions(filteredPromotions);
        } else {
            setFilterPromotions(promotions);
        }
    }, [search, promotions]);

    const listPromotions = () => {
        setIsLoading(true);
        const params = {
            shop_id: shop.id,
        };
        axiosGet(API.PROMOTION.LIST, {}, params)
            .then((response) => {
                const data = response.data.data;
                setPromotions(data);
            })
            .finally(() => setIsLoading(false));
    };

    const formatExpiryDate = (expiry_date: string | null | undefined): string => {
        if (!expiry_date) {
            return "";
        }
        const formattedDate = new Date(expiry_date).toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        });

        return formattedDate;
    };

    const handleApply = (id: any) => () => {
        dispatch(setSelectedPromocode(id));
        handleClose();
    };
    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSearch(value);
    };

    return (
        <div className="flex flex-col font-primary flex-1 tracking-[0.03rem] ">
            <div className="flex flex-col mt-1 lg:mt-[20px] mb-[30px]">
                <h3 className="text-[16px] lg:text-[20px] mb-1 leading-[22.4px] lg:leading-[28.4px] font-bold lg:font-semibold text-txtAppointmentColor -tracking-[0.01rem] capitalize">
                    {t("Promotions available")}
                </h3>
                <span className="font-normal text-[12px] lg:text-sm leading-[18.2px] text-textGrey -tracking-[0.03rem]">{t("Choose a promotion for this appointment")}</span>
            </div>
            <InputWithLabel
                name="search"
                placeholder={t("Search")}
                inputClass="pl-[41px] pt-0.5"
                value={search}
                borderClass="border border-borderCounter !h-[44px]"
                buttonIcon={<SearchLg className="w-[18px] text-textGrey h-[18px]" />}
                buttonIconRight={search ? <X className="text-textGrey w-5 absolute top-1/2 -translate-y-1/2 right-4" /> : null}
                handlerIconClick={() => setSearch("")}
                handleChange={handleSearch}
            />
            {filterPromotions.length > 0 ? (
                filterPromotions.map((promotion: any) => (
                    <div className=" border border-lightgray py-3 md:py-3 xl:py-3 pl-3 pr-3 flex gap-5 items-center rounded-xl relative h-[64px] mt-3" key={promotion.code}>
                        <div className="flex gap-3 items-center w-full justify-between">
                            <div className=" flex flex-1 flex-row items-center gap-5 ">
                                <div className="p-[5px] lg:p-[5px] xl:p-[5px] 2xl:p-[5px] border custom-icon-border rounded-lg w-[46px] h-[46px] lg:w-9 lg:h-9 xl:w-10 xl:h-10 2xl:w-[40px] 2xl:h-[40px] flex justify-center items-center bg-disableLightGreyButton">
                                    <BiGift fill="var(--brand-color)" size={20} height={20} />
                                </div>
                                <div className="flex flex-col">
                                    <h2 className="  text-sm !leading-[22.4px]   text-txtAppointmentColor font-medium  align-middle">
                                        {promotion.name} - {promotion.type === "percentage" ? `${promotion.value}%` : `$${promotion.value}`}
                                    </h2>
                                    {promotion.expiry_date && <span className="text-xs font-medium text-textGrey tracking-[0.03rem]">Exp: {formatExpiryDate(promotion.expiry_date)} </span>}
                                </div>
                            </div>
                            <div className="mr-0 float-right">
                                <div className="flex justify-center items-center border h-[36px] border-primaryAppointment px-4 transition-all ease-in-out delay-100 font-semibold text-base bg-transparent text-primaryAppointment rounded-lg">
                                    <button
                                        className="font-semibold text-sm bg-transparent text-primaryAppointment pt-[2px]"
                                        type="button"
                                        onClick={handleApply(promotion)}
                                        disabled={booking?.selectedPromocode?.id === promotion.id}
                                    >
                                        {booking?.selectedPromocode?.id === promotion.id ? t("Selected") : t("Apply")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                <div className="h-full w-full flex-1 text-base flex justify-center items-center text-textGrey tracking-[0.03rem]">{isLoading ? t("Loading") : t("No promotions available")}</div>
            )}
        </div>
    );
};

export default Promotion;
