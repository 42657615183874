import React, { useState } from "react";
import Info from "./Info";
import { useLocalizedSetting } from "src/hooks/useLocalizedSetting";

interface Props {
    data: any;
}
const Service: React.FC<Props> = ({ data }) => {
    const { getLocalizedSetting } = useLocalizedSetting();
    const [isCollapsed, setIsCollapsed] = useState<any>({ id: 0, isShow: false });
    const title = getLocalizedSetting(data, "title");

    return (
        <div className="w-full pt-9 md:spacing-top">
            {data?.is_title && <h2 className="custom-heading uppercase pb-5 md:pb-[54px]">{title}</h2>}
            <div className="flex flex-col">
                {data?.services?.map((service: any, index: number) => (
                    <Info index={index} service={service} key={service.id} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
                ))}
            </div>
        </div>
    );
};

export default Service;
