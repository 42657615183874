import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomButton from "src/components/CustomButton";
import InputWithLabel from "src/components/InputWithLabel";
import WPhoneOrEmail from "src/components/PhoneOrEmail/WPhoneOrEmail";
import { getShortName } from "src/utils/global-functions";
import { InfoCircle, User01 } from "@untitled-ui/icons-react/build/cjs";
import Tooltip from "src/components/Tooltip/Tooltip";

const WSocialProfile = ({ errorImage, cropData, onErrorImage, referralConfig, onChangeCrop, isLoading }: any) => {
    const { t } = useTranslation();
    const { control, watch, setValue } = useFormContext();
    const firstName = watch("first_name");
    const lastName = watch("last_name");
    return (
        <>
            <div className={`flex flex-col justify-center`}>
                <div className="flex flex-col">
                    <Controller
                        name="profile_image_name"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <div className="w-full flex flex-col">
                                <div className="flex flex-row gap-4 lg:gap-5 relative">
                                    {!errorImage.profile && cropData ? (
                                        <img src={cropData} alt="" onError={onErrorImage("profile")} className="w-16 h-16 border-[0.75px] border-uploadProfileBg rounded-lg object-cover" />
                                    ) : firstName && lastName ? (
                                        <div className="rounded-lg bg-uploadProfileBg flex justify-center items-center  text-primaryAppointment font-medium h-[64px] w-[64px] ">
                                            <span className="text-[24px] leading-[36px] font-medium">{getShortName(`${firstName} ${lastName}`)}</span>
                                        </div>
                                    ) : (
                                        <div className="rounded-lg bg-uploadProfileBg flex justify-center items-center  text-primaryAppointment font-medium h-[64px] w-[64px] ">
                                            <span className="text-[24px] leading-[36px] font-medium">
                                                <User01 className="text-[#8C9CBB] w-5 h-5" />
                                            </span>
                                        </div>
                                    )}

                                    <div
                                        className={`rounded-lg border border-uploadProfileBorder bg-uploadInputBg hover:shadow shadow-sm cursor-pointer flex-auto items-center justify-center py-[11px] px-0 `}
                                    >
                                        <div className="relative overflow-hidden cursor-pointer">
                                            <input type="file" id="image" className=" absolute opacity-0 rounded-full h-[64px] w-[64px]" onChange={onChangeCrop} />
                                            <label htmlFor="image" className="cursor-pointer text-center ">
                                                <div className="text-textGrey text-[14px] leading-[20px] font-normal">
                                                    <p className="mb-1 flex items-center justify-center">
                                                        <span className="text-primary text-[14px] leading-[20px] font-semibold pr-1">{t("Click to upload")}</span>
                                                        <span className=" text-textGrey text-[14px] leading-[20px] font-normal">{t("or drag and drop")}</span>
                                                    </p>
                                                    <p className="text-[12px] text-textGrey font-normal leading-[18px]">{t("PNG or JPG file")}</p>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {error && <p className="text-error">{error.message}</p>}
                            </div>
                        )}
                    />
                    <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-4 mt-[24px] mb-4">
                        <Controller
                            name="first_name"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <div className="w-full">
                                    <InputWithLabel
                                        label={t("First Name")}
                                        borderClass="!h-[44px]"
                                        required
                                        type="text"
                                        placeholder={t("Enter your first name")}
                                        handleChange={onChange}
                                        value={value}
                                        name="first_name"
                                        error={!!error}
                                    />
                                    {error && <p className="text-error">{error.message}</p>}
                                </div>
                            )}
                        />
                        <Controller
                            name="last_name"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <div className="w-full">
                                    <InputWithLabel
                                        label={t("Last Name")}
                                        borderClass="!h-[44px]"
                                        required
                                        type="text"
                                        placeholder={t("Enter your last name")}
                                        handleChange={onChange}
                                        value={value}
                                        name="last_name"
                                        error={!!error}
                                    />
                                    {error && <p className="text-error">{error.message}</p>}
                                </div>
                            )}
                        />
                    </div>
                    <Controller
                        name="phone"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                            <div className="w-full">
                                <label className="text-sm text-txtAppointmentColor leading-[18.2px] flex font-normal text-[14px] -tracking-[0.02rem] mb-1.5">
                                    {t("Phone")} <span className="asterisk">*</span>
                                </label>
                                <WPhoneOrEmail
                                    singleType="phone"
                                    inputType="phone"
                                    borderClass="!h-[44px]"
                                    onNumberChange={({ data: { country, code, number } }: any) => {
                                        setValue("phone", `+${code}${number}`);
                                        setValue("phone_country_code", country);
                                    }}
                                    errors={error}
                                />
                            </div>
                        )}
                    />

                    {referralConfig.status && (
                        <Controller
                            name="referral_code"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <div className="mb-5 md:w-full mt-4">
                                    <div className="flex flex-row  mb-1.5 items-center">
                                        <span className="text-txtAppointmentColor text-[14px] font-normal leading-[18.2px] -tracking-[0.03rem]">{t("Referral code")}</span>
                                        <Tooltip
                                            description={t("Get 0% off any service when you refer a friend!", {
                                                value: referralConfig.refferer_type === "percentage" ? `${referralConfig.refferer}%` : `$${referralConfig.refferer}`,
                                            })}
                                        >
                                            <InfoCircle className="text-primaryAppointment ml-1.5 cursor-pointer w-[14px]" />
                                        </Tooltip>
                                        {/* <Tooltip
                                                content={
                                                    <div>
                                                        <p className=" text-primaryAppointment text-xs font-normal">
                                                            {t("Get 0% off any service when you refer a friend!", {
                                                                value: referralConfig.refferer_type === "percentage" ? `${referralConfig.refferer}%` : `$${referralConfig.refferer}`,
                                                            })}
                                                        </p>
                                                    </div>
                                                }
                                                trigger="hover"
                                                style={"light" as any}
                                                className="w-[185px] bg-[#EDF1FD] p-3 rounded-lg "
                                                placement="right"
                                            >
                                                <BiInfoCircle className="text-primaryAppointment ml-1.5 cursor-pointer w-[16px]" />
                                            </Tooltip> */}
                                    </div>
                                    <InputWithLabel
                                        // label={t("Referral code (Optional)")}
                                        type="text"
                                        placeholder={t("Enter your referral code (optional)")}
                                        handleChange={(e) => onChange(e.target.value.toUpperCase())}
                                        value={value ?? ""}
                                        name="referral_code"
                                        error={!!error}
                                    />
                                    {error && <p className="text-error">{error.message}</p>}
                                    <p className="text-secondaryTxt text-xs font-normal leading-4 -tracking-[0.03px] mt-2">
                                        {t("Enter a referral code to receive")}
                                        &nbsp;
                                        <span className="font-semibold">{referralConfig.referee_type === "percentage" ? `${referralConfig.referee}%` : `$${referralConfig.referee}`}</span>
                                        &nbsp;
                                        {t("your first appointment")}
                                    </p>
                                </div>
                            )}
                        />
                    )}
                </div>
            </div>
            <div className="pt-4">
                <p className="text-txtcolor text-[12px] leading-[20px] -tracking-[0.01rem] font-normal text-left ">
                    {t("By signing up, you agree to the")}&nbsp;
                    <a href="https://www.getflair.ca/terms-of-service" target="_blank" className="text-primary font-semibold">
                        {t("Terms of Service")}
                    </a>
                    &nbsp;{t("and")}&nbsp;
                    <a href="https://www.getflair.ca/privacy-policy" target="_blank" className="text-primary font-semibold">
                        {t("Privacy Policy, ")}
                    </a>
                    &nbsp;
                    {t("and consent to receive personalized offers and update.")}
                </p>
            </div>
            <div className="flex flex-col mt-[30px] max-md:mb-[30px]">
                <CustomButton primary type="submit" size="w-full" className="!h-[48px] " isLoading={isLoading} disabled={isLoading}>
                    {t("Continue")}
                </CustomButton>
            </div>
        </>
    );
};

export default WSocialProfile;
