import React, { FC, useState } from "react";
import { CountryCode } from "libphonenumber-js";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomButton from "src/components/CustomButton";
import Number from "src/components/Number";
import OTPField from "src/components/OTPField/OTPField";
import { IUpdatePhone } from "../Profile.interface";
import { CheckCircle } from "@untitled-ui/icons-react/build/cjs";
import { NavLink } from "react-router-dom";

const MUpdatePhone: FC<IUpdatePhone> = ({ verifyStep, isLoading, handleModalClose, phoneDetails, isOtpLoading, handlerSendCode }) => {
    const { t } = useTranslation();
    const { control, watch, setValue, clearErrors } = useFormContext();
    const otpWatch = watch("otp");
    const [isFocus, setIsFocus] = useState(false);
    const [isLabel, setIsLabel] = useState(true);
    const [localNumber, setLocalNumber] = useState("");
    const handleNumberChange = (country: CountryCode, phone: string, code: string, isValid: boolean) => {
        setValue(`phone`, `+${code}${phone}`);
        setValue(`phone_country_code`, country);
        if (isValid) {
            clearErrors("phone");
        }
        setLocalNumber(phone);
    };

    const onFocus = () => {
        setIsFocus(true);
        setIsLabel(true);
    };

    const onBlur = () => {
        if (!localNumber) {
            setIsLabel(true);
        } else {
            setIsLabel(false);
        }
        setIsFocus(false);
    };
    return (
        verifyStep && (
            <div className="mobile-model-inner-wrapper font-primary">
                <div className="mobile-model-inner flex flex-col">
                    {verifyStep.otp && (
                        <div className="flex flex-col w-full">
                            <span className="text-txtAppointmentColor font-semibold leading-[18.2px] text-[14px] tracking-[-0.01rem]">{phoneDetails.formatedPhone}</span>
                            {/* <span className="login-model-subtitle-dec">{phone}</span> */}
                            <div className="w-full mt-[12px]">
                                <Controller
                                    name="otp"
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                        <div className="flex flex-col">
                                            <OTPField otp={value} setOtp={onChange} errors={error} />
                                            {error && <p className="text-error text-center">{error.message}</p>}
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="mt-[6px] flex flex-col items-center">
                                <div className="flex items-center w-full justify-between gap-1">
                                    <p className="text-[#475467] text-[12px] font-semibold -tracking-[0.02rem] leading-[20px] flex">{t("Enter the code sent to your phone number")}</p>
                                    <CustomButton
                                        type="button"
                                        isLoading={isOtpLoading}
                                        disabled={isOtpLoading}
                                        onClick={handlerSendCode(phoneDetails)}
                                        className="text-primary !border-0 !p-0 !h-auto leading-[20px] !font-semibold !bg-transparent !text-[12px] text-center flex flex-row ring-0"
                                        shadow={false}
                                    >
                                        {t("Resend code")}
                                    </CustomButton>
                                </div>
                            </div>
                        </div>
                    )}
                    {verifyStep.phone && (
                        <div className="flex flex-col w-full">
                            <span className="text-txtAppointmentColor font-bold leading-[22.4px] text-[16px] tracking-[-0.01rem]">{t("Please update your phone number")}</span>
                            <div className="w-full mt-[12px]">
                                <Controller
                                    name="phone"
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                        <div className="flex flex-col w-full">
                                            <div className="flex flex-col relative">
                                                {isLabel ? (
                                                    <span className="text-textGrey text-[12px] font-normal -tracking-[0.01rem] leading-[14.52px] flex absolute left-[14px] top-2">
                                                        {t("Phone number")}
                                                    </span>
                                                ) : (
                                                    ""
                                                )}
                                                <Number
                                                    getNumber={onChange}
                                                    errors={error}
                                                    parentClassName={`${isFocus || isLabel ? "!pt-[22px]" : ""}`}
                                                    onNumberChange={handleNumberChange}
                                                    onFocus={onFocus}
                                                    onBlur={onBlur}
                                                />
                                            </div>
                                            {error && <p className="text-error">{error.message}</p>}
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                    )}
                    {verifyStep.success && (
                        <div className="flex flex-col w-full">
                            <div className="flex flex-row w-full p-4 border gap-3.5 border-successMessagesBorder bg-successMessagesBg rounded-xl">
                                <CheckCircle className="text-[#079455] w-5 h-5" />
                                <span className="text-[#101828] font-semibold text-[14px] leading-[20px]">{t("Your phone number has been successfully updated")}</span>
                            </div>
                            <CustomButton primary onClick={() => handleModalClose()} className={`w-full mt-5 !h-[48px] mb-5`}>
                                <span>{t("Continue")}</span>
                            </CustomButton>
                        </div>
                    )}
                    {!verifyStep.success && (
                        <div className="mt-[20px] w-full flex mb-5">
                            <CustomButton
                                primary
                                isLoading={isLoading}
                                disabled={isLoading || (verifyStep.otp && (isLoading || !otpWatch || otpWatch.length !== 4))}
                                className={`w-full !h-[48px]`}
                                type="submit"
                            >
                                <span>{verifyStep.phone ? t("Update") : t("Continue")}</span>
                            </CustomButton>
                        </div>
                    )}
                </div>
                {verifyStep.phone && (
                    <div className={`login-footer mt-[116px]`}>
                        <span className="font-medium text-[12px] leading-[15.52px] text-txtAppointmentColor max-w-[340px] mx-auto">
                            {t("By continuing, you agree to the ")}
                            <NavLink to="https://www.getflair.ca/terms-of-service" target="_blank" className="text-primaryAppointment">
                                {t("terms")}
                            </NavLink>
                              {t("and acknowledge the ")}
                            <NavLink to="https://www.getflair.ca/privacy-policy" target="_blank" className="text-primaryAppointment">
                                {t("policies")}
                            </NavLink>
                            .
                        </span>
                    </div>
                )}
            </div>
        )
    );
};

export default MUpdatePhone;
