import React, { useCallback, useState } from "react";
import { SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { ChevronLeft, ChevronRight } from "@untitled-ui/icons-react/build/cjs";
import CustomSwiper from "src/components/CustomSwiper/CustomSwiper";
import { Swiper as SwiperType } from "swiper/types";
import { useEnableSliderButtons } from "src/hooks/useEnableSliderButtons";
import { onError } from "src/utils/global-functions";
import { useTranslation } from "react-i18next";
interface Props {
    data: any;
}
const Gallery: React.FC<Props> = ({ data }) => {
    const { t } = useTranslation();
    const [swiperRef, setSwiperRef] = useState<SwiperType>();
    const [currentBreakpoint, setCurrentBreakpoint] = useState("1280");
    const conditions = [
        { breakpoint: "1280", minLength: 3 },
        { breakpoint: "1024", minLength: 2 },
        { breakpoint: "768", minLength: 2 },
    ];
    const isEnableSliderButtons = useEnableSliderButtons(currentBreakpoint, data.urls.length, conditions, true);

    const handleSlideChange = (event: any) => {
        setCurrentBreakpoint(event.currentBreakpoint);
    };
    const handlePrevious = useCallback(() => {
        swiperRef?.slidePrev();
    }, [swiperRef]);

    const handleNext = useCallback(() => {
        swiperRef?.slideNext();
    }, [swiperRef]);
    return (
        <div className="text-txtcolor pt-9 md:spacing-top">
            <div className=" w-full ">
                <div className="flex justify-between items-center mb-6 md:mb-[54px]">
                    <div className="flex w-full">
                        <h2 className="custom-heading w-full uppercase">{t("OUR GALLERY")}</h2>
                        <div className="md:flex relative hidden">
                            <button
                                disabled={!isEnableSliderButtons}
                                onClick={handlePrevious}
                                className="rounded-full flex justify-center items-center border border-TextColor cursor-pointer h-[52px] min-w-[52px] w-[52px] mr-2.5 bg-templateBg"
                            >
                                <ChevronLeft className="w-[24px] h-[24px] text-TextColor" />
                            </button>
                            <button
                                onClick={handleNext}
                                disabled={!isEnableSliderButtons}
                                className="rounded-full flex justify-center items-center cursor-pointer border border-TextColor bg-templateBg h-[52px] min-w-[52px] w-[52px]"
                            >
                                <ChevronRight className="w-[24px] h-[24px] text-TextColor" />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="">
                    <CustomSwiper
                        spaceBetween={28}
                        loop={isEnableSliderButtons}
                        className="mySwiper w-full"
                        onInit={handleSlideChange}
                        breakpoints={{
                            640: {
                                slidesPerView: 1,
                                spaceBetween: 15,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 15,
                            },
                            1024: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            1280: {
                                slidesPerView: 3,
                                spaceBetween: 20,
                            },
                        }}
                        setSwiperRef={setSwiperRef}
                    >
                        {/* Slides with content */}
                        {data.urls.map((img: any, index: React.Key | null | undefined) => (
                            <SwiperSlide key={index}>
                                <div className="relative w-full h-full cursor-pointer">
                                    <img src={`${img}`} alt="" onError={onError("Location")} className="w-full object-cover aspect-[15/10]" />
                                </div>
                            </SwiperSlide>
                        ))}
                    </CustomSwiper>
                    {data && data.urls.length > 1 && (
                        <div className="flex w-full justify-center md:hidden relative mt-4">
                            <button
                                className="rounded-full flex flex-col justify-center items-center border border-TextColor cursor-pointer h-[24px] w-[24px] min-w-[24px] smd:w-[40px] smd:h-[40px] smd:min-w-[40px] mr-3.5 bg-transparent"
                                onClick={handlePrevious}
                            >
                                <ChevronLeft className="text-TextColor w-4 h-4 smd:w-[20px] smd:h-[20px]" />
                            </button>
                            <button
                                className="rounded-full flex flex-col justify-center items-center border border-TextColor cursor-pointer h-[24px] w-[24px] min-w-[24px] smd:w-[40px] smd:h-[40px] smd:min-w-[40px] bg-transparent"
                                onClick={handleNext}
                            >
                                <ChevronRight className="text-TextColor w-4 h-4 smd:w-[20px] smd:h-[20px]" />
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Gallery;
